<template>
  <v-autocomplete
    v-model="chosenJobDescription"
    v-bind="$attrs"
    :items="formattedJobDescriptions"
    multiple
    deletable-chips
    small-chips
    append-icon="mdi-menu-down"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "JobDescriptionPicker",
  props: {
    initial: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({
    chosenJobDescription: "",
    isLoading: false,
    formattedJobDescriptions: [
      {
        text: "Child minding / baby sitting",
        value: "Child minding / baby sitting",
      },
      {
        text: "Minding old/sick employer or relative",
        value: "Minding old/sick employer or relative",
      },
      { text: "General tidying of house", value: "General tidying of house" },
      { text: "Making of beds", value: "Making of beds" },
      { text: "Vacuuming of carpets", value: "Vacuuming of carpets" },
      { text: "Vacuuming of upholstery", value: "Vacuuming of upholstery" },
      { text: "Cleaning of ornaments", value: "Cleaning of ornaments" },
      {
        text: "Cleaning of toilets, basins, baths, showers, taps etc.",
        value: "Cleaning of toilets, basins, baths, showers, taps etc.",
      },
      {
        text: "Wiping down of all appliances e.g. T.V etc",
        value: "Wiping down of all appliances e.g. T.V etc",
      },
      {
        text: "Mopping of tiled/vinyl floors",
        value: "Mopping of tiled/vinyl floors",
      },
      {
        text: "Cleaning of inside of cupboards",
        value: "Cleaning of inside of cupboards",
      },
      {
        text: "Cleaning of stove and oven",
        value: "Cleaning of stove and oven",
      },
      {
        text: "Preparation/cooking of breakfast",
        value: "Preparation/cooking of breakfast",
      },
      {
        text: "Preparation/cooking of supper",
        value: "Preparation/cooking of supper",
      },
      {
        text: "Preparation/cooking of lunch",
        value: "Preparation/cooking of lunch",
      },
      { text: "Setting of table", value: "Setting of table" },
      {
        text: "Cleaning away after breakfast/lunch/supper ",
        value: "Cleaning away after breakfast/lunch/supper ",
      },
      {
        text: "Polishing of floors and verandas",
        value: "Polishing of floors and verandas",
      },
      { text: "Cleaning brass and silver", value: "Cleaning brass and silver" },
      { text: "Washing of Walls", value: "Washing of Walls" },
      { text: "Other", value: "Other" },
      { text: `Laundry – machine wash`, value: `Laundry – machine wash` },
      { text: `Laundry – hand wash`, value: `Laundry – hand wash` },
      { text: "Washing of curtains", value: "Washing of curtains" },
      { text: "Ironing", value: "Ironing" },
      {
        text: "Small mending job, e.g. replacing buttons, hems, etc ",
        value: "Small mending job, e.g. replacing buttons, hems, etc ",
      },
      {
        text: "Defrosting and cleaning fridge & freezer",
        value: "Defrosting and cleaning fridge & freezer",
      },
      {
        text: "Cleaning of windows and glass doors inside and out",
        value: "Cleaning of windows and glass doors inside and out",
      },
      {
        text: "Cleaning of all used equipment e.g. vacuum cleaner",
        value: "Cleaning of all used equipment e.g. vacuum cleaner",
      },
      { text: "Packing away of groceries", value: "Packing away of groceries" },
      {
        text: "Removal of refuse for collection",
        value: "Removal of refuse for collection",
      },
      {
        text: "Sweeping of outside patios, steps, etc",
        value: "Sweeping of outside patios, steps, etc",
      },
      {
        text: "Wiping down of outside lights",
        value: "Wiping down of outside lights",
      },
      {
        text: `Cleaning of outside room’s and cloakroom`,
        value: `Cleaning of outside room’s and cloakroom`,
      },
      {
        text: "Maintain garden in clean and tidy condition",
        value: "Maintain garden in clean and tidy condition",
      },
      { text: "Caring for pool", value: "Caring for pool" },
      { text: "Mow lawns", value: "Mow lawns" },
      { text: "Weeding", value: "Weeding" },
      {
        text: "Washing and grooming of dogs",
        value: "Washing and grooming of dogs",
      },
      { text: "Trimming and pruning", value: "Trimming and pruning" },
      { text: "Painting of walls", value: "Painting of walls" },
    ],
  }),
  watch: {
    initial(newValue, oldValue) {
      this.chosenJobDescription = this.initial;
    },
    chosenJobDescription(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-job-description",
          this.chosenJobDescription ? this.chosenJobDescription : ""
        );
      }
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass"></style>
