import Vue from "vue";
import VueRouter from "vue-router";

// Layouts
import FrontendLayout from "@/views/Layouts/Frontend";
import UserLayout from "@/views/Layouts/User";
import AdminLayout from "@/views/Layouts/Admin";
import ConsultantLayout from "@/views/Layouts/Consultant";
import EmployerLayout from "@/views/Layouts/Employer";
// Children
import { FrontendChildren } from "./frontend";
import { AdminChildren } from "./admin";
import { ConsultantChildren } from "./consultant";
import { UserChildren } from "./user";
import { EmployerChildren } from "./employer";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: FrontendLayout,
    ...FrontendChildren,
  },
  {
    path: "/admin",
    component: AdminLayout,
    ...AdminChildren,
  },
  {
    path: "/consultant",
    component: ConsultantLayout,
    ...ConsultantChildren,
  },
  {
    path: "/employer",
    component: EmployerLayout,
    ...EmployerChildren,
  },
  {
    path: "/user",
    component: UserLayout,
    ...UserChildren,
  },
  {
    path: "*",
    component: FrontendLayout,
    children: [
      {
        name: "404 Error",
        path: "",
        component: () => import("@/views/Error"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const { authorize } = to.meta;
  document.title = 'Fairwage'
  const loggedIn = localStorage.getItem("token");
  const role = localStorage.getItem("role");

  if (to.fullPath.substr(0, 2) === "/#") {
    const path = to.fullPath.substr(2);
    next(path);
    return;
  }

  if (authorize && !loggedIn) return next({ name: "Home" });

  if (authorize && !role) return next({ name: "Home" });

  if (role && authorize && !authorize.includes(role))
    return next({ name: "Home" });
  return next();
});

export default router;
