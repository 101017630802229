<template>
  <v-autocomplete
    v-model="chosenBank"
    v-bind="$attrs"
    :items="formattedBanks"
    append-icon="mdi-menu-down"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "BankPicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenBank: "",
    isLoading: false,
    formattedBanks: [
      { text: "Absa Bank", value: "Absa Bank" },
      { text: "Capitec Bank", value: "Capitec Bank" },
      {
        text: "First National Bank",
        value: "First National Bank",
      },
      { text: "Investec Bank", value: "Investec Bank" },
      {
        text: "Nedbank Limited",
        value: "Nedbank Limited",
      },
      {
        text: "Nedbank CSA",
        value: "Nedbank CSA",
      },
      { text: "Postbank", value: "Postbank" },
      {
        text: "Standard Bank",
        value: "Standard Bank",
      },
      { text: "African Bank", value: "African Bank" },
      { text: "Albaraka Bank", value: "Albaraka Bank" },
      { text: "Bank of Namibia", value: "Bank of Namibia" },
      { text: "Bidvest Bank", value: "Bidvest Bank" },
      {
        text: "Central Bank of Lesotho",
        value: "Central Bank of Lesotho",
      },
      { text: "Citi Bank", value: "Citi Bank" },
      { text: "Discovery Bank", value: "Discovery Bank" },
      {
        text: "Finbond Mutual Bank",
        value: "Finbond Mutual Bank",
      },
      {
        text: "First National Bank Lesotho",
        value: "First National Bank Lesotho",
      },
      {
        text: "First National Bank Namibia",
        value: "First National Bank Namibia",
      },
      {
        text: "First National Bank Swaziland",
        value: "First National Bank Swaziland",
      },
      { text: "Grinrod Bank", value: "Grinrod Bank" },
      { text: "HSBC Bank", value: "HSBC Bank" },
      {
        text: "JP Morgan Chase Bank",
        value: "JP Morgan Chase Bank",
      },
      { text: "Meeg Bank", value: "Meeg Bank" },
      { text: "Merchantile Bank", value: "Merchantile Bank" },
      { text: "MTN Banking", value: "MTN Banking" },
      {
        text: "Standard Bank Namibia",
        value: "Standard Bank Namibia",
      },
      {
        text: "State Bank of India",
        value: "State Bank of India",
      },
      { text: "Ubank", value: "Ubank" },
      { text: "Unibank", value: "Unibank" },
      { text: "VBS Mutual Bank", value: "VBS Mutual Bank" },
      {
        text: "Olympus Mobile Bank",
        value: "Olympus Mobile Bank",
      },
      { text: "Tyme Bank", value: "Tyme Bank" },
    ],
  }),
  watch: {
    chosenBank(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-bank",
          this.chosenBank ? this.chosenBank : ""
        );
      }
    },
  },
  async mounted() {
    this.initial ? (this.chosenBank = this.initial) : (this.chosenBank = "");
  },
  methods: {},
};
</script>

<style lang="sass"></style>
