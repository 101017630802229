<template>
  <v-autocomplete
    v-model="chosenUser"
    v-bind="$attrs"
    :items="formattedUsers"
    :loading="isLoading"
    :disabled="isLoading"
    chips
    color="blue-grey lighten-2"
  >
    <template>
      <v-chip>
        {{ formattedUsers }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "BasicEmployerPicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
    role: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    user: "",
    chosenUser: [],
    totalRecords: 0,
    isLoading: false,
    returnedUsers: [],
    formattedUsers: [],
  }),
  apollo: {
    users: {
      query: gql`
        query Users($limit: Int!, $query: JSON!) {
          users(limit: $limit, query: $query) {
            records {
              id
              # username
              firstName
              lastName
              email
              role
            }
            # count
          }
        }
      `,
      variables() {
        return {
          limit: 9999,

          query: { role: "employer" },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.returnedUsers = data.users.records;
        this.formattedUsers = _.map(this.returnedUsers, (user) => {
          return {
            text: `${user.firstName} ${user.lastName} `,
            value: user.id,
          };
        });
      },
    },
  },
  watch: {
    chosenUser(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-user",
          this.chosenUser ? this.chosenUser : ""
        );
      }
    },
  },
  async mounted() {
    this.initial ? (this.chosenUser = this.initial) : (this.chosenUser = "");
  },
  methods: {},
};
</script>

<style lang="sass"></style>
