<template v-if="users.records">
   <download-excel
      class="text-right"
      :data="users.records"
      :fields="fields"
      :before-generate="startDownload"
      :before-finish="finishDownload"
      worksheet="My Worksheet"
      name="MedsafuConsultantsList.xls"
    >
    <v-btn class="text-right" color="#0a2752" large dark>
      <h1>Download</h1>
    </v-btn>
  </download-excel>
</template>

<script>
import { gql } from "apollo-boost";
import downloadexcel from "vue-json-excel";

export default {
  name: "UserDownload",
  components: {
    downloadexcel,
  },
  data: () => ({
    isLoading: false,
    fields: {
      "First Name": "firstName",
      "Last Name": "lastName",
      "Email Address": "email",
      "Mobile Number": "telNumber",
      Referral: "referral",
      "Consultant ID": "consultant",
      "Branch ID": "branch",
      "Client/Person ID": "id",
      "Created At": "createdAt",
    },
    users: [],
    page: 1,
  }),
  apollo: {
    users: {
      query: gql`
        query Users($limit: Int, $page: Int, $query: JSON!) {
          users(limit: $limit, page: $page, query: $query) {
            records {
              id
              firstName
              lastName
              email
              telNumber
              branch
              referral
              consultant
              createdAt
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        return {
          limit: limit,
          page: this.page,
          query: {role: 'consultant'},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },
  watch: {},
  async mounted() {},
  methods: {
    async refreshClientData() {
      this.$router.go(this.$route.currentRoute);
    },
    startDownload(){
        this.isLoading = true;
    },
    finishDownload(){
        this.isLoading = false;
    }
  },
};
</script>

<style lang="sass"></style>
