<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn x-small fab color="green lighten-2" v-bind="attrs" v-on="on"
          ><v-icon>mdi-eye</v-icon></v-btn
        >
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Aknowledge Notification
        </v-card-title>

        <v-card-text>
          <v-switch
            v-model="notificationModel.read"
            flat
            :label="`Read: ${notificationModel.read.toString()}`"
          ></v-switch>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="[save(), (dialog = false)]">
            Acknowledge
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { gql } from "apollo-boost";

import { Notification } from "@/models/Notification";

export default {
  name: "UpdateNotification",

  data() {
    return {
      isEdit: false,
      isLoading: false,
      dialog: false,
      notificationModel: new Notification(),

      id: "",
    };
  },
  // created() {
  //   this.isLoading = this.isEdit ? true : false;
  // },
  computed: {},

  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            firstName
            lastName
            profilePicture
          }
        }
      `,
      fetchPolicy: "network-only",
      result() {
        console.log("this.me", this.me);
        if (this.me.id != "" || null) {
          this.isEdit = true;
          this.id = this.me.id;
        }
      },
    },
    notifications: {
      query: gql`
        query Notifications($limit: Int!, $skip: Int!, $query: JSON!) {
          notifications(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              description
              done
              assignedTo
              assignedToName
              createdBy
              createdByName
              read
              employerAssignedTo
              employerAssignedToName
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: {}, //{ assignedTo: this.me.id },
        };
      },
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      // if (!self.$refs.notificationForm.validate()) {
      //   self.$swal("Error", "Validation failed", "error");

      //   self.isLoading = false;
      //   return;
      // }

      if (notifications) {
        await this.updateNotification()
          .then(() => {
            this.$swal("Success!", "Notification was updated", "success");
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              "something went wrong when trying to update the Notification, check data and try again later!",
              "error"
            );
          });
      }
      this.isLoading = false;
    },
    async updateNotification() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateNotification(
              $notification: NotificationUpdateInput!
            ) {
              updateNotification(notification: $notification) {
                id
                updated
              }
            }
          `,
          variables: {
            notification: {
              id: self.me.id,

              read: self.notificationModel.read,
              updatedBy: self.me.id,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the Notification!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
