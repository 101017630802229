import { loadView } from "./functions";

export const UserChildren = {
  children: [
    {
      path: "landing",
      name: "userlanding",
      component: loadView("User/Landing"),
      ...userMeta(),
    },
    {
      path: "paymentHistory",
      name: "userPaymentHistory",
      component: loadView("User/PaymentHistory"),
      ...userMeta(),
    },
    {
      path: "paymentCreate",
      name: "userpaymentCreate",
      component: loadView("User/paymentCreate"),
      ...userMeta(),
    },
    {
      path: "paymentSchedule",
      name: "userpaymentSchedule",
      component: loadView("User/paymentSchedule"),
      ...userMeta(),
    },
    {
      path: "paymentProcess/:id",
      name: "userpaymentProcess",
      component: loadView("User/paymentProcess"),
      ...userMeta(),
    },
    {
      path: "shopping-list",
      name: "usershoppingList",
      component: loadView("User/Shopping"),
      ...userMeta(),
    },
    {
      path: "todo-list",
      name: "usertoDoList",
      component: loadView("User/ToDoList"),
      ...userMeta(),
    },
    {
      path: "calendar",
      name: "userCalender",
      component: loadView("User/Calender"),
      ...userMeta(),
    },
    {
      path: "profile",
      name: "userProfile",
      component: loadView("User/UserProfile"),
      ...userMeta(),
    },
    {
      path: "paymentOptions",
      name: "userpaymentOptions",
      component: loadView("User/PaymentOptions"),
      ...userMeta(),
    },

    {
      path: "todolists",
      name: "Todolists",
      component: loadView("User/ToDoList/View"),
      ...userMeta(),
    },
    {
      path: "todolist/:id",
      name: "Todolist Details",
      component: loadView("User/ToDoList/Details"),
      ...userMeta(),
    },
    {
      path: "todolist/:id/edit",
      name: "Todolist Edit",
      component: loadView("User/ToDoList/Edit"),
      ...userMeta(),
    },
    /////
  ],
};

function userMeta() {
  return {
    meta: {
      authorize: ["admin", "user", "employer", "employee", "consultant"],
    },
  };
}
