<template>
  <v-card class="my-0">
    <v-card-text>
      <v-form ref="UserCreateForm" v-model="formValid">
        <base-loading :is-loading="isLoading" />
        <v-alert v-if="successMessage" type="success">
          {{ successMessage }}
        </v-alert>
        <v-alert v-if="errorMessage" type="error">
          {{ errorMessage }}
        </v-alert>

        <v-row class="mx-1">
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.firstName"
              color="primary"
              outlined
              required
              :rules="[(v) => !!v || 'First name is required']"
            >
              <template #label>
                <span class="red--text">First Name<strong>* </strong></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.lastName"
              color="primary"
              outlined
              required
              :rules="[(v) => !!v || 'Last name is required']"
            >
              <template #label>
                <span class="red--text">Last Name<strong>* </strong></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.email"
              color="primary"
              outlined
              required
              :rules="[
                (v) => !!v || 'Email is required',
                (v) =>
                  /.+@.+\..+/.test(v) || 'Email Address format must be valid',
              ]"
            >
              <template #label>
                <span class="red--text">Email<strong>* </strong></span>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="user.password"
              color="primary"
              outlined
              clearable
              required
              :rules="[(v) => !!v || 'Password is required']"
            >
              <template #label>
                <span class="red--text">Password<strong>* </strong></span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :disabled="!formValid"
        color="primary"
        class="mx-2"
        @click="[save()]"
      >
        Save
      </v-btn>
      <!-- <v-btn
        :disabled="!formValid"
        color="primary"
        class="mx-2"
        @click="refreshPage()"
      >
        Refresh
      </v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import { gql } from "apollo-boost";
import UserEditPicker from "./UserEditPicker.vue";
// import moment from 'moment'
export default {
  name: "ConsultantsUserCreate",
  documents: [],
  components: {
    UserEditPicker,
  },
  data: () => ({
    isLoading: false,
    formValid: true,
    interestQuestion: false,
    user: {
      password: "",
      firstName: "",
      lastName: "",

      role: "employee",
    },
    successMessage: "",
    errorMessage: "",
  }),
  computed: {},
  async mounted() {},
  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      await this.saveUser()
        .then(() => {
          // this.$swal("Success!", "User was created", "success");
          // window.location.href = `/admin/users`;
        })
        .catch((e) => {
          console.log(e);
          /*this.$swal(
            "Error!",
            `Something went wrong when creating the User, check data and try again later!`,
            "error"
          );*/
        });
      this.isLoading = false;
    },
    async refreshClientData() {
      this.$apollo.queries.users.refetch();
    },
    async refreshPage() {
      this.$router.go(this.$route.currentRoute);
    },
    async saveUser() {
      const self = this;
      self.successMessage = "";
      self.errorMessage = "";
      if (self.$refs.UserCreateForm.validate()) {
        try {
          const {
            password,
            firstName,
            lastName,

            email,
            role,
          } = self.user;
          const createdUser = await this.$apollo.mutate({
            mutation: gql`
              mutation ($user: UserCreateInput!) {
                createUser(user: $user) {
                  id
                }
              }
            `,
            variables: {
              user: {
                password,
                firstName,
                lastName,

                email,
                role: "employee",
              },
            },
            watchLoading(isLoading, countModifier) {
              self.isLoading = isLoading;
            },
          });
          console.log(createdUser);
          if (!createdUser) {
            return "Something went wrong when creating the user!";
          }
          this.$swal({
            title: "Success!",
            text: "User was created",
            type: "success",
          }).then(function () {
            window.location.href = `/admin/users`;
          });
          self.successMessage = `User Created Successfully`;
          self.$eventHub.$emit("user-updated");
        } catch (error) {
          this.$swal({
            title: "Error!",
            text: "Something went wrong when creating the User, check data and try again later!",
            type: "error",
          }).then(function () {
            window.location.href = `/admin/users`;
          });
          self.errorMessage = `User Not Created / ${error.message}`;
        }
      } else {
        self.errorMessage = `User Not Created/ All required fields must be completed.`;
      }
    },
  },
};
</script>

<style lang="sass"></style>
