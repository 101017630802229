<template>
  <div v-if="formType == 'default'">
    <!-- <v-breadcrumbs :items="crumbs"></v-breadcrumbs> -->

    <v-row>
      <v-col md="6">
        <v-card elevation="0">
          <v-card-text>
            <v-form ref="shoppingForm" @submit.prevent="save()">
              <v-row>
                <v-col md="12">
                  <v-text-field
                    v-model="shoppinglistModel.description"
                    label="Description of item needed"
                    required
                    placeholder="Handy Andy"
                    outlined
                    :rules="[(v) => !!v || 'List description is required']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-btn type="submit" color="success">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <div v-else-if="formType == 'markDone'">
    <v-form ref="shoppingForm">
      <v-checkbox
        @click="save()"
        v-model="shoppinglistModel.done"
        color="primary"
      ></v-checkbox>
    </v-form>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { ShoppingList } from "@/models/ShoppingList";
export default {
  name: "ShoppingEdit",
  props: {
    assignedTo: {
      type: String,
      default: "",
    },
    assignedToName: {
      type: String,
      default: "",
    },
    fromId: {
      type: String,
      default: "",
    },
    formType: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      isEdit: false,
      shoppinglistModel: new ShoppingList(),
      me: "",
      search: null,
    };
  },
  created() {
    this.isEdit =
      (this.$route.params.id != 0 && this.$route.params.id != null) ||
      this.fromId != "";
  },

  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    shopping: {
      query: gql`
        query Shopping($id: ID!) {
          shopping(id: $id) {
            id
            description
            done
            assignedTo
            updatedBy
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: this.isEdit ? self.fromId : self.$route.params.id,
        };
      },
      result(response, key) {
        //  alert(JSON.stringify(this.shopping));

        this.shoppinglistModel.id = this.shopping.id;
        this.shoppinglistModel.description = this.shopping.description;
        this.shoppinglistModel.done = this.shopping.done;
        this.shoppinglistModel.assignedTo = this.shopping.assignedTo;
        this.shoppinglistModel.updatedBy = this.shopping.updatedBy;
      },
    },
  },
  methods: {
    async save() {
      const self = this;
      if (!self.$refs.shoppingForm.validate()) {
        self.$swal("Error", "Validation failed", "error");
        return;
      }
      if (self.isEdit) {
        // update
        await this.updateShopping()
          .then(() => {
            this.$swal({
              title: "Shopping list update",
              text: "Your employees shopping list was updated, click ok to refresh",
              type: "success",
              showConfirmButton: true,
              showCancelButton: false,
              icon: "success",
              timer: 1500,
            }).then(
              function () {},
              // handling the promise rejection
              function (dismiss) {
                if (dismiss === "timer") {
                  this.$router.go(this.$route.currentRoute);
                }
              }
            );
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              "something went wrong when trying to update the shopping list item, try again later!",
              "error"
            );
          })
          .then(function () {
            this.$router.go(this.$route.currentRoute);
          });
      } else {
        // create
        await this.createShopping()
          .then(() => {
            this.$swal({
              title: "Item added to the list ",
              text: "Your shopping list item was added to the things your employer needs to get for you",
              type: "success",
              showConfirmButton: true,
              showCancelButton: false,
              icon: "success",
              timer: 1500,
            }).then(
              function () {},
              // handling the promise rejection
              function (dismiss) {
                if (dismiss === "timer") {
                  this.$router.go(this.$route.currentRoute);
                }
              }
            );
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              `Something went wrong when creating the shopping list item, check data and try again later!`,
              "error"
            );
          });
      }
    },
    async updateShopping() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation update($shopping: ShoppingUpdateInput!) {
              updateShopping(shopping: $shopping) {
                id
                updated
              }
            }
          `,
          variables: {
            shopping: {
              id: self.shoppinglistModel.id,
              description: self.shoppinglistModel.description,
              done: self.shoppinglistModel.done,
              updatedBy: self.me.id,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the shopping list item!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
    async createShopping() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createShopping($shopping: ShoppingCreateInput!) {
              createShopping(shopping: $shopping) {
                id
              }
            }
          `,
          variables: {
            shopping: {
              description: self.shoppinglistModel.description,
              createdBy: self.me.id,
              assignedTo: this.assignedTo,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the Shopping Item!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createShopping) {
          self.shoppinglistModel.id = response?.data?.createShopping?.id;
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
