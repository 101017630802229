<template>
  <v-card class="my-0">
    <v-card-text>
      <v-form ref="UserCreateForm" v-model="formValid">
        <base-loader :is-loading="isLoading" />
        <v-alert v-if="successMessage" type="success">
          {{ successMessage }}
        </v-alert>
        <v-alert v-if="errorMessage" type="error">
          {{ errorMessage }}
        </v-alert>
        <v-row class="mx-1">
          <v-col cols="12" md="5">
            <div class="mb-12 ml-4">
              <a
                href="https://media.publit.io/file/medsafu/Sample/BulkUsersUpload.png"
                >Click here for a Sample CSV file</a
              >
            </div>
            <v-file-input
              v-model="userCSVFile"
              accept="text/csv"
              color="secondary"
              label="File input"
              show-size
              prepend-icon="mdi-paperclip"
              counter
              :full-width="true"
              outlined
              required
              :rules="[(v) => !!v || 'File is required']"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip color="secondary" dark label small>
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
            <v-btn
              :disabled="!userCSVFile"
              color="secondary"
              class="ma-1"
              @click="stageForUploading()"
            >
              Stage for Uploading
            </v-btn>
            <v-btn
              :disabled="
                !parsedCSVIsValid || parsedCSV.length === 0 || !userCSVFile
              "
              class="ma-1"
              color="secondary"
              :block="false"
              @click="startUpload()"
            >
              Start Upload
            </v-btn>
            <!--v-btn
              :disabled="
                !parsedCSVIsValid || parsedCSV.length === 0 || !userCSVFile
              "
              class="ma-1"
              color="secondary"
              :block="false"
              @click="failedUpload()"
            >
              Download File
            </!--v-btn-->
            <h1 class="mt-4">Upload progress</h1>
            <v-progress-linear
              :value="uploadProgress"
              color="secondary"
              height="25"
              reactive
              class="my-5"
            >
              <strong style="color: #fff"
                >{{ Math.ceil(uploadProgress) }}% ({{ completedRecords }} /
                {{ totalRecords }})</strong
              >
            </v-progress-linear>
            <v-btn class="ma-1" color="secondary" @click="refreshClientData()">
              <h1>Close & Refresh</h1>
            </v-btn>
          </v-col>
          <v-col cols="12" md="7">
            <v-textarea
              v-model="stagedTextArea"
              color="secondary"
              rows="20"
              label="Staged Data"
              filled
              no-resize
              outlined
              :readonly="true"
            />
          </v-col>
        </v-row>
      </v-form>
      <!-- <small>*indicates required field</small> -->
    </v-card-text>
    <v-card-actions>
      <!-- <v-btn
        :disabled="!userCSVFile"
        color="secondary"
        class="mx-2"
        @click="stageForUploading()"
      >
        Save
      </v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
// import { mapGetters } from 'vuex'
import { gql } from "apollo-boost";
var Papa = require("papaparse");
const Joi = require("joi");
export default {
  name: "UserBulkCreate",
  data: () => ({
    isLoading: false,
    uploadingData: false,
    formValid: true,
    tenant: "",
    userCSVFile: null,
    parsedCSV: [],
    failedUploadData: [],
    stagedTextArea: "",
    parsedCSVIsValid: false,
    uploadProgress: 0,
    totalRecords: 0,
    completedRecords: 0,
    successMessage: "",
    errorMessage: "",
  }),
  // computed: {
  //   ...mapGetters(),
  // },
  watch: {
    userCSVFile() {
      console.log("userCSVFile has changed");
      this.parsedCSV = [];
      this.stagedTextArea = "";
    },
  },
  async mounted() {},
  methods: {
    async refreshClientData() {
      this.$router.go(this.$route.currentRoute);
    },

    async stageForUploading() {
      const self = this;
      self.parsedCSV = [];
      this.stagedTextArea = "";
      self.isLoading = true;
      self.successMessage = "";
      self.errorMessage = "";
      try {
        console.log("Parsing CSV File");
        Papa.parse(self.userCSVFile, {
          header: true,
          skipEmptyLines: true,
          step: (row) => {
            console.log("Row:", row.data);
            self.parsedCSV.push(row.data);
          },
          complete: () => {
            console.log("All done!");
            self.stagedTextArea = `There are ${self.parsedCSV.length} items in this file.\r\n`;
            this.checkDataIsValid();
            self.totalRecords = self.parsedCSV.length;
            self.isLoading = false;
          },
        });
      } catch (error) {
        self.errorMessage = `Could Not Stage File: ${Date.now()} it seems there was this error: ${
          error.message
        }`;
        self.isLoading = false;
      }
    },
    checkDataIsValid() {
      console.log("Validating Objects in Array");
      const self = this;
      self.parsedCSVIsValid = false;
      const userObj = Joi.object().keys({
        title: Joi.alternatives().try(Joi.string()),
        firstName: Joi.alternatives().try(Joi.string()).required(),
        lastName: Joi.alternatives().try(Joi.string()).required(),
        email: Joi.alternatives()
          .try(Joi.string().email({ tlds: { allow: false } }))
          .required(),
        password: Joi.alternatives().try(Joi.string(), Joi.number()).required(),
        idNumber: Joi.alternatives().try(Joi.string()).required(),
        // dob: Joi.alternatives().try(Joi.string()),
        telNumber: Joi.alternatives().try(Joi.string()),
        altNumber: Joi.alternatives().try(Joi.string()),
        fullAddress: Joi.alternatives().try(Joi.string(), Joi.number()),
        branch: Joi.alternatives().try(Joi.string(), Joi.number()),
        consultant: Joi.alternatives().try(Joi.string(), Joi.number()),
        // age: Joi.alternatives().try(Joi.string()),
        relationship: Joi.alternatives().try(Joi.string()),
        gender: Joi.alternatives().try(Joi.string()),
        postalCode: Joi.alternatives().try(Joi.string(), Joi.number()),
        region: Joi.alternatives().try(Joi.string(), Joi.number()),
        mailingPref: Joi.alternatives().try(Joi.string(), Joi.number()),
        faxNumber: Joi.alternatives().try(Joi.string(), Joi.number()),
        businessEmail: Joi.alternatives().try(Joi.string()),
        race: Joi.alternatives().try(Joi.string()),
      });
      const userArr = Joi.array().items(userObj);
      // const test = Joi.validate(self.parsedCSV, userArr);
      const test = userArr.validate(self.parsedCSV);
      console.log(test);
      if (test.error) {
        self.errorMessage = `There are problems with your file : ${test.error}`;
        self.parsedCSVIsValid = false;
      } else {
        self.successMessage = "Yay your file is valid to begin the upload";
        self.parsedCSVIsValid = true;
      }
      return self.parsedCSVIsValid;
    },
    async startUpload() {
      const self = this;
      self.totalRecords = self.parsedCSV.length;
      self.uploadProgress = 0;
      self.completedRecords = 0;
      self.failedUploadData = [];
      await Promise.all(
        self.parsedCSV.map(async (user) => {
          user.firstName = user.firstName.toLowerCase();
          user.lastName = user.lastName.toLowerCase();
          user.email = user.email.toLowerCase();
          self.stagedTextArea += `Creating ${user.firstName} ${user.lastName}\r\n`;
          try {
            await self.saveUser(user);
            self.stagedTextArea += `${user.firstName} ${user.lastName}created successfully\r\n`;
          } catch (error) {
            self.stagedTextArea += `${user.firstName} ${user.lastName} not created: ${error.message}\r\n`;
            /*self.failedUploadData = {
              title: `${user.title}`,
              firstName: `${user.firstName}`,
              lastName: `${user.lastName}`,
              email: `${user.email}`,
              password: `${user.password}`,
              idNumber: `${user.idNumber}`,
              dob: `${user.dob}`,
              telNumber: `${user.telNumber}`,
              altNumber: `${user.altNumber}`,
              fullAddress: `${user.fullAddress}`,
              branch: `${user.branch}`,
              consultant: `${user.consultant}`
            };*/
            self.failedUploadData.push(user);
            const userArry = self.failedUploadData;
            console.log(userArry[0]);
          }
          self.completedRecords += 1;
          self.uploadProgress =
            (self.completedRecords / self.totalRecords) * 100;
        })
      );
    },
    async failedUpload(failedUploadRecords) {
      const self = this;
      this.stagedTextArea = "";
      self.isLoading = true;
      self.successMessage = "";
      self.errorMessage = "";
      try {
        console.log("Unparsing CSV File");
        Papa.unparse({
          fields: [
            "title",
            "firstName",
            "lastName",
            "email",
            "password",
            "idNumber",
            //  "dob",
            "telNumber",
            "altNumber",
            "fullAddress",
            "branch",
            "consultant",
          ],
          data: failedUploadRecords,
          complete: () => {
            console.log("All done!");
            self.stagedTextArea = `There are ${self.parsedCSV.length} items in this file.\r\n`;
          },
        });
      } catch (error) {
        self.errorMessage = `Could Not Create File: ${Date.now()} it seems there was this error: ${
          error.message
        }`;
        self.isLoading = false;
      }
    },
    async saveUser(userObj) {
      const self = this;
      self.successMessage = "";
      self.errorMessage = "";
      userObj.role = "user";
      userObj.referral = "Organic Website";
      try {
        console.log("userObj", userObj);
        const createdUser = await this.$apollo.mutate({
          mutation: gql`
            mutation ($userInput: UserCreateInput!) {
              createUser(user: $userInput) {
                id
              }
            }
          `,
          variables: { userInput: userObj },
          error(error) {
            console.log(JSON.stringify(error.message));
          },
        });
        console.log(createdUser);

        return true;
      } catch (error) {
        throw error;
      }
    },
  },
};
</script>

<style lang="sass"></style>
