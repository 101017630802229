<template>
  <div v-if="formType == 'default'">
    <!-- <v-breadcrumbs :items="crumbs"></v-breadcrumbs> -->

    <v-row>
      <v-col md="6">
        <v-card elevation="0">
          <v-card-text>
            <v-form ref="notificationForm" @submit.prevent="save()">
              <v-row>
                <v-col md="12">
                  <v-text-field
                    v-model="notificationlistModel.description"
                    label="Description of item needed"
                    required
                    placeholder="Handy Andy"
                    outlined
                    :rules="[(v) => !!v || 'List description is required']"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!--                    <v-row>-->
              <!--                      <v-col>-->
              <!--                        <p>Approve</p>-->
              <!--                        <v-icon color="secondary">mdi-receipt-text-check-outline</v-icon>-->
              <!--                      </v-col>-->
              <!--                      <v-spacer></v-spacer>-->
              <!--                        <v-col>-->
              <!--                          <p>Reject</p>-->
              <!--                          <v-icon color="warning">mdi-receipt-text-remove-outline</v-icon>-->
              <!--                      </v-col>-->
              <!--                    </v-row>-->
              <v-btn type="submit" color="success">Save</v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <div v-else-if="formType == 'markDone'">
    <v-form ref="notificationForm">
      <v-checkbox
        @click="save()"
        v-model="notificationlistModel.read"
        color="primary"
      ></v-checkbox>
    </v-form>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { Notification } from "@/models/Notification";
export default {
  name: "NotificationEdit",
  props: {
    assignedTo: {
      type: String,
      default: "",
    },
    assignedToName: {
      type: String,
      default: "",
    },
    fromId: {
      type: String,
      default: "",
    },
    formType: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      isEdit: false,
      notificationlistModel: new Notification(),
      me: "",
      search: null,
    };
  },
  created() {
    this.isEdit =
      (this.$route.params.id != 0 && this.$route.params.id != null) ||
      this.fromId != "";
  },

  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    notification: {
      query: gql`
        query Notification($id: ID!) {
          notification(id: $id) {
            id
            title
            body
            read
            done
            assignedTo
            createdBy
            updatedBy
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: this.isEdit ? self.fromId : self.$route.params.id,
        };
      },
      result(response, key) {
        //  alert(JSON.stringify(this.notification));

        this.notificationlistModel.id = this.notification.id;
        this.notificationlistModel.description = this.notification.description;
        this.notificationlistModel.done = this.notification.done;
        this.notificationlistModel.read = this.notification.read;
        this.notificationlistModel.assignedTo = this.notification.assignedTo;
        this.notificationlistModel.updatedBy = this.notification.updatedBy;
      },
    },
  },
  methods: {
    async save() {
      const self = this;
      if (!self.$refs.notificationForm.validate()) {
        self.$swal("Error", "Validation failed", "error");
        return;
      }
      if (self.isEdit) {
        // update
        await this.updateNotification()
          .then(() => {
            this.$swal({
              title: "Notification list update",
              text: "Your employees notification list was updated, click ok to refresh",
              type: "success",
              showConfirmButton: true,
              showCancelButton: false,
              icon: "success",
              timer: 1500,
            }).then(
              function () {},
              // handling the promise rejection
              function (dismiss) {
                if (dismiss === "timer") {
                  window.location.href = `/employer/notifications`;
                }
              }
            );
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              "something went wrong when trying to update the notification list item, try again later!",
              "error"
            );
          })
          .then(function () {
            this.$router.go(this.$route.currentRoute);
          });
      } else {
        // create
        await this.createNotification()
          .then(() => {
            this.$swal(
              "Success!",
              "Notification list item was created",
              "success"
            );
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              `Something went wrong when creating the notification list item, check data and try again later!`,
              "error"
            );
          });
      }
    },
    async updateNotification() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation update($notification: NotificationUpdateInput!) {
              updateNotification(notification: $notification) {
                id
                updated
              }
            }
          `,
          variables: {
            notification: {
              id: self.notificationlistModel.id,
              description: self.notificationlistModel.description,
              done: self.notificationlistModel.done,
              read: self.notificationlistModel.read,
              updatedBy: self.me.id,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the notification list item!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
    async createNotification() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createNotification(
              $notification: NotificationCreateInput!
            ) {
              createNotification(notification: $notification) {
                id
              }
            }
          `,
          variables: {
            notification: {
              description: self.notificationlistModel.description,
              createdBy: self.me.id,
              assignedTo: this.assignedTo,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the Notification Item!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createNotification) {
          self.notificationlistModel.id =
            response?.data?.createNotification?.id;
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
