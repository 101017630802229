<template>
  <v-autocomplete
    v-model="chosenPaymentIdType"
    v-bind="$attrs"
    :items="formattedPaymentIdTypes"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "PaymentIdTypePicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenPaymentIdType: "",
    isLoading: false,
    formattedPaymentIdTypes: [
      {
        text: "Employer prepopulate work schedule",
        value: "Employer prepopulate work schedule",
      },
      {
        text: "Employee to checkin each day",
        value: "Employee to checkin each day",
      },
    ],
  }),
  watch: {
    chosenPaymentIdType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-payment-id-types",
          this.chosenPaymentIdType ? this.chosenPaymentIdType : ""
        );
      }
    },
  },
  async mounted() {
    this.initial
      ? (this.chosenPaymentIdType = this.initial)
      : (this.chosenPaymentIdType = "");
  },
  methods: {},
};
</script>

<style lang="sass"></style>
