<template>
  <v-autocomplete
    v-model="chosenGender"
    v-bind="$attrs"
    :items="formattedGenders"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "GenderPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "";
      },
    },
  },
  data: () => ({
    chosenGender: "",
    isLoading: false,
    formattedGenders: [
      { text: "Male", value: "Male" },
      { text: "Female", value: "Female" },
      { text: "Other", value: "Other" },
    ],
  }),
  watch: {
    initial(newValue, oldValue) {
      this.chosenGender = this.initial;
    },
    chosenGender(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-gender",
          this.chosenGender ? this.chosenGender : ""
        );
      }
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass"></style>
