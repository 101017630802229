var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","light":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(_vm.buttonText)?_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"elevation":"3","fab":"","x-small":""}},'v-btn',_vm.$attrs,false),on),[_vm._v(" "+_vm._s(_vm.buttonText)+" ")]):_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"fab":"","x-small":"","color":"success","elevation":3}},'v-btn',_vm.$attrs,false),on),[_c('v-icon',[_vm._v(" mdi-email ")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('h2',{staticClass:"heading my-2"},[_vm._v(" Contact "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")]),_c('v-spacer'),_c('v-spacer'),_c('v-btn',{staticClass:"text-right",attrs:{"color":"primary","fab":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v(" mdi-close ")])],1)],1),_c('v-card-text',[_c('v-form',{ref:"UserContactForm",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('base-loading',{attrs:{"is-loading":_vm.isLoading}}),(_vm.successMessage)?_c('v-alert',{attrs:{"type":"success"}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e(),(_vm.errorMessage)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('h3',{staticClass:"mb-4"},[_vm._v("Would you like to send email correspondence?")]),_c('v-text-field',{attrs:{"color":"warning","label":`To ${_vm.user.firstName} ${_vm.user.lastName}`,"outlined":"","required":"","readonly":true,"rules":[
            (v) => !!v || 'To Email Address is required',
            (v) =>
              /.+@.+\..+/.test(v) || 'Email Address format must be valid',
          ]},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}}),_c('v-text-field',{attrs:{"color":"warning","label":`From ${_vm.me.firstName} ${_vm.me.lastName}`,"outlined":"","required":"","readonly":true,"rules":[
            (v) => !!v || 'From Email Address is required',
            (v) =>
              /.+@.+\..+/.test(v) || 'Email Address format must be valid',
          ]},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}}),_c('v-textarea',{attrs:{"color":"primary","label":"Message","outlined":"","counter":"","required":"","rules":[(v) => !!v || 'Message is required']},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('small',[_vm._v("*indicates required field")])],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"disabled":!_vm.formValid,"color":"'#0a2752'"},on:{"click":function($event){return _vm.sendEmail()}}},[_vm._v(" Send Email ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }