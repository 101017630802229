import { loadView } from "./functions";

export const AdminChildren = {
  children: [
    {
      path: "dashboard",
      name: "Dashboard",
      component: loadView("Admin/Dashboard"),
      ...userMeta(),
    },
    {
      path: "landing",
      name: "Landing",
      component: loadView("Admin/Landing"),
      ...userMeta(),
    },
    {
      path: "users",
      name: "Users",
      component: loadView("Admin/Users/View"),
      ...userMeta(),
    },
    {
      path: "user/:id",
      name: "User Details",
      component: loadView("Admin/Users/Details"),
      ...userMeta(),
    },
    {
      path: "user/:id/edit",
      name: "User Details Edit",
      component: loadView("Admin/Users/Edit"),
      ...userMeta(),
    },
    {
      path: "consultants",
      name: "Consultants",
      component: loadView("Admin/Consultants/View"),
      ...userMeta(),
    },
    {
      path: "consultant/:id",
      name: "Consultant Details",
      component: loadView("Admin/Consultants/Details"),
      ...userMeta(),
    },
    {
      path: "consultant/:id/edit",
      name: "Consultant Details Edit",
      component: loadView("Admin/Consultants/Edit"),
      ...userMeta(),
    },
    //Employers
    {
      path: "employers",
      name: "Employers",
      component: loadView("Admin/Employers/View"),
      ...userMeta(),
    },
    {
      path: "employer/:id",
      name: "Employer Details",
      component: loadView("Admin/Employers/Details"),
      ...userMeta(),
    },
    {
      path: "employer/:id/edit",
      name: "Employer Details Edit",
      component: loadView("Admin/Employers/Edit"),
      ...userMeta(),
    },

    ///Notifications

    //Contract Templates
    {
      path: "contracttemplate",
      name: "Contract Templates",
      component: loadView("Admin/ContractTemplates/View"),
      ...userMeta(),
    },
    {
      path: "contracttemplate/:id/edit",
      name: "Edit Contract Template",
      component: loadView("Admin/ContractTemplates/Edit"),
      ...userMeta(),
    },
    {
      path: "notifications",
      name: "Notifications",
      component: loadView("Admin/Notifications/View"),
      ...userMeta(),
    },
    {
      path: "notification/:id",
      name: "Notification Details",
      component: loadView("Admin/Notifications/Details"),
      ...userMeta(),
    },
    {
      path: "notification/:id/edit",
      name: "EditNotifications",
      component: loadView("Admin/Notifications/Edit"),
      ...userMeta(),
    },

    //
    ///Support
    // {
    //   path: "support",
    //   name: "Support",
    //   component: loadView("Admin/Support/View"),
    //   ...userMeta(),
    // },
    // {
    //   path: "support/:id",
    //   name: "Consultant Details",
    //   component: loadView("Admin/Consultants/Details"),
    //   ...userMeta(),
    // },
    // {
    //   path: "support/:id/edit",
    //   name: "Consultant Details Edit",
    //   component: loadView("Admin/Support/Edit"),
    //   ...userMeta(),
    // },

    ///

    {
      path: "admins",
      name: "Admins",
      component: loadView("Admin/Admins/View"),
      ...userMeta(),
    },

    {
      path: "admin/support",
      name: "Support",
      component: loadView("Admin/Support/View"),
      ...userMeta(),
    },
    {
      path: "admin/:id",
      name: "Admin Details",
      component: loadView("Admin/Admins/Details"),
      ...userMeta(),
    },
    {
      path: "admin/:id/edit",
      name: "Admin Details Edit",
      component: loadView("Admin/Admins/Edit"),
      ...userMeta(),
    },

    {
      path: "payments",
      name: "Payments",
      component: loadView("Admin/Payments/View"),
      ...userMeta(),
    },

    //////
  ],
};

function userMeta() {
  return {
    meta: { authorize: ["admin"] },
  };
}
