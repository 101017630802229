<template>
  <v-form
    ref="UserCreateForm"
    v-model="formValid"
    :style="{ background: '#fff', width: '100vw'}"
  >
    <base-loading :is-loading="isLoading" />
    <base-dialog-notification ref="userCreateConfirm" />
    <base-snackbar-notification ref="userCreateSnackbar" />

    <div v-if="formType == 'default'"></div>
    <!-- class="New-employee-create" -->
    <div v-else-if="formType == 'employeeCreate'">
      <v-stepper v-model="step" vertical height="100vh">
        <v-stepper-step :complete="step > 1" step="1">
          <span class="text-h4">Employee personal details</span>
        </v-stepper-step>

        <v-stepper-content step="1">
          <!-- <v-card color="#fff" class="mb-12" height="auto"> -->
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="user.firstName"
                :error-messages="formErrorMessages.firstname"
                label="First Name"
                required
                :rules="[(v) => !!v || 'First name is required']"
                outlined
                dense
                class="mt-1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="user.lastName"
                :error-messages="formErrorMessages.lastname"
                label="Last Name"
                required
                :rules="[(v) => !!v || 'Last name is required']"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="pb-0">
              <v-text-field
                  v-model="user.email"
                  label="Email"
                  outlined
                  :error-messages="formErrorMessages.email"
                  dense
                  hint="Optional"
              />
            </v-col>
            <v-col cols="12" class="pb-0">
              <register-mobile-number-input
                  @updateMobileNumberInput="mobileNumberFunc"
                  :allowTestNumbers="true"
                  :enableAppendIcon="false"
                  :sizeDense="true"
                  :providedErrorMessage="formErrorMessages.mobile"
              />
            </v-col>

            <v-col cols="12" class="pb-0">
              <base-identification-type-picker
                :initial="'South African ID'"
                label="Identification Type"
                outlined
                :chosen-identification-types.sync="idType"
                dense
              />
            </v-col>

            <v-col cols="12" class="pb-0">
              <v-text-field
                v-if="idType == 'South African ID'"
                :error-messages="formErrorMessages.idNumber"
                v-model="user.idNumber"
                label="ID Number"
                type="tel"
                clearable
                outlined
                dense
              />
              <v-text-field
                v-else
                v-model="user.passport"
                label="Passport Number"
                clearable
                outlined
                dense
              />
            </v-col>
          </v-row>
          <div class="d-flex justify-end mb-2">
            <v-btn class="mt-2" color="primary" @click="saveUserDetails()">Continue</v-btn>
          </div>
          <!-- <v-btn text> Cancel </v-btn> -->
        </v-stepper-content>
        <!-- :complete="step > 2" -->
        <v-stepper-step :complete="step > 2" step="2">
          <span class="text-h4">Employee bank details</span>
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model="user.accountHolder"
                label="Account Holder Name"
                outlined
                :error-messages="formErrorMessages.accountHolder"
                dense
                class="mt-1"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                v-model.trim="user.accountNumber"
                type="tel"
                label="Account Number"
                required
                outlined
                :error-messages="formErrorMessages.accountNumber"
                dense
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="pb-0">
              <base-bank-picker
                :chosen-bank.sync="user.bankName"
                :initial="user.bankName"
                label="Bank"
                outlined
                :error-messages="formErrorMessages.bankName"
                dense
              />
            </v-col>
            <v-col cols="12" class="pb-0">
              <base-bank-branch-picker
                :chosen-bank-branch.sync="user.branchCode"
                :initial="user.branchCode"
                label="Branch Code"
                outlined
                :error-messages="formErrorMessages.branchCode"
                dense
              />
            </v-col>
            <v-col cols="12" class="pb-0">
              <base-account-type-picker
                  :chosen-account-type.sync="user.accountType"
                  :initial="user.accountType"
                  label="Account Type"
                  required
                  outlined
                  hint="Capitec, Tyme and Discovery bank are Savings"
                  :rules="[(v) => !!v || 'Account type is required']"
                  :error-messages="formErrorMessages.accountType"
                  dense
              />
            </v-col>
          </v-row>
          <v-row class="ma-2 mt-4 d-flex justify-end">
            <v-btn color="primary" class="mr-2" @click="step = 1" outlined>
              Previous step</v-btn
            >
            <v-btn
              color="primary"
              @click="saveUserDetails()"
            >
              Save
            </v-btn>

          </v-row>
        </v-stepper-content>
      </v-stepper>
    </div>
  </v-form>
  <!-- </v-card-text> -->
  <!-- </v-card> -->
</template>

<script>
import { gql } from "apollo-boost";
import UserEditPicker from "./UserEditPicker.vue";
import Calender from "../../views/Employer/Calender.vue";
import RegisterMobileNumberInput from '@/components/Base/RegisterMobileNumberInput.vue'
// import moment from 'moment'
export default {
  props: {
    employerID: {
      type: String,
      default: "",
    },
    close: {
      type: Boolean,
      default: false,
    },
    createdBy: {
      type: String,
      default: "",
    },
    workAddress: {
      type: String,
      default: "",
    },
    formType: {
      type: String,
      default: "default",
    },
    role: {
      type: String,
      default: "employee",
    },
  },

  name: "UserCreate",
  documents: [],

  components: {
    UserEditPicker,
    Calender,
    RegisterMobileNumberInput
  },
  data: () => ({
    isLoading: false,
    formValid: true,
    step: 1,
    userId: "",
    idType: "South African ID",
    user: {
      password: "Test1234!!",
      firstName: "",
      lastName: "",
      paymentSetupType: "",
      telNumber: "",
      email: "",
      role: "",
      createdBy: "",
      accountType: "",
      accountNumber: "",
      accountHolder: "",
      bankName: "",
      branchCode: "",
      workAddress: "",
      jobTitle: "",
      employers: [],
      passport: "",
    },
    formErrorMessages: {
      firstname: '',
      lastname: '',
      email: '',
      mobile: '',
      jobTitle: '',
      idNumber: '',
      paymentSetupType: '',
      accountHolder: '',
      accountNumber: '',
      bankName: '',
      branchCode: '',
      accountType: '',
    },
    successMessage: "",
    errorMessage: "",
  }),
  computed: {},
  async mounted() {},
  methods: {
    mobileNumberFunc(value) {
      this.user.telNumber = value
    },
    async save() {
      this.isLoading = true;
      const self = this;
      await this.saveUser()
        .then(() => {
          // this.$swal("Success!", "User was created", "success");
          // window.location.href = `/admin/users`;
        })
        .catch((e) => {
          console.log(e);
          /*this.$swal(
            "Error!",
            `Something went wrong when creating the User, check data and try again later!`,
            "error"
          );*/
        });
      this.isLoading = false;
    },
    async refreshClientData() {
      this.$apollo.queries.users.refetch();
    },
    async refreshPage() {
      this.$router.go(this.$route.currentRoute);
    },
    async saveUserDetails() {
      const self = this;
      const user = this.user;
      const empty = `cannot be empty`;
      this.user.role = "employee";
      (this.user.createdBy = this.createdBy),
        (this.user.workAddress = this.workAddress);
      this.user.employers = [this.employerID];
      this.formErrorMessages.firstname = ''
      this.formErrorMessages.lastname = ''
      this.formErrorMessages.email = ''
      this.formErrorMessages.mobile = ''
      this.formErrorMessages.jobTitle = ''
      this.formErrorMessages.idNumber = ''

      var ex =
        /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/;
      if (this.step === 1) {
        if (this.idType === "South African ID") {
          user.passport = "";
        }
        if (this.idType === "Foreign passport") {
          user.idNumber = "";
        }
        self.errorMessage = "Sorry, there was a problem with the information you provided. Please check the form and correct any issues.";
        let isValidationError = false
        if (this.convertName(user.firstName) === "") {
          this.formErrorMessages.firstname = 'First name cannot be empty'
          isValidationError = true
        }
        if (this.convertName(user.lastName) === "") {
          this.formErrorMessages.lastname = 'First name cannot be empty'
          isValidationError = true
        }
        if (this.convertName(user.telNumber) === "") {
          this.formErrorMessages.mobile = 'Mobile number cannot be empty'
          isValidationError = true
        }
        if (this.idType === "South African ID" && this.convertName(user.idNumber) === "") {
          this.formErrorMessages.idNumber = 'ID number cannot be empty'
          isValidationError = true
        } else if (this.idType === "South African ID" && this.convertName(user.idNumber) !== "" && !ex.test(user.idNumber)) {
          this.formErrorMessages.idNumber = 'ID number is invalid.'
          isValidationError = true
        } else if (this.idType !== "South African ID" && this.convertName(user.passport) === "") {
          isValidationError = true
        }
        if (isValidationError) {
          this.$refs.userCreateSnackbar.open(self.errorMessage, "warning", 3000, true)
        } else {
          if (!isValidationError) {
            self.step = 2;
          }
        }

      } else if (this.step === 2) {
        await this.saveUserBankingDetails();
      }
    },
    async saveUserBankingDetails() {
      const user = this.user;
      let isValidationError = false
      this.formErrorMessages.accountHolder = ''
      this.formErrorMessages.accountNumber = ''
      this.formErrorMessages.bankName = ''
      this.formErrorMessages.branchCode = ''
      this.formErrorMessages.accountType = ''
      const errorMessage = "Sorry, there was a problem with the information you provided. Please check the form and correct any issues.";

      if (user.accountHolder === '') {
        this.formErrorMessages.accountHolder = 'Account holder cannot be empty.'
        isValidationError = true
      }
      if (user.accountNumber === '') {
        this.formErrorMessages.accountNumber = 'Account number cannot be empty.'
        isValidationError = true
      }
      if (user.bankName === '') {
        this.formErrorMessages.bankName = 'Bank name cannot be empty.'
        isValidationError = true
      }
      if (user.branchCode === '') {
        this.formErrorMessages.branchCode = 'Branch code cannot be empty.'
        isValidationError = true
      }
      if (user.accountType === '') {
        this.formErrorMessages.accountType = 'Account type cannot be empty.'
        isValidationError = true
      }
      if (isValidationError) {
        this.$refs.userCreateSnackbar.open(errorMessage, "warning", 3000, true)
      } else {
        await this.saveUser();
      }

    },
    convertName(name) {
      if (name != undefined) {
        name = name.trim().toLowerCase();
        if (name != "") {
          return (name = name.charAt(0).toUpperCase() + name.slice(1));
        } else {
          return (name = "");
        }
      } else {
        return (name = "");
      }
    },
    async saveUser() {
      const self = this;
      self.successMessage = "";
      self.errorMessage = "";
      try {
        const createdUser = await this.$apollo.mutate({
          mutation: gql`
            mutation createUser($user: UserCreateInput!) {
              createUser(user: $user) {
                id
              }
            }
          `,
          variables: {
            user: {
              firstName: self.user.firstName,
              lastName: self.user.lastName,
              email: self.user.email,
              password: "notused1234",
              passport: self.user.passport,
              telNumber: self.user.telNumber,
              role: "employee",
              accountType: self.user.accountType,
              accountNumber: self.user.accountNumber,
              accountHolder: self.user.accountHolder,
              bankName: self.user.bankName,
              branchCode: self.user.branchCode,
              employers: self.user.employers,
              paymentSetupType: null,
              workAddress: self.user.workAddress,
              jobTitle: null,
            },
          },
          watchLoading(isLoading, countModifier) {
            self.isLoading = isLoading;
          },
        });
        this.$emit('closeCreateEmployeeDialog', {success: 'Employee saved.'})
        this.$emit('createEmployeeSuccess', createdUser?.data?.createUser?.id)
        console.log(
          "createdUser?.data?.createUser?.id",
          createdUser?.data?.createUser?.id
        );
        if (createdUser && createdUser.data.createUser) {
          self.userId = createdUser?.data?.createUser?.id;
        }
        self.$eventHub.$emit("user-updated");
        // this.step = 3;
      } catch (error) {
        console.dir(error)
        const errorMessage = this.$_.get(error, 'graphQLErrors[0].message', 'Sorry, there was a problem creating the Employee. Please try again or contact support.')
        this.$emit('closeCreateEmployeeDialog', {error: errorMessage})
      }
    },
  },
};
</script>

<style lang="scss"></style>
