<template>
  <v-app v-if="me">
    <v-app-bar app elevation="0" class="user-header d-flex align-center">
      <v-row class="d-flex align-center p-0 my-n5">
        <v-col cols="2">
          <v-icon
            v-if="pageName != 'Home'"
            color="white"
            @click="$router.push('/' + me.role + '/landing')"
            >mdi-arrow-left</v-icon
          >
        </v-col>
        <v-col cols="8" class="white--text align-center text-h3"> {{ pageName }}</v-col>
        <v-col cols="2" class="align-start"><v-icon class="align-start" color="white" @click="$router.push('/')">mdi-lock</v-icon></v-col>
      </v-row
      >
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <router-view
          :pageName.sync="pageName"
          v-on:refreshNotifications="refreshNotifications"
      />
    </v-main>
    <v-footer app class="user-footer" height="72">
      <!-- v-model="tab" -->
      <v-tabs background-color="" centered dark icons-and-text>
        <v-tab xhref="#tab-1" @click="$router.push('/employer/landing')">
          Home
          <v-icon>mdi-home-outline</v-icon>
        </v-tab>

        <v-tab xhref="#tab-2" @click="$router.push('/employer/profile')">
          Profile
          <v-icon>mdi-account-outline</v-icon>
        </v-tab>

        <v-tab
          v-if="notificationsCount > 0"
          href="#tab-3"
          @click="$router.push('/employer/notifications')"
          ><div>
            <v-row class="mt-4 mx-auto">
              <v-badge
                bordered
                color="secondary black--text"
                :content="notificationsCount"
                overlap
              >
                <v-icon>mdi-bell</v-icon></v-badge
              ></v-row
            >
            <v-row> <p>Inbox</p></v-row>
          </div>
        </v-tab>
        <v-tab
          v-else
          href="#tab-3"
          @click="$router.push('/employer/notifications')"
        >
          <div>
            <v-row class="mt-4 mx-auto">
              <v-badge bordered color="blue" content="0" overlap>
                <v-icon>mdi-bell</v-icon></v-badge
              ></v-row
            >
            <v-row> <p>Inbox</p></v-row>
          </div>
        </v-tab>
      </v-tabs>
    </v-footer>
  </v-app>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";

export default {
  props: {
    pageName: {
      type: String,
      default: "Home",
    },
  },
  data() {
    return {
      drawer: null,
      isLoading: false,
      notifications: [],
      notificationsCount: 0,
      me: false,
      user: null,

      items: [
        {
          title: "Reporting",
          icon: "mdi-view-dashboard",
          href: "/employer/dashboard",
        },
        {
          title: "Documents",
          icon: "mdi-book-check",
          href: "/employer/documents",
        },
        {
          title: "Shopping",
          icon: "mdi-cart-outline",
          href: "/employer/documents",
        },
        {
          title: "Contracts",
          icon: "mdi-cart-outline",
          href: "/employer/contracts",
        },
        {
          title: "To Do lists",
          icon: "mdi-clipboard-list-outline",
          href: "/employer/documents",
        },
        {
          title: "Payments",
          icon: "mdi-cash-clock",
          href: "/employer/documents",
        },
      ],
      right: null,
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            role
            firstName
            lastName
            fullAddress
          }
        }
      `,
      fetchPolicy: "network-only",
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        if (response.data) {
          this.user = new User();
          this.user.id = this.me.id;
          this.user.role = this.me.role;
          this.user.firstName = this.me.firstName;
          this.user.lastName = this.me.lastName;
          this.user.fullAddress = this.me.fullAddress;
        }
      },
      error(error) {
        this.errorMsg = error;
      },
    },
    notifications: {
      query: gql`
        query Notifications($limit: Int!, $skip: Int!, $query: JSON!) {
          notifications(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              read
              assignedTo
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: { assignedTo: this.me.id, read: false },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        if (response.data) {
          this.notifications = response.data.notifications.records;
          this.notificationsCount = response.data.notifications.count;
        }
      },
      error(error) {
        console.error(error)
      },
    },
  },
  methods: {
    logout() {
      localStorage.setItem("token", "");
      localStorage.setItem("role", "");
      this.$router.push("/");
    },
    refreshNotifications(count) {
      this.notificationsCount = count
    },
  }
};
</script>

<style lang="scss" scoped></style>
