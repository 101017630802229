<template>
  <v-app v-if="me">
    <v-app-bar app class="header" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="user"
        ><h3 style="color: '#fff'">
          Welcome,
          <span> </span></h3
      ></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        offset-y
        bottom
        transition="scale-transition"
        :close-on-content-click="false"
        min-width="240"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="light" dark outlined v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout" class="mb-3">
            <v-list-item-icon>
              <v-icon>mdi-location-exit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" dark app>
      <div>
        <v-img
          @click="$router.push('/')"
          v-if="me.branch === '60f67355297f461ee095764b'"
          :contain="false"
          max-height="150px"
          class="py-1"
          v-bind="props"
          position="50% 50%"
          src="@/assets/FairwageLogo.png"
        ></v-img>
        <v-img
          @click="$router.push('/')"
          v-else-if="me.branch === '60f6736e297f461ee095764c'"
          :contain="false"
          max-height="150px"
          v-bind="props"
          position="50% 50%"
          class="py-1"
          src="@/assets/FairwageLogo.png"
        ></v-img>
        <v-img
          @click="$router.push('/')"
          v-else-if="me.branch === '60f673fb297f461ee095764e'"
          :contain="false"
          max-height="150px"
          v-bind="props"
          position="50% 50%"
          class="py-1"
          src="@/assets/FairwageLogo.png"
        ></v-img>
        <v-img
          v-else
          @click="$router.push('/')"
          :contain="false"
          max-height="150px"
          v-bind="props"
          position="50% 50%"
          class="py-1"
          src="@/assets/FairwageLogo.png"
        ></v-img>
      </div>
      <v-divider></v-divider>

      <v-list nav dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.href"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              ><h1>{{ item.title }}</h1></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-btn
          @click="myFunction()"
          absolute
          bottom
          dark
          rounded
          large
          color="primary"
        >
          <v-icon left>mdi-progress-question</v-icon>
          <h1>Help</h1>
        </v-btn>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <v-col>
          <transition name="fade">
            <router-view />
          </transition>
        </v-col>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";

export default {
  data() {
    return {
      drawer: null,
      imgText: "",
      me: null,
      user: null,

      items: [
        // {
        //   title: "Reporting",
        //   icon: "mdi-view-dashboard",
        //   href: "/consultant/dashboard",
        // },
        {
          title: "Clients",
          icon: "mdi-account-circle",
          href: "/consultant/users",
        },
      ],
      right: null,
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            role
            firstName
            lastName
            branch
            branchName
          }
        }
      `,
      fetchPolicy: "network-only",
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        if (response.data) {
          this.user = new User();
          this.user.id = this.me.id;
          this.user.role = this.me.role;
          this.user.firstName = this.me.firstName;
          this.user.lastName = this.me.lastName;
        }
      },
      error(error) {
        this.errorMsg = error;
      },
    },
  },
  computed: {},
  methods: {
    myFunction: function () {
      window.open(
        "https://media.publit.io/file/medsafu/HelpDocs/Consultant-Help-document.pdf",
        "_blank"
      );
    },
    logout() {
      localStorage.setItem("token", "");
      localStorage.setItem("role", "");
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped></style>
