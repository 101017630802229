export class Notification {
  constructor() {
    this.id = "";
    this.description = "";
    this.createdBy = "";
    this.done = false;
    this.assignedTo = "";
    this.assignedToName = "";
    this.comment = "";
    this.read = false;
    this.deleted = false;
    this.createdBy = "";
    this.updatedBy = "";
    this.createdByName = "";
    this.updatedByName = "";
    this.createdAt = null;
    this.updatedAt = null;
    this.employerAssignedTo = "";
    this.employerAssignedToName = "";
    this.employerNumber = "";
  }
}
