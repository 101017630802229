<template>
    <v-text-field
      v-model="mobileNumber"
      label="Mobile Number"
      prefix="+27"
      outlined
      required
      type="tel"
      name="tel"
      clearable
      :dense = "sizeDense"
      placeholder="083 123 5678"
      :append-icon="appendIconName"
      :rules="[rules.required, rules.zaMobileNumber]"
      :error-messages="errorMessage"
    />
</template>

<script>
export default {
  name: "RegisterMobileNumberInput",
  props: {
    allowTestNumbers: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialValue: {
      type: String,
      required: false,
      default: '',
    },
    sizeDense: {
      type: Boolean,
      required: false,
      default: false,
    },
    enableAppendIcon: {
      type: Boolean,
      required: false,
      default: true
    },
    providedErrorMessage: {
      type: String,
      required: false,
      default: ''
    },
  },
  data() {
    return {
      rules: {
        zaMobileNumber: (v) => {
          const regex = this.telNumberRegex
          return regex.test(v) || "Not a valid South African mobile number"
        },
        required: value => !!value || 'Mobile number required',
      },
      mobileNumber: '',
      appendIconName: 'mdi-phone',
      errorMessage: ''
    }
  },
  created() {
    this.mobileNumber = this.initialValue
    if(!this.enableAppendIcon) this.appendIconName = ''
    if(this.providedErrorMessage) this.errorMessage = this.providedErrorMessage
  },
  watch: {
    mobileNumber(value) {
      const number = this.formatFinalNumber(value)
      this.$emit('updateMobileNumberInput', number)
    },
  },
  methods: {
    formatFinalNumber(value) {
      if (this.telNumberRegex.test(value)) {
        return value.replace(/^(\+27|27|0|)/, "27")
      }
      return ''
    }
  },
  computed: {
    telNumberRegex() {
      if (this.allowTestNumbers) {
        return /^(\+27|27|0|)[6-8][0-9#]{8,10}$/
      } else {
        return /^(\+27|27|0|)[6-8][0-9]{8}$/
      }
    },
  },
}
</script>

<style lang="scss">
</style>
