<template>
  <v-autocomplete
    v-model="chosenBankBranch"
    v-bind="$attrs"
    :items="formattedBanks"
    append-icon="mdi-menu-down"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "BankBranchPicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenBankBranch: "",
    isLoading: false,
    formattedBanks: [
      { text: "Absa Bank - 632005", value: "632005" },
      { text: "Capitec Bank - 470010", value: "470010" },
      {
        text: "First National Bank - 250655",
        value: "250655",
      },
      { text: "Investec Bank - 580105", value: "580105" },
      {
        text: "Nedbank -198765",
        value: "198765",
      },
      {
        text: "Nedbank C.S.A- 720026",
        value: "720026",
      },
      { text: "Postbank - 460005", value: "460005" },
      {
        text: "Standard Bank - 051001",
        value: "051001",
      },
      { text: "African Bank - 430000", value: "430000" },
      { text: "Albaraka Bank - 800000", value: "800000" },
      { text: "Bank of Namibia - 980172", value: "980172" },
      { text: "Bidvest Bank - 462005", value: "462005" },
      {
        text: "Central Bank of Lesotho - 586611",
        value: "586611",
      },
      { text: "Citi Bank - 350005", value: "350005" },
      { text: "Discovery Bank - 679000", value: "679000" },
      {
        text: "Finbond Mutual Bank - 589000",
        value: "589000",
      },
      {
        text: "First National Bank Lesotho - 280061",
        value: "280061",
      },
      {
        text: "First National Bank Namibia - 282672",
        value: "282672",
      },
      {
        text: "First National Bank Swaziland - 287364",
        value: "287364",
      },
      { text: "Grinrod Bank - 584000", value: "584000" },
      { text: "HSBC Bank - 587000", value: "587000" },
      {
        text: "JP Morgan Chase Bank - 432000",
        value: "432000",
      },
      { text: "Meeg Bank - 470010", value: "470010" },
      { text: "Merchantile Bank - 450105", value: "450105" },
      { text: "MTN Banking - 490991", value: "490991" },
      {
        text: "Standard Bank Namibia - 087373",
        value: "087373",
      },
      {
        text: "State Bank of India - 801000",
        value: "801000",
      },
      { text: "Ubank - 431010", value: "431010" },
      { text: "Unibank - 790005", value: "790005" },
      { text: "VBS Mutual Bank - 588000", value: "588000" },
      {
        text: "Olympus Mobile Bank - 585001",
        value: "585001",
      },
      { text: "Tyme Bank -678910", value: "678910" },
    ],
  }),
  watch: {
    chosenBankBranch(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-bank-branch",
          this.chosenBankBranch ? this.chosenBankBranch : ""
        );
      }
    },
  },
  async mounted() {
    this.initial
      ? (this.chosenBankBranch = this.initial)
      : (this.chosenBankBranch = "");
  },
  methods: {},
};
</script>

<style lang="sass"></style>
