export class Contract {
  constructor() {
    this.id = null
    this.jobTitle = null
    this.duties = null
    this.contractCurrency = "ZAR"
    this.terminationNoticePeriod = 0
    this.wagePaymentSchedule = null
    this.wagePaymentDate = null
    this.wagePaymentWeekDay = 'none'
    this.wagePeriod = null
    this.wagePeriodUnitType = null
    this.wageTracking = null
    this.wageRate = 0
    this.overtimeAvailable = false
    this.overtimeRate = 0
    this.publicHolidayRate = 0
    this.transportAllowanceRate = 0
    this.transportAllowancePeriod = 'none'
    this.accommodationAllowanceRate = 0
    this.accommodationAllowancePeriod = 'none'
    this.mealAllowanceRate = 0
    this.mealAllowancePeriod = 'none'
    this.hoursOfWorkStart = null
    this.hoursOfWorkEnd = null
    this.workSchedule = null
    this.lunchPeriod = 0
    this.leaveAvailable = false
    this.annualLeaveDays = 0
    this.sickLeaveDays = 0
    this.maternityLeaveDays = 0
    this.familyResponsibilityLeaveDays = 0
    this.createdAt = new Date().getTime()
    this.updatedAt = new Date().getTime()
  }
}
