<template>
  <div v-if="formType == 'default'">
    <!-- <v-breadcrumbs :items="crumbs"></v-breadcrumbs> -->
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col md="6">
        <v-card elevation="0">
          <v-card-text>
            <v-form ref="todolistForm" @submit.prevent="save()">
              <v-row>
                <v-col md="12">
                  <v-textarea
                    v-model="todolistModel.description"
                    label="Description of item needed"
                    required
                    placeholder="Wash floors, clean kitchen"
                    outlined
                    :rules="[(v) => !!v || 'Description of task is required']"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-btn type="submit" color="success" @click="taskDialog = false"
                >Save</v-btn
              >
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
  <div v-else-if="formType == 'markDone'">
    <v-form ref="todolistForm">
      <v-checkbox
        @click="save()"
        v-model="todolistModel.done"
        color="primary"
      ></v-checkbox>
    </v-form>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { Todolist } from "@/models/Todolist";
export default {
  name: "ToDoEdit",
  props: {
    assignedTo: {
      type: String,
      default: "",
    },
    dialogState: {
      type: Boolean,
      default: false,
    },
    assignedToName: {
      type: String,
      default: "",
    },
    fromId: {
      type: String,
      default: "",
    },
    formType: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      isEdit: false,
      isLoading: false,
      todolistModel: new Todolist(),
      me: "",
      taskDialog: false,
    };
  },
  created() {
    this.isEdit =
      (this.$route.params.id != 0 && this.$route.params.id != null) ||
      this.fromId != "";
  },

  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    todolist: {
      query: gql`
        query Todolist($id: ID!) {
          todolist(id: $id) {
            id
            description
            done
            assignedTo
            updatedBy
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: this.isEdit ? self.fromId : self.$route.params.id,
        };
      },
      result(response, key) {
        //  alert(JSON.stringify(this.todolist));
        this.isLoading = false;
        this.todolistModel.id = this.todolist.id;
        this.todolistModel.description = this.todolist.description;
        this.todolistModel.done = this.todolist.done;
        this.todolistModel.assignedTo = this.todolist.assignedTo;
        this.todolistModel.updatedBy = this.todolist.updatedBy;
      },
    },
  },
  methods: {
    async refreshToDoData() {
      this.$apollo.queries.todolist.refetch();
    },
    async save() {
      const self = this;
      if (!self.$refs.todolistForm.validate()) {
        self.$swal("Error", "Validation failed", "error");
        self.isLoading = false;
        return;
      }
      if (self.isEdit) {
        // update
        await this.updateTodolist()
          .then(() => {
            this.$swal({
              title: "Task list update",
              text: "Your employee's task list was updated, click ok to refresh",
              type: "success",
              showConfirmButton: true,
              showCancelButton: false,
              icon: "success",
              timer: 1500,
            }).then(
              function () {},
              // handling the promise rejection
              function (dismiss) {
                if (dismiss === "timer") {
                  window.location.href = `/employer/toDoList`;
                  // this.$router.go(this.$route.currentRoute);
                }
              }
            );
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              "something went wrong when trying to update the task list item, try again later!",
              "error"
            );
          })
          .then(function () {
            window.location.href = `/employer/toDoList`;
          });
      } else {
        // create
        await this.createTodolist()

          .then(() => {
            this.$swal({
              title: "Task list update",
              text: "Your employee's task list was updated, click ok to refresh",
              type: "success",
              showConfirmButton: true,
              showCancelButton: false,
              icon: "success",
              timer: 1500,
            }).then(
              function () {},
              // handling the promise rejection
              function (dismiss) {
                if (dismiss === "timer") {
                  window.location.href = `/employer/toDoList`;
                }
              }
            );
          })
          .catch((e) => {
            this.$swal(
              "Error!",
              `Something went wrong when creating the task list item, check data and try again later!`,
              "error"
            );
          });
      }
    },
    async updateTodolist() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation update($todolist: TodolistUpdateInput!) {
              updateTodolist(todolist: $todolist) {
                id
                updated
              }
            }
          `,
          variables: {
            todolist: {
              id: self.todolistModel.id,
              description: self.todolistModel.description,
              done: self.todolistModel.done,
              updatedBy: self.me.id,
            },
          },
        });
        if (!response) {
          throw "Something went wrong when creating the todolist!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
      } catch (e) {
        throw e.message;
      }
    },
    async createTodolist() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createTodolist($todolist: TodolistCreateInput!) {
              createTodolist(todolist: $todolist) {
                id
              }
            }
          `,
          variables: {
            todolist: {
              description: self.todolistModel.description,
              createdBy: self.me.id,
              assignedTo: this.assignedTo,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the Todolist Item!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createTodolist) {
          self.todolistModel.id = response?.data?.createTodolist?.id;
        }
      } catch (e) {
        throw e.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
