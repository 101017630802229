<template>
  <v-app v-if="me">
    <v-app-bar app elevation="0" class="user-header">
      <v-row >
      <v-col cols="2">
      <v-icon
        v-if="pageName != 'Home'"
        color="white"
        @click="$router.push('/user/landing')"
        >mdi-arrow-left</v-icon
      >
      </v-col>
      <v-col cols="8" class="white--text align-center"> {{ pageName }}</v-col>
      </v-row>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main>
      <v-scroll-x-transition hide-on-leave>
        <router-view :pageName.sync="pageName" />
      </v-scroll-x-transition>
    </v-main>
    <v-footer app class="user-footer">
      <v-tabs  background-color="" centered dark icons-and-text>
        <v-tab xhref="#tab-1" @click="$router.push('/user/landing')">
          Home
          <v-icon>mdi-home-outline</v-icon>
        </v-tab>

        <v-tab xhref="#tab-2" @click="$router.push('/user/profile')">
          Profile
          <v-icon>mdi-account-outline</v-icon>
        </v-tab>

        <v-tab href="#tab-3" @click="$router.push('/')">
          Logout
          <v-icon>mdi-lock</v-icon>
        </v-tab>
      </v-tabs>
    </v-footer>
      <v-container fluid>
        <v-col>
          <transition name="fade">
             
          </transition>
        </v-col>
      </v-container>
  </v-app>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";

export default {
  props: {
    pageName: {
      type: String,
      default: "Landing",
    },
  },
  data() {
    return {
      drawer: null,

      me: null,
      user: null,

      items: [
            {
          title: "Home",
          icon: "mdi-view-dashboard",
          href: "/user/landing",
        },
        {
          title: "To Do lists",
          icon: "mdi-clipboard-list-outline",
          href: "/user/todolists",
        },
        {
          title: "Shopping",
          icon: "mdi-cart-outline",
          href: "/user/shopping",
        },

        {
          title: "Calendar",
          icon: "mdi-calendar-month",
          href: "/user/calendar",
        },
      ],
      right: null,
    };
  },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            role
            firstName
            lastName
          }
        }
      `,
      fetchPolicy: "network-only",
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        if (response.data) {
          this.user = new User();
          this.user.id = this.me.id;
          this.user.role = this.me.role;
          this.user.firstName = this.me.firstName;
          this.user.lastName = this.me.lastName;
        }
      },
      error(error) {
        this.errorMsg = error;
      },
    },
  },
  methods: {
    logout() {
      localStorage.setItem("token", "");
      localStorage.setItem("role", "");
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>

</style>
