<template>
  <v-autocomplete
    v-model="chosenIdentificationType"
    v-bind="$attrs"
    :items="formattedIdentificationTypes"
    append-icon="mdi-menu-down"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "IdentificationTypePicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenIdentificationType: "",
    isLoading: false,
    formattedIdentificationTypes: [
      { text: "South African ID", value: "South African ID" },
      { text: "Foreign passport", value: "Foreign passport" },
    ],
  }),
  watch: {
    chosenIdentificationType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-identification-types",
          this.chosenIdentificationType ? this.chosenIdentificationType : ""
        );
      }
    },
  },
  async mounted() {
    this.initial
      ? (this.chosenIdentificationType = this.initial)
      : (this.chosenIdentificationType = "");
  },
  methods: {},
};
</script>

<style lang="sass"></style>
