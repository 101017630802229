<template>
  <v-autocomplete
    v-model="chosenUser"
    v-bind="$attrs"
    :items="formattedUsers"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
    small-chips
    clearable
  />
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "UserEditPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "";
      },
    },
    role: {
      type: String,
      default: function() {
        return "";
      },
    },
  },
  data: () => ({
    chosenUser: [],
    totalRecords: 0,
    isLoading: false,
    returnedUsers: [],
    formattedUsers: [],
  }),
  apollo: {
    users: {
      query: gql`
        query Users($limit: Int $query: JSON!) {
          users(limit: $limit query: $query) {
            records {
              id
              firstName
              lastName
            }
          }
        }
      `,
      variables() {
        var query = {};
        console.log(this.role)
        if (this.role) {
          query = { role: this.role };
        }
        return {
          limit: 9999,
          query: query,
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.returnedUsers = data.users.records;
        //console.log(this.returnedUsers)
        this.formattedUsers = _.map(this.returnedUsers, (user) => {
          return { text: `${user.firstName} ${user.lastName}`, value: user.id };
        });
        //console.log(this.formattedUsers)
      },
    },
  },
  watch: {
     chosenUser(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-user",
          this.chosenUser ? this.chosenUser : ""
        );
      }
    },
  },
  async created() {
    this.$eventHub.$on("users-updated", this.refreshData);
  },
  /*watch: {
    /*initial(newValue, oldValue) {
      this.chosenUser = this.initial;
    },*
    chosenUser(newValue) { 
      console.log("newValue: " + newValue )
      //if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-user",
          this.chosenUser ? this.chosenUser : null
        );
      //}
    },
  },*/
  async mounted() {
    this.initial
      ? (this.chosenUser = this.initial)
      : (this.chosenUser = "");
  },
  methods: {
    refreshData() {
      console.log("Refetching users");
      this.$apollo.queries.users.refetch();
    },
  },
};
</script>

<style lang="sass"></style>
