<template>
  <v-container fluid>
    <base-loader :isLoading="isLoading"></base-loader>
    <base-dialog-notification ref="confirm" />
    <base-snackbar-notification ref="mainSnackbar" />
    <v-row class="d-flex align-start">
      <v-col cols="12" md="12">
        <template v-if="calendarRefreshed">
          <div>
            <v-sheet tile height="48" class="d-flex">
              <v-btn icon color="primary" @click="prev()">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-toolbar-title class="ma-2" v-if="calendarTitle">
                {{ calendarTitle }}
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon color="primary" @click="next()">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </v-sheet>
            <v-sheet height="480px">
              <v-calendar
                ref="calendar"
                v-model="value"
                :weekdays="weekday"
                :type="type"
                color="primary"
                @change="calendarRefresh"
                @click:date="toggleCalendarDetailView"
                @click:day="toggleCalendarDetailView"
              >
                <template v-slot:day="{ date }">
                  <v-row class="justify-center">
                    <template v-if="workedDates[date]">
                      <v-sheet
                          :key="date"
                          :title="workedDates[date].name"
                          color="secondary"
                          class="rounded-circle mt-3"
                      >
                        <v-icon color="white" class="px-1 py-1" dense>{{ workedDates[date].icon }}</v-icon>
                      </v-sheet>
                    </template>
                  </v-row>
                </template>
              </v-calendar>
            </v-sheet>
          </div>
          </template>
        </v-col>
    </v-row>
    <v-row justify="center" v-if="calendarRefreshed && workedDatesDetailDate">
      <v-dialog
          v-model="showCalendarDetailView"
          persistent
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark color="primary">
            <v-btn
                icon
                dark
                @click="showCalendarDetailView = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="white--text">Edit</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <template v-if="workedDates[workedDatesDetailDate]">
          <v-list subheader>
            <v-list>
              <v-subheader
                  class="text-h5"
                  v-text="'Logged Work on ' + workedDatesDetailShortDate"
              ></v-subheader>
              <template v-for="(item, index) in workedDates[workedDatesDetailDate].workEvents">

                <v-list-item
                    :key="item.id"
                >
                  <v-list-item-avatar>
                    <v-avatar v-if="item.profilePicture" class="my-4">
                      <img :src="item.profilePicture" size="38" />
                    </v-avatar>
                    <v-avatar v-else class="my-4">
                      <img
                          :src="defaultProfileImage()"
                          size="38"
                      />
                    </v-avatar>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-html="item.firstName + ' ' + item.lastName + ' logged work:'"></v-list-item-title>
                    <v-list-item-subtitle>
                      <v-icon fab small color="black" class="mr-1">mdi-calendar-month</v-icon>{{item.workEventContent}}
                      <span class="black--text ml-2 mr-1">Status:</span>
                      <v-icon fab small :color="item.workEventColour">{{item.workEventIcon}}</v-icon>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <template v-if="item.processed"><v-btn
                        color="grey"
                        dark
                        dense
                        icon
                    ><v-icon>mdi-delete-off-outline</v-icon></v-btn></template>
                    <template v-else><v-btn
                        color="orange"
                        dark
                        dense
                        icon
                        @click="deleteWorkEvent(item, workedDatesDetailDate)"
                    ><v-icon>mdi-trash-can-outline</v-icon></v-btn></template>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
          </v-list>
          </template>
          <template v-else>
            <v-list subheader>
              <v-list >
                <v-subheader
                    class="text-h5"
                    v-text="'Logged Work on ' + workedDatesDetailShortDate"
                ></v-subheader>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title v-html="'No one has logged anything.'"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-list>
          </template>
          <template v-if="notWorkEventsUsers.length">
            <v-list subheader >
              <v-divider></v-divider>
              <v-list >
                <v-subheader class="text-h5">Manually Log Work</v-subheader>
                <template v-for="(item, index) in notWorkEventsUsers">

                  <v-list-item
                      :key="item.id"
                  >
                    <v-list-item-avatar>
                      <v-avatar v-if="item.profilePicture" class="my-4">
                        <img :src="item.profilePicture" size="38" />
                      </v-avatar>
                      <v-avatar v-else class="my-4">
                        <img
                            :src="defaultProfileImage()"
                            size="38"
                        />
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title v-html="item.firstName + ' ' + item.lastName"></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn
                          color="primary"
                          dark
                          dense
                          icon
                          @click="manuallyAddWorkEventUser(item, workedDatesDetailDate)"
                      ><v-icon>mdi-map-marker-check-outline</v-icon></v-btn>
                    </v-list-item-action>
                  </v-list-item>

                </template>
              </v-list>
            </v-list>
          </template>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row><v-spacer></v-spacer></v-row>
  </v-container>

</template>

<script>
import { gql } from "apollo-boost";
import moment from "moment";
import Holidays from "date-holidays";
import defaultProfile from "@/assets/avatar.png";
export default {
  props: {
    userIdPassed: {
      type: String,
      default: "",
    },
  },
  name: "Calendar",
  mounted() {
    this.$emit("update:pageName", this.$options.name);
    this.userContracts = this.$route.params.userContracts
  },
  beforeDestroy() {
    clearInterval(this.calendarRefreshTimer);
  },
  data() {
    return {
      showCalendarView: true,
      showCalendarDetailView: false,
      type: "month",
      mode: "stack",
      weekday: [0, 1, 2, 3, 4, 5, 6],
      workedDates: {},
      tempWorkedDates: {},
      workedDatesDetailDate: "",
      workedDatesDetailShortDate: "",
      notWorkEventsUsers: [],
      calendarTitle: "",
      calendarRefreshed: false,
      calendarRefreshTimer: null,
      value: "",
      eventsFirst: [],
      events: [],
      show: false,
      tab: 0,
      panel: 0,
      workingDaysItems: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      workingDays: [],
      colors: ['secondary', 'blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
      specialDaysItems: ["Public Holiday"],
      specialDays: [],
      tabs: ["Daily", "Weekly", "Monthly"],
      dailyRateRules: [
        (v) => (v && v.length >= 2) || "Daily amount needs to be double digits",
        (v) => (v && v >= 10) || "Daily amount should be above R200",
      ],
      WeekDayRateRules: [
        (v) => (v && v >= 10) || "Weekday amount should be above R200",
      ],
      weekendRateRules: [
        (v) => (v && v >= 10) || "Weekend amount should be above R200",
      ],
      publicHolidayRateRules: [
        (v) => (v && v >= 10) || "Public holiday amount should be above R200",
      ],
      weeklyRules: [
        (v) =>
          (v && v.length >= 2) || "Weekly amount needs to be double digits",
        (v) => (v && v >= 10) || "Weekly amount should be above R200",
      ],
      monthlyRules: [
        (v) =>
          (v && v.length >= 2) || "Monthly amount needs to be double digits",
        (v) => (v && v >= 10) || "Monthly amount should be above R200",
      ],
      payment: {
        weekdayRate: 0,
        weekendRate: 0,
        holidayRate: 0,
        employee: "",
        payDate: null,
        days: [],
        nrOfdays: 0,
        amount: 0,
        type: "",
        duration: "",
      },
      attributes: [
        {
          key: "today",
          highlight: true,
          dates: new Date(),
        },
      ],
      days: [],
      carryOverDays: [],
      selectedDates: [],
      menu1: false,
      menu2: false,
      menu: false,
      menu4: false,
      items: ["Monday", "Friday"],
      duration: [
        { text: "1 Month", value: 1 },
        { text: "3 Months", value: 3 },
        { text: "6 Months", value: 6 },
        { text: "1 Year", value: 12 },
        { text: "2 Year", value: 24 },
        { text: "3 Years", value: 36 },
        { text: "10 Years", value: 120 },
      ],
      newItems: [],
      selectedItem: null,
      isLoading: false,
      userId: "",
      amount: 0.0,
      RateType: "",
      hd: new Holidays("ZA"),
      users: [],
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,

    users: {
      query: gql`
        query Users($limit: Int, $query: JSON!) {
          users(limit: $limit, query: $query) {
            records {
              id
              firstName
              lastName
            }
          }
        }
      `,
      variables () {
        return {
          limit: 0,
          query: { employers: { $in: [this.me.id] }, role: "employee", deleted: { $ne: true } },
        };
      },
      result () {
        const startDate = new Date()
        this.users = this.users.records;
        this.getEvents(this.users, startDate)
      },
    },
  },
  methods: {
    defaultProfileImage() {
      return defaultProfile;
    },
    toggleCalendarDetailView ({date}) {
      this.workedDatesDetailDate = date
      this.workedDatesDetailShortDate = this.$moment(date).format('D MMM')
      this.notWorkEventsUsers = this.calculateNotWorkEvents(this.workedDates, this.users, date)
      this.showCalendarDetailView = true
    },
    calculateNotWorkEvents (workedDates, users, date) {
      if (!workedDates[date]) return users
      const workEventsUsers = workedDates[date].workEvents
      return users.filter(user => !workEventsUsers.find(workEventUser => workEventUser.userId === user.id))
    },
    async calendarRefresh ({start, end}) {
      const calendarDate = new Date(start.date)
      this.isLoading = true
      this.calendarTitle = calendarDate.toLocaleString('default', { month: 'long', year: 'numeric' });
      if(!this.users) {
        await this.$apollo.queries.users.refetch()
      }
      await this.getEvents(this.users, start.date)
      try {
        clearInterval(this.calendarRefreshTimer)
      } catch (error) {
        console.log('No refresh timer to clear')
      }
      this.calendarRefreshTimer = setInterval(() => {
        this.getEvents(this.users, start.date)
      }, 30000)
      this.isLoading = false
    },
    async next() {
      this.$refs.calendar.next()
    },
    async prev() {
      this.$refs.calendar.prev()
    },
    async getEvents(users, date) {
      if(!users) return
      if(!date) return
      const month = new Date(date).getMonth() + 1
      const year = new Date(date).getFullYear()
      this.tempWorkedDates = {}
      let userIndex = 0
      for (const user of users) {
        await this.$apollo.query({
          query: gql`
            query GetWorkEvents($id: ID!, $month: Int!, $year: Int) {
              workEvents(id: $id, month: $month, year: $year) {
                records {
                  id
                  createdAt
                  createdBy
                  assignedTo
                  eventDate
                  requiresApproval
                  approved
                  approvedBy
                  approvedAt
                  rejected
                  rejectedBy
                  rejectedAt
                  processed
                  processedAt
                }
              }
            }
          `,
          fetchPolicy: 'no-cache',
          variables: {
            id: user.id,
            month: month,
            year: year
          }
        }).then((data) => {
          const workEvents = _.get(data, 'data.workEvents.records', [])
          if (_.size(workEvents) > 0) {
            _.forEach(workEvents, (record) => {
              this.addCheckinToTempWorkedDates(user, record)
            })
          }
        }).catch((err) => {
          console.log('Error:', err)
          this.$refs.confirm.open("Error", "There was a problem while loading the Worked Days. Please try again or contact support.", "error")
        })
        userIndex++
      }

      const zeroPrefixedMonth = month < 10 ? `0${month}` : month
      // Loop through the month and compare the latest data with what we have. Update if there are differences.
      for (let i = 1; i <= 31; i++) {
        const day = i < 10 ? `0${i}` : i
        const workedDay = `${year}-${zeroPrefixedMonth}-${day}`
        const isTempTheSame = this.$_.isEqual(this.tempWorkedDates[workedDay], this.workedDates[workedDay])
        if (!isTempTheSame) {
          this.$set(this.workedDates, workedDay, this.tempWorkedDates[workedDay])
        }
      }
      this.calendarRefreshed = true
    },
    addCheckinToWorkedDates (user, workEvent) {
      const workedDay = new Date(workEvent.eventDate).toISOString().split('T')[0]
      const workEventObj = {
        id: workEvent.id,
        firstName: user.firstName,
        lastName: user.lastName,
        datetime: workEvent.eventDate,
        userId: workEvent.assignedTo,
        checkedInBy: workEvent.createdBy,
        createdAt: workEvent.createdAt,
        requiresApproval: workEvent.requiresApproval,
        approved: workEvent.approved,
        approvedBy: workEvent.approvedBy,
        approvedAt: workEvent.approvedAt,
        processed: workEvent.processed,
        processedAt: workEvent.processedAt,
        workEventContent: this.returnWorkEventContent(workEvent.createdAt),
        workEventIcon: this.returnWorkEventIcon(workEvent.requiresApproval, workEvent.approved, workEvent.processed),
        workEventColour: this.returnWorkEventColour(workEvent.requiresApproval, workEvent.approved, workEvent.processed)
      }
      const workedDayObj = {
        workEvents: [],
        name: [],
        icon: "mdi-numeric-1"
      }
      if(this.workedDates[workedDay]){
        if(!_.find(this.workedDates[workedDay].workEvents, { userId: workEventObj.userId })){
          this.workedDates[workedDay].workEvents.push(workEventObj)
          const totalworkEvents = _.size(this.workedDates[workedDay].workEvents)
          this.workedDates[workedDay].icon = this.returnIconName(totalworkEvents)
          this.workedDates[workedDay].name = "Multiple Employees"
        }
      } else {
        workedDayObj.workEvents.push(workEventObj)
        workedDayObj.name = workEventObj.firstName + ' ' + workEventObj.lastName
        this.$set(this.workedDates, workedDay, workedDayObj)
      }
    },
    addCheckinToTempWorkedDates (user, workEvent) {
      const workedDay = new Date(workEvent.eventDate).toISOString().split('T')[0]
      const approved = this.$_.get(workEvent, 'approved', false)
      const rejected = this.$_.get(workEvent, 'rejected', false)
      const processed = this.$_.get(workEvent, 'processed', false)
      const workEventObj = {
        id: workEvent.id,
        firstName: user.firstName,
        lastName: user.lastName,
        datetime: workEvent.eventDate,
        userId: workEvent.assignedTo,
        checkedInBy: workEvent.createdBy,
        createdAt: workEvent.createdAt,
        requiresApproval: workEvent.requiresApproval,
        approved: approved,
        approvedBy: workEvent.approvedBy,
        approvedAt: workEvent.approvedAt,
        rejected: rejected,
        rejectedBy: workEvent.rejectedBy,
        rejectedAt: workEvent.rejectedAt,
        processed: workEvent.processed,
        processedAt: workEvent.processedAt,
        workEventContent: this.returnWorkEventContent(workEvent.createdAt),
        workEventIcon: this.returnWorkEventIcon(workEvent.requiresApproval, approved, processed, rejected),
        workEventColour: this.returnWorkEventColour(workEvent.requiresApproval, approved, processed, rejected)
      }
      const workedDayObj = {
        workEvents: [],
        name: [],
        icon: "mdi-numeric-1"
      }
      if(this.tempWorkedDates[workedDay]){
        if(!this.$_.find(this.tempWorkedDates[workedDay].workEvents, { userId: workEventObj.userId })){
          this.tempWorkedDates[workedDay].workEvents.push(workEventObj)
          const totalworkEvents = _.size(this.tempWorkedDates[workedDay].workEvents)
          this.tempWorkedDates[workedDay].icon = this.returnIconName(totalworkEvents)
          this.tempWorkedDates[workedDay].name = "Multiple Employees"
        }
      } else {
        workedDayObj.workEvents.push(workEventObj)
        workedDayObj.name = workEventObj.firstName + ' ' + workEventObj.lastName
        this.$set(this.tempWorkedDates, workedDay, workedDayObj)
      }
    },
    returnIconName (count) {
      if (count === 0) return "mdi-check"
      if (count > 9) return "mdi-numeric-9-plus"
      return `mdi-numeric-${count}`
    },
    returnWorkEventContent (eventDate) {
      const date = this.$moment(eventDate).format('HH:mm Do MMM')
      return date
    },
    returnWorkEventColour(requiresApproval, approved, processed, rejected) {
      if (processed) {
        if(requiresApproval) {
          if (approved) {
            return 'secondary'
          } else {
            return 'orange'
          }
        } else {
          return 'secondary'
        }
      }
      if( requiresApproval) {
        if (approved){
          return 'secondary'
        } else if(rejected){
          return 'red darken-4'
        } else {
          return 'amber lighten-3'
        }
      }
      return 'secondary'
    },
    returnWorkEventIcon(requiresApproval, approved, processed, rejected) {
      if (processed) {
        if(requiresApproval) {
          if (approved) {
            return 'mdi-currency-usd'
          } else {
            return 'mdi-currency-usd-off'
          }
        } else {
          return 'mdi-currency-usd'
        }
      }
      if(requiresApproval) {
        if (approved){
          return 'mdi-check'
        } else if (rejected) {
          return 'mdi-close'
        } else {
          return 'mdi-progress-check'
        }
      }
      return 'mdi-check'
    },
    async manuallyAddWorkEventUser(item, workedDatesDetailDate) {
      this.isLoading = true
      const userContract = this.$_.find(this.userContracts, { userId: item.id })
      await this.$apollo.mutate({
        mutation: gql`
            mutation createWorkEvent($workEvent: WorkEventInput!) {
              createWorkEvent(workEvent: $workEvent) {
                id
                assignedTo
                createdBy
                eventDate
                createdAt
              }
            }
          `,
        variables: {
          workEvent: {
            assignedTo: item.id,
            eventDate: new Date(workedDatesDetailDate).getTime(),
            eventType: 'WORK',
            eventUnit: this.$_.get(userContract, 'wagePeriod', null),
            createdBy: this.me.id,
            createdAt: new Date().getTime(),
          },
        },
      }).then((result) => {
        const currentUser = _.find(this.users, { id: item.id })
        this.addCheckinToWorkedDates(currentUser, result.data.createWorkEvent)
        this.notWorkEventsUsers = this.calculateNotWorkEvents(this.workedDates, this.users, workedDatesDetailDate)
        this.isLoading = false
      })
      .catch((e) => {
        this.isLoading = false
        this.$refs.confirm.open("Error", "Your check-in failed. Please try again or contact support.", "error")
      })
    },
    async deleteWorkEvent(item, date) {

      await this.$apollo.mutate({
        mutation: gql`
            mutation ($id: ID!) {
              deleteWorkEvent(id: $id) {
                id
                deleted
              }
            }
          `,
        variables: {
          id: item.id
        }
      }).then((data) => {
        this.removeWorkEventFromWorkedDates(item.id, date)
        this.notWorkEventsUsers = this.calculateNotWorkEvents(this.workedDates, this.users, date)
      }).catch((error) => {
        console.log('Error: ', error)
        this.$refs.confirm.open("Error", "There was a problem while deleting the check-in. Please try again or contact support.", "error")
      })
    },
    removeWorkEventFromWorkedDates (id, date) {
      const newWorkEvents = _.filter(this.workedDates[date].workEvents, function(workEvent) {
        return workEvent.id !== id
      })
      this.$set(this.workedDates[date], 'workEvents', newWorkEvents)
      const totalWorkEvents = _.size(this.workedDates[date].workEvents)
      this.workedDates[date].icon = this.returnIconName(totalWorkEvents)
      if (totalWorkEvents === 0) {
        this.$delete(this.workedDates, date)
      } else if (totalWorkEvents === 1) {
        this.workedDates[date].name = this.workedDates[date].workEvents[0].firstName + ' ' + this.workedDates[date].workEvents[0].lastName
      } else {
        this.workedDates[date].name = "Multiple Employees"
      }
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    groupByKey(array, key) {
      return array.reduce((hash, obj) => {
        if (obj[key] === undefined) return hash;
        return Object.assign(hash, {
          [obj[key]]: (hash[obj[key]] || []).concat(obj),
        });
      }, {});
    },
    getEventColor(event) {
      return event.color;
    },
    isHoliday(date) {
      return this.hd.isHoliday(new Date(date));
    },
    saveSchedule(date) {
      this.$refs.menu.save(date);
      this.$refs.menu1.save(date);
      this.$refs.menu2.save(date);
      this.days.push(this.$refs.menu4.save(date));
    },
    savePerRateType(date) {
      if (this.isWeekend(date)) {
        this.amount += parseFloat(this.payment.weekendRate);
      } else if (this.specialDays.length > 0 && this.isHoliday(date)) {
        this.amount += parseFloat(this.payment.holidayRate);
      } else {
        this.amount += parseFloat(this.payment.weekdayRate);
      }
    },
    isWeekend(date) {
      return new Date(date).getDay() === 6 || new Date(date).getDay() === 0;
    },
    async save(type) {
      this.isLoading = true;
      const self = this;
      this.RateType = type;
      if (this.RateType == "Weekly") {
        this.amount = parseFloat(self.payment.amount, 2) * 4;
      } else {
        this.amount = parseFloat(self.payment.amount, 2);
      }
      try {
        if (this.payment.duration > 1) {
          for (var i = 0; i < this.payment.duration; i++) {
            var newdate = moment(this.payment.payDate).add(i, "months");
            var formatdate = newdate._d;
            this.payment.payDate = formatdate;
            this.daysInMonthPassed(newdate.format("YYYY"), newdate.format("M"));
            await this.createPayment(formatdate, this.fixDateDays(newdate));
            if (i == this.payment.duration - 1) {
              if (this.carryOverDays.length != 0) {
                await this.createPayment(formatdate, this.carryOverDays);
              }
            }
          }
        } else {
          await this.createPayment(this.payment.payDate, this.days);
        }
      } catch (e) {
        this.$swal("Error!", e.message, "error");
        this.isLoading = false;
      }
    },
    daysInMonthPassed(year, month) {
      const daysInMonth = moment(`${year - month}`, "YYYY-MM").daysInMonth();
      let days = [];
      for (let x = 1; x <= daysInMonth; x++) {
        const daysMonth = this.fomartDatePassed(`${year}-${month}-${x}`);
        if (daysMonth.workDay) {
          const currentDate = `${daysMonth.year}-${daysMonth.month}-${daysMonth.day}`;
          days.push(currentDate);
          if (
            this.specialDays.length <= 0 &&
            this.isHoliday(new Date(`${year}-${month}-${x}`))
          ) {
            days = days.filter((v) => v !== currentDate);
          }
        }
      }
      this.days = days;
    },
    fomartDatePassed(date) {
      const newDate = new Date(date);
      const newDay = newDate.getDate().toString().padStart(2, "0");
      const newYear = newDate.getFullYear();
      const newMonth = (newDate.getMonth() + 1).toString().padStart(2, "0");
      var dayName = this.workingDaysItems[newDate.getDay()];
      return {
        year: newYear,
        month: newMonth,
        day: newDay,
        workDay: this.workingDaysItems.some((substring) =>
          dayName.includes(substring)
        ),
      };
    },
    fixDateDays(newdate) {
      var formtedDays = [];
      var month = newdate.format("M");
      var day = newdate.format("D");
      var year = newdate.format("YYYY");
      this.carryOverDays.forEach((element) => {
        formtedDays.push(element);
      });
      this.carryOverDays = [];
      this.days.forEach((date) => {
        const newDate = new Date(date);
        const newDay = newDate.getDate().toString().padStart(2, "0");
        const newYear = newDate.getFullYear();
        const newMonth = (newDate.getMonth() + 1).toString().padStart(2, "0");
        month = month.toString().padStart(2, "0");
        if (day > newDay) {
          if (year > newYear) {
            formtedDays.push(`${year}-${month}-${newDay}`);
          } else {
            formtedDays.push(`${newYear}-${month}-${newDay}`);
          }
        } else {
          if (year > newYear) {
            if (month <= newMonth) {
              this.carryOverDays.push(`${newYear}-${newMonth}-${newDay}`);
            } else {
              this.carryOverDays.push(`${year}-${month}-${newDay}`);
            }
          } else {
            this.carryOverDays.push(`${year}-${month}-${newDay}`);
          }
        }
      });
      formtedDays.sort(function (a, b) {
        a = a.split("-");
        b = b.split("-");
        return Number(b[2] + b[1] + b[0]) - Number(a[2] + a[1] + a[0]);
      });
      return formtedDays;
    },
    async createPayment(payDate, formtedDays) {
      const self = this;
      if (this.RateType == "Daily") {
        this.amount = 0.0;
        formtedDays.forEach((date) => {
          this.savePerRateType(date);
        });
      }

      const response = await self.$apollo.mutate({
        mutation: gql`
          mutation createPayment($payment: PaymentCreateInput!) {
            createPayment(payment: $payment) {
              id
            }
          }
        `,
        variables: {
          payment: {
            employer: this.me.id,
            employee: this.userIdPassed,
            days: formtedDays,
            status: "scheduled",
            processBy: this.payment.payDate,
            period: this.RateType,
            amount: this.amount,
            publicHoliday: this.specialDays == 0 ? false : true,
            weekdayRate: parseFloat(self.payment.weekdayRate, 2),
            weekendRate: parseFloat(self.payment.weekendRate, 2),
            holidayRate: parseFloat(self.payment.holidayRate, 2),
            rate: parseFloat(self.payment.weekdayRate, 2),
            duration: this.payment.duration,
          },
        },
      });

      if (response?.data?.createPayment?.id) {
        this.$swal(
          "Success!",
          "Your employee was successfully configured for the duration of their contract",
          "success"
        ).then(() => {
          this.$router.go(this.$route.currentRoute);
        });
      } else {
        this.$swal("Error!", "Something went wrong, please try again later");
      }
      this.isLoading = false;
    },
  },

  computed: {},
};
</script>

<style scoped>
.herso {
  background: url("../../assets/landing.png") no-repeat center center fixed !important;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}


.card-header {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 20px;
}

.select-card {
  padding: 10px 30px;
  min-height: 60px;
  position: relative;
}

.top-sect .v-btn {
  width: calc(100% - 67px);
  min-height: 70px;
}

.top-sect .v-btn__content {
  color: #eeecf1 !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  letter-spacing: 0.75px;
}

.top-sect .v-card__actions {
  position: absolute;
  right: 10px;
  top: 10px;
}

.top-sect .v-icon.v-icon {
  color: #ffffff;
}

.select-list {
  margin-top: 10px;
}

.ico-point {
  font-size: 30px;
}

.app-btn {
  height: auto;
  min-height: 10px;
  background-color: #fff;
  min-width: 0;
  border-radius: 12px;
  padding: 0;
}

.app-btn .v-btn__content {
  display: block !important;
  padding: 10px;
}

.v-application .secondary.app-btn {
  background-color: #f1f3f6 !important;
  border-color: #f1f3f6 !important;
  color: #3f3d56;
  box-shadow: none;
}

.v-application .primary.app-btn {
  background-color: #6dd984 !important;
  border-color: #6dd984 !important;
  color: #fff;
}

.app-btn .v-btn__content i {
  margin-bottom: 10px;
  border: 2px solid;
  border-radius: 50%;
}

.theCaleSect,
.btn-group {
  text-align: center;
}

/*/////////////////////////*/
.forCale {
  background-color: white;
  padding: 10px !important;
  /* overflow: hidden; */
}

.forCale .names {
  background: #a5a6f6;
  border-radius: 10px;
}

.v-btn:not(.v-btn--round).v-size--default.app-btn {
  height: auto;

  min-width: auto;
  padding: 10px 10px !important;
}

/*/////////////////////////*/

.name-selecter-parent {
  border: 4px solid#A5A6F6;
  margin-bottom: 30px;
  border-radius: 10px;
  background: #5d5fef;
}

.name-selecter > button {
  width: 100%;
  position: relative;
}

.name-selecter > button i {
  position: absolute;
  right: 0;
}
</style>
