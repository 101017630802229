<template>
  <v-container fluid :style="{ background: '#fff', width: '100vw', height: '100vh' }" class="">
    <v-row>
      <v-col cols="12" md="12">
        <h2>Confirm Details</h2>
        <p></p>
        <form
            name="form"
            id="x1"
            method="POST"
            :action="payNowNetCash"
            target="_top"
            v-on:submit="emitSavePayment(payNowNetCash)"
        >
          <!---Form POST **--->
          <input
              type="text"
              name="M1"
              :value="payNowServiceKey"
              class="d-none"
              readonly
          />
          <!--- YOUR PAY NOW SERVICE KEY GOES IN HERE --->
          <input
              type="text"
              name="m2"
              :value="payNowSoftwareVendorKey"
              class="d-none"
              readonly
          />
          <!--- SOFTWARE VENDOR KEY GOES IN HERE --->
          <v-row>
            <v-col cols="6">
              <span class="font-weight-black">Payment Reference:</span>
            </v-col>
            <v-col cols="6">
              <span class="">{{ paymentRef }}</span>
              <input
                  class="d-none"
                  type="text"
                  name="p2"
                  :value="paymentRef"
                  readonly
              />
              <!---Unique ID for this / each transaction--->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <span class="font-weight-black">Payment for employee:</span>
            </v-col>
            <v-col cols="6">
              <span class="">{{ employeeFullName }}</span>
              <input
                  class="d-none"
                  type="text"
                  name="p3"
                  v-model="employeeFullName"
                  readonly
              />
              <!---Description of goods being purchased--->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <span class="font-weight-black">Amount:</span>
            </v-col>
            <v-col cols="6">
              <span class="">{{ returnCurrencyFormattedNumber(totalPayment, paymentCurrencyCode) }}</span>
              <input type="text" name="p4" :value="getRealAmount()" class="d-none"/>
              <!---Amount to be settled / paid--->
            </v-col>
          </v-row>
          <v-row v-if="meObjectReady">
            <v-col cols="6">
              <span class="font-weight-black">Mobile Number:</span>
            </v-col>
            <v-col cols="6">
              <span class="">{{ cleanedTelNumber() }}</span>
              <input
                  class="d-none"
                  type="text"
                  name="m11"
                  :value="cleanedTelNumber()"
              />
              <!---Card holders mobile number--->
            </v-col>
          </v-row>
          <input type="text" name="m4" :value="paymentId" class="hide-input" />
          <!---This is an extra field ***--->
          <input type="text" name="m5" :value="employeeId" class="hide-input" />
          <!---This is an extra field ***--->
          <input type="text" name="m6" :value="paymentRef" class="hide-input" />
          <!---This is an extra field ***--->
          <input
              type="text"
              name="m10"
              :value="paymentId"
              class="hide-input"
          />
          <input type="text" name="m14" value="1" class="hide-input" />
          <p></p>
          <v-row>
            <v-col cols="12" class="d-flex">
            <v-btn color="secondary" class="mx-auto black--text" elevation="0" type="submit">
              <v-icon class="mr-2">mdi-cash-multiple</v-icon>PAY
            </v-btn>
            </v-col>
          </v-row>
        </form>
      </v-col>
    </v-row>
    <p></p>
    <v-row>
      <v-col cols="12">
        <p style="margin-left: 10%; margin-right: 10%">
          <span class="font-weight-black">NB:</span> You will be redirected to a payment gateway to
          process your payment.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { User } from "@/models/User";
import { Payment } from "@/models/Payment";
import { gql } from "apollo-boost";

export default {
  components: {},
  name: "Process Payment",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    employeeFullName: {
      type: String,
      default: "",
    },
    employeeId: {
      type: String,
      default: "",
    },
    totalPayment: {
      type: Number,
      default: 0,
    },
    paymentCurrencyCode: {
      type: String,
      default: "ZAR",
    },
    paymentCurrencyString: {
      type: String,
      default: "R",
    },
    paymentId: {
      type: String,
      default: "",
    },
    paymentRef: {
      type: String,
      default: "",
    }
  },
  mounted() {
    this.$emit("update:pageName", this.$options.name);
  },
  data() {
    return {
      selectedItem: 1,
      paymentReference: "",
      isLoading: false,
      paymentModel: new Payment(),
      userModel: new User(),
      process: [""],
      payNowNetCash: this.$config.payNowNetCash,
      payNowServiceKey: this.$config.payNowServiceKey,
      payNowSoftwareVendorKey: this.$config.payNowSoftwareVendorKey,
    };
  },
  apollo: {
    me: gql`
      query {
        me {
          id
          email
          telNumber
        }
      }
    `,
  },
  methods: {
    cleanedTelNumber() {
      let number = this.me.telNumber
      if (number.startsWith('27')) {
        // remove the 27 from start of the telNumber
        number = '0' + number.replace(/[^0-9]/g, "").substring(2, 11)
      } else {
        number = number.replace(/[^0-9]/g, "").substring(0, 10);
      }
      return number
    },
    returnCurrencyFormattedNumber (number, currency) {
      console.log('Number:', number, ', Currency:', currency)
      if (number == null || number === 'undefined') return 'R 0.00'
      if (!currency) return `R ${number}`
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'narrowSymbol'
      })
      return formatter.format(number)
    },
    getRealAmount() {
      return this.totalPayment
    },
    emitSavePayment(data) {
      console.log('Emitting SavePaymentEvent, URL:', data)
    }
  },
  computed: {
    meObjectReady () {
      return (this.me && this.me.id)
    },
  },
};
</script>

<style scoped>
/*process payment*/
.hide-input {
  display: none;
}

.input-box {
  display: flex;
  align-items: center;
  max-width: 300px;
  background: #fff;
  border: none;

  padding-left: 0.5rem;
  overflow: hidden;
  font-family: sans-serif;
}

.input-box .prefix {
  font-weight: 300;
  font-size: 14px;
  color: #999;
}

.input-box input {
  flex-grow: 1;
  font-size: 14px;
  background: #fff;
  border: none;
  outline: none;
  padding: 0.5rem;
}

.input-box:focus-within {
  border: none;
}
</style>
