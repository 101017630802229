export class User {
  constructor() {
    this.id = "";
    this.firstName = "";
    this.lastName = "";
    this.title = "";
    this.relationship = "";
    this.gender = null;
    this.race = null;
    this.telNumber = "";
    this.altNumber = "";
    this.email = "";
    this.businessEmail = "";
    this.faxNumber = "";
    this.age = 0;
    this.employeeID = "";
    this.idNumber = "";
    this.passport = "";
    this.fullAddress = "";
    this.postalCode = "";
    this.region = "";
    this.industry = null;
    this.employers = [];
    this.employees = [];
    this.role = "";
    this.consultant = null;
    this.consultantName = "";
    this.referral = null;
    this.relationship = null;
    this.employees = null;
    this.employers = null;
    this.source = null;
    this.dates = null;
    this.updatedBy = null;
    this.updatedByName = "";
    this.profileStatus = null;
    this.accountType = "";
    this.accountNumber = "";
    this.accountHolder = "";
    this.bankName = "";
    this.branchCode = null;
    this.profilePicture = "";
    this.paymentSetupType = "";
    this.netPay = 0;
    this.contractHtml = "";
    this.jobTitle = "";
    this.payslipHtml = "";
    this.workAddress = "";
  }

  get fullName() {
    var toArray = [this.firstName, this.lastName];
    return toArray
      .filter((item) => item)
      .join(" ")
      .trim();
  }
}
