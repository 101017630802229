<template>
  <v-autocomplete
    v-model="chosenPaymentSetupType"
    v-bind="$attrs"
    :items="formattedPaymentSetupTypes"
    append-icon="mdi-menu-down"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "PaymentSelectionPicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenPaymentSetupType: "",
    isLoading: false,
    formattedPaymentSetupTypes: [
      {
        text: "Employer pre-populated work schedule.",
        value: "Employer pre-populated work schedule.",
      },
      {
        text: "Employee to check-in each day.",
        value: "Employee to check-in each day.",
      },
    ],
  }),
  watch: {
    chosenPaymentSetupType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-payment-setup-type",
          this.chosenPaymentSetupType ? this.chosenPaymentSetupType : ""
        );
      }
    },
  },
  async mounted() {
    this.initial
      ? (this.chosenPaymentSetupType = this.initial)
      : (this.chosenPaymentSetupType = "");
  },
  methods: {},
};
</script>

<style lang="sass"></style>
