<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="600px">
    <template v-slot:activator="{ on }">
      <v-card max-width="170">
        <v-card-text v-if="user && !isLoading">
          <v-img
            :src="
              user.profilePicture
                ? user.profilePicture
                : 'https://via.placeholder.com/250'
            "
            :contain="true"
            max-width="150"
            id="rounded-card"
            max-height="150"
            v-on="on"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular indeterminate color="grey" />
              </v-row>
            </template>
          </v-img>
          <v-btn
            class="white--text"
            fab
            small
            absolute
            top
            right
            v-bind="$attrs"
            color="teal"
            :elevation="8"
            v-on="on"
          >
            <v-icon> mdi-image </v-icon>
          </v-btn>
        </v-card-text>
      </v-card>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">Upload New Profile Picture</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <base-loading :is-loading="isLoading" />
          <v-alert
            v-if="successMessage"
            type="success"
            icon="mdi-firework"
            dense
          >
            {{ successMessage }}
          </v-alert>
          <v-alert v-if="errorMessage" type="error">
            {{ errorMessage }}
          </v-alert>
          <v-file-input
            v-model="newProfilePicture"
            accept="image/*"
            color="primary"
            label="Profile Picture"
            show-size
            prepend-icon="mdi-image-outline"
            counter
            :full-width="true"
            outlined
          >
            <template v-slot:selection="{ index, text }">
              <v-chip color="secondary" dark label small>
                {{ text }}
              </v-chip>
            </template>
          </v-file-input>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1" />
        <v-btn color="primary" @click="dialog = false"> Close </v-btn>
        <v-btn color="secondary" @click="saveUserProfilePicture()">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "UserProfilePictureUpdate",
  props: {
    id: {
      type: String,
      default: function () {
        return null;
      },
    },
  },
  data: () => ({
    dialog: false,
    isLoading: false,
    newProfilePicture: null,
    wasUpdated: false,
    successMessage: "",
    errorMessage: "",
  }),
  apollo: {
    user: {
      query: gql`
        query User($id: ID!) {
          user(id: $id) {
            id
            profilePicture
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        return {
          id: self.id,
        };
      },
      watchLoading(isLoading, countModifier) {
        console.log("UserProfilePictureUpdate is loading:", isLoading);
        this.isLoading = isLoading;
      },
    },
  },
  computed: {},
  async created() {
    this.$eventHub.$on("user-updated", this.refreshData);
  },
  beforeDestroy() {
    this.$eventHub.$off("user-updated");
  },
  methods: {
    async saveUserProfilePicture() {
      const self = this;
      self.successMessage = "";
      self.errorMessage = "";
      self.isLoading = true;
      try {
        var profilePictureAsDataURL = null;
        if (self.newProfilePicture) {
          profilePictureAsDataURL = await self.getFileAsDataURL();
        }
        const updatedUser = await this.$apollo.mutate({
          mutation: gql`
            mutation ($user: UserUpdateInput!) {
              updateUser(user: $user) {
                id
                updated
              }
            }
          `,
          variables: {
            user: {
              id: self.id,
              profilePicture: profilePictureAsDataURL,
            },
          },
        });
        console.log(updatedUser);
        self.isLoading = false;
        if (!updatedUser.data.updateUser.updated) {
          return "Something went wrong when updating the users profile picture!";
        }
        self.successMessage = `Profile Picture Saved Successfully: ${Date.now()}`;
        self.wasUpdated = true;
        self.$eventHub.$emit("user-updated");
      } catch (error) {
        self.errorMessage = `Profile Picture Saved: ${Date.now()} / ${
          error.message
        }`;
      }
    },
    async getFileAsDataURL() {
      const self = this;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (event) => {
          const data = reader.result;
          resolve(data);
        };
        reader.onerror = (e) => {
          console.error(e);
          reject(e);
        };
        reader.readAsDataURL(self.newProfilePicture);
      });
    },
    refreshData() {
      console.log("Refetching me");
      this.$apollo.queries.user.refetch();
    },
  },
};
</script>

<style lang="css">
#rounded-card {
  border-radius: 50%;
  min-height: 150px;
  min-width: 150px;
}
</style>
