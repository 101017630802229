<template>
    <v-text-field
      v-model="mobileNumber"
      label="Mobile Number"
      prefix="(+27)"
      outlined
      dense
      required
      type="tel"
      name="tel"
      placeholder="083 123 5678"
      :rules="[rules.required, rules.zaMobileNumber]"
    />
</template>

<script>
export default {
  name: "MobileNumberInput",
  props: {
    allowTestNumbers: {
      type: Boolean,
      required: false,
      default: false,
    },
    initialValue: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      rules: {
        zaMobileNumber: (v) => {
          const regex = this.telNumberRegex
          return regex.test(v) || "Not a valid South African mobile number"
        },
        required: value => !!value || 'Mobile number required',
      },
      mobileNumber: '',
    }
  },
  created() {
    this.mobileNumber = this.normalisedInitialNumber
  },
  watch: {
    mobileNumber(value) {
      const number = this.formatOutPutNumber(value)
      if (number) {
        this.$emit('updateMobileNumberInput', number)
      }
    },
  },
  methods: {
    formatOutPutNumber(value) {
      if (this.telNumberRegex.test(value)) {
        return value.replace(/^(\+27|27|0|)/, "27")
      }
      return null
    }
  },
  computed: {
    telNumberRegex() {
      if (this.allowTestNumbers) {
        return /^(0)[6-8][0-9#]{8,10}$/
      } else {
        return /^(0)[6-8][0-9]{8}$/
      }
    },
    normalisedInitialNumber() {
      return this.initialValue.replace(/^(\+27|27|0|)/, "0")
    },
  },
}
</script>

<style lang="scss">
</style>
