import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#7D72F5',
        secondary: '#61E786',
        fairwage_light_grey: '#6C87B4',
        fairwage_dark_grey: '#3F3D56'
      },
    },
  },
});
