<template>
  <v-app v-if="me">
    <v-app-bar app light color="#fff">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title v-if="user">Administration Portal</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        offset-y
        bottom
        transition="scale-transition"
        :close-on-content-click="false"
        min-width="280"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon light outlined v-bind="attrs" v-on="on" class="mr-6">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </template>
        <v-list class="ma-6">
          <!-- :uses="users"  -->
          <base-user-picker
            label="Search"
            placeholder="Search by ID Number,Name or email"
            outlined
            clearable
          />
        </v-list>
      </v-menu>

      <v-menu
        offset-y
        bottom
        transition="scale-transition"
        :close-on-content-click="false"
        min-width="280"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon light outlined v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="logout" class="mb-3">
            <v-list-item-icon>
              <v-icon light>mdi-location-exit</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      dark
      mobile-breakpoint="960"
      width="280"
      color="dark-grey"
    >
      <v-list-item>
        <v-list-item-content>
          <v-img
            @click="$router.push('/')"
            :contain="true"
            max-height="150px"
            v-bind="props"
            position="50% 50%"
            class="py-1"
            src="@/assets/FairwageLogo.png"
          ></v-img>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
      <v-list nav>
        <div v-for="item in items" :key="item.title">
          <v-list-item v-if="!item.children" link color="green" :to="item.href">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            v-else
            :key="item.title"
            link
            active-class="bg-active"
            color="green"
            :to="item.href"
            :value="false"
            prepend-icon="mdi-account-group-outline"
          >
            <template v-slot:activator>
              <v-list-item-title>Accounts</v-list-item-title>
            </template>

            <v-list-item
              v-for="item in item.children"
              :key="item.title"
              link
              :to="item.href"
            >
              <v-list-item-icon>
                <v-icon class="ml-3">{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container fluid>
        <v-col>
          <transition name="fade">
            <router-view />
          </transition>
        </v-col>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { gql } from "apollo-boost";
import { User } from "@/models/User";

export default {
  name: "AdminLayout",

  data() {
    return {
      props: {
        expandOnHover: {
          type: Boolean,
          default: false,
        },
      },
      drawer: null,
      me: null,
      user: null,
      items: [
        {
          title: "Reporting",
          icon: "mdi-file-chart",
          href: "/admin/dashboard",
        },

        {
          title: "Accounts",
          icon: "mdi-account-group-outline",
          href: "#",
          children: [
            {
              title: "Employees",
              icon: "mdi-account-multiple-plus-outline",
              href: "/admin/users",
            },
            {
              title: "Employers",
              icon: "mdi-account",
              href: "/admin/employers",
            },
            {
              title: "Admins",
              icon: "mdi-shield-crown",
              href: "/admin/admins",
            },
          ],
        },
        {
          title: "Contract Templates",
          icon: "mdi-file-sign",
          href: "/admin/contracttemplate",
        },
        {
          title: "Notifications",
          icon: "mdi-bell",
          href: "/admin/notifications",
        },
        {
          title: "Payments",
          icon: "mdi-cash-multiple",
          href: "/admin/payments",
        },
        {
          title: "Support",
          icon: "mdi-lifebuoy",
          href: "admin/support",
        },
      ],
      right: null,
    };
  },
  // computed: {
  //   ...mapState(["barColor", "barImage"]),
  // },
  apollo: {
    me: {
      query: gql`
        query {
          me {
            id
            role
            firstName
            lastName
          }
        }
      `,
      fetchPolicy: "network-only",
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(response, key) {
        if (response.data) {
          this.user = new User();
          this.user.id = this.me.id;
          this.user.role = this.me.role;
          this.user.firstName = this.me.firstName;
          this.user.lastName = this.me.lastName;
        }
      },
      error(error) {
        this.errorMsg = error;
      },
    },
  },
  methods: {
    logout() {
      localStorage.setItem("token", "");
      localStorage.setItem("role", "");
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.help-btn:hover .help-text {
  display: inline-block;
  transition: all 2s linear;
}

.bg-active {
  background-color: black !important;
  color: white !important;
}
.help-text {
  display: none;
}
button.float-right.help-btn.v-btn.v-btn--absolute.v-btn--bottom.v-btn--is-elevated.v-btn--has-bg.v-btn--rounded.theme--dark.v-size--default.primary {
  float: right;
  right: 1%;
}
</style>
