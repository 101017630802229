<template>
  <v-dialog
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      @keydown.esc="cancel"
  >
    <v-card>
      <v-card-title class="text-h3" :class="options.titleColor">
        {{ title }}
      </v-card-title>
      <v-card-text
          v-show="!!message"
          class=""
          v-html="message"
      ></v-card-text>
      <v-card-actions class="pt-3">
        <v-spacer></v-spacer>
        <v-btn
            :text="options.buttonText"
            :color="options.agreeColor"
            class="mb-2"
            @click.native="agree"
        >{{ agreeText }}</v-btn>
        <template v-if="showCancel">
          <v-btn
              text
              :color="options.cancelColor"
              class="mb-2 ml-2"
              @click="cancel"
          >{{ cancelText }}</v-btn>
        </template>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogNotification",
  data: () => ({
    agreeText: "CLOSE",
    cancelText: "CANCEL",
    confirmType: "notification",
    showCancel: false,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: "grey lighten-3",
      titleColor: "",
      width: 400,
      zIndex: 200,
      noconfirm: false,
      buttonText: true,
      agreeColor: "primary",
      cancelColor: "warning"
    }

  }),
  methods: {
    open(title, message, type, confirmType, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.confirmType = confirmType || "notification"
      this.options = Object.assign(this.options, options);
      switch(type) {
        case 'success':
          this.options.titleColor = "success--text"
          break;
        case 'warning':
          this.options.titleColor = "warning--text"
          break;
        case 'error':
          this.options.titleColor = "error--text"
          break;
        case 'info':
          this.options.titleColor = "primary--text"
          break;
        default:
          this.options.titleColor = ""
      }
      switch(this.confirmType) {
        case 'notification':
          this.options.noconfirm = false
          this.showCancel = false
          this.agreeText = "CLOSE"
          break;
        case 'yesno':
          this.options.noconfirm = false
          this.showCancel = true
          this.agreeText = "YES"
          this.cancelText = "NO"
          this.options.buttonText = true
          this.options.agreeColor = "warning"
          this.options.cancelColor = "primary"
          break;
        case 'agreecancel':
          this.options.noconfirm = false
          this.showCancel = true
          this.agreeText = "I AGREE"
          this.cancelText = "CANCEL"
          this.options.buttonText = true
          this.options.agreeColor = "primary"
          this.options.cancelColor = "warning"
          break;
        case 'okaycancel':
          this.options.noconfirm = false
          this.showCancel = true
          this.agreeText = "OKAY"
          this.cancelText = "CANCEL"
          this.options.buttonText = false
          break;
        default:
          this.options.noconfirm = false
          this.agreeText = "CLOSE"
          this.showCancel = false
      }
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel () {
      this.resolve(false);
      this.dialog = false;
    }
  },
};
</script>

<style lang="sass"></style>
