<template>
  <base-stats-card
    :color="'#39fe88'"
    title="No. of Branches"
    :value="branches.count"
    :elevation="8"
    sub-icon="mdi-clock"
    :sub-text="subText"
    :is-loading="isLoading"
  />
</template>

<script>
import Moment from "moment";
import { gql } from "apollo-boost";
export default {
  name: "TotalBranchesStatisticsCard",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    isLoading: false,
    subText: "",
    branches: "",
  }),
  apollo: {
    branches: {
      query: gql`
        query Branches($limit: Int!, $skip: Int!, $query: JSON!) {
          branches(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
            }
            count
          }
        }
      `,
      variables() {
        return {
          limit: 10000,
          skip: 0,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result(data, key) {
        this.subText = "Updated At " + new Moment().format("HH:mm:ss");
      },
    },
  },
  async created() {},
  methods: {},
};
</script>

<style lang="sass"></style>
