var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('v-card',{staticClass:"my-0"},[_c('v-card-text',[_c('v-form',{ref:"UserLeaseAgreementForm",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('base-loading',{attrs:{"is-loading":_vm.isLoading}}),(_vm.successMessage)?_c('v-alert',{attrs:{"type":"success","icon":"mdi-firework","dense":"","prominent":"","dismissable":""}},[_c('h1',[_vm._v(_vm._s(_vm.successMessage))])]):_vm._e(),(_vm.errorMessage)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),_c('v-row',{staticClass:"mx-1"},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('base-user-picker',{attrs:{"chosen-user":_vm.chosenUserId,"initial":_vm.id,"label":"User","outlined":""},on:{"update:chosenUser":function($event){_vm.chosenUserId=$event},"update:chosen-user":function($event){_vm.chosenUserId=$event}}}),(_vm.user.validLease)?_c('v-alert',{attrs:{"type":"success","prominent":"","dismissable":""}},[_c('h1',[_vm._v(" The Lease Agreement has already been completed for this user ")])]):_c('v-alert',{attrs:{"type":"error"}},[_c('h1',[_vm._v("This user has not yet completed the Lease Agreement")])])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-img',{attrs:{"src":'https://volcafespecialty.com/wp-content/uploads/2017/09/PDF-Placeholder.png',"contain":true,"max-width":"60","max-height":"80"}}),_c('div',{domProps:{"innerHTML":_vm._s(
                _vm.user.leaseAgreement
                  ? _vm.$options.filters.getQuestionsBeforeYouSignAgreementLink(
                      _vm.user.leaseAgreement
                    )
                  : 'None'
              )}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-btn',{staticClass:"mx-2",attrs:{"disabled":!_vm.formValid || _vm.user.leaseAgreement,"color":"primary"},on:{"click":function($event){return _vm.signLeaseAgreement()}}},[_vm._v(" Send contract ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }