import { loadView } from "./functions";

export const EmployerChildren = {
  children: [
    {
      path: "landing",
      name: "landing",
      component: loadView("Employer/Landing"),
      ...userMeta(),
    },
    {
      path: "paymentHistory",
      name: "PaymentHistory",
      component: loadView("Employer/PaymentHistory"),
      ...userMeta(),
    },
    {
      path: "contracts",
      name: "Contracts",
      component: loadView("Employer/Contract"),
      ...userMeta(),
    },
    {
      path: "notifications",
      name: "EmployerNotifications",
      component: loadView("Employer/Notifications"),
      ...userMeta(),
    },
    {
      path: "paymentCreate",
      name: "paymentCreate",
      component: loadView("Employer/paymentCreate"),
      ...userMeta(),
    },
    {
      path: "paymentSchedule/:id",
      name: "paymentSchedule",
      component: loadView("Employer/paymentSchedule"),
      ...userMeta(),
    },
    {
      path: "paymentProcess/:id",
      name: "paymentProcess",
      component: loadView("Employer/paymentProcess"),
      ...userMeta(),
    },
    {
      path: "shoppingList",
      name: "shoppingList",
      component: loadView("Employer/Shopping"),
      ...userMeta(),
    },
    {
      path: "toDoList",
      name: "toDoList",
      component: loadView("Employer/ToDoList"),
      ...userMeta(),
    },
    {
      path: "calender",
      name: "Calendar",
      component: loadView("Employer/Calender"),
      ...userMeta(),
    },
    {
      path: "profile",
      name: "EmployerProfile",
      component: loadView("Employer/Profile"),
      ...userMeta(),
    },
    {
      path: "employee-profile/:id/edit",
      name: "EmployeeProfile",
      component: loadView("Employer/EmployeeProfile"),
      ...userMeta(),
    },

    {
      path: "success/:id",
      name: "SuccessfulPayment",
      component: loadView("Employer/Success"),
      ...userMeta(),
    },
    {
      path: "approved/:id",
      name: "SuccessfulTransaction",
      component: loadView("Employer/Success"),
      ...userMeta(),
    },
    {
      path: "declined/:id",
      name: "DeclinedTransaction",
      component: loadView("Employer/Declined"),
      ...userMeta(),
    },
    {
      path: "pending",
      name: "PendingTransaction",
      component: loadView("Employer/Pending"),
      ...userMeta(),
    },
    {
      path: "cancelled",
      name: "CancelledTransaction",
      component: loadView("Employer/Cancelled"),
      ...userMeta(),
    }
  ],
};

function userMeta() {
  return {
    meta: { authorize: ["admin", "user", "employer"] },
  };
}
