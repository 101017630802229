<template>
  <v-container fluid :style="{ background: '#fff', width: '100vw' }" class="px-0">
    <base-loader :isLoading="isLoading"></base-loader>
    <v-row>
      <v-col class="px-0">
        <v-card flat>
          <v-card-title class="text-h4 grey--text" v-if="title">
            {{ title }}
          </v-card-title>
          <v-card-subtitle class="text-h4 blue-grey--text" v-if="finalSubTitle">
            {{ finalSubTitle }}
          </v-card-subtitle>
          <v-card-text >
            <v-form ref="contractForm" >
              <v-row>
                <v-col md="6" class="pb-0">
                  <v-text-field
                      label="Job title"
                      v-model="contractModel.jobTitle"
                      outlined
                      class="text-body-1"
                      dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="contractTemplateJobResponsibilitiesOptions">
                <v-col md="6" class="py-1">
                  <v-select
                      v-model="contractModel.duties"
                      :items="contractTemplateJobResponsibilitiesOptions"
                      item-text="text"
                      item-value="value"
                      label="Job responsibilities"
                      outlined
                      multiple
                      class="text-body-1"
                      dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" class="py-1">
                  <v-select
                      v-model="contractModel.wagePeriod"
                      :items="wagePeriodTypeOptions"
                      item-text="text"
                      item-value="value"
                      label="Monthly or daily wage?"
                      outlined
                      class="text-body-1"
                      dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" class="py-1">
                  <v-text-field
                      v-model.number="contractModel.wageRate"
                      outlined
                      label="Wage"
                      prefix="R"
                      :suffix="wagePeriodSuffix"
                      class="text-body-1"
                      dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="contractModel.wagePeriod === 'per_day'">
                <v-col md="6" class="py-1">
                  <v-select
                      v-model="contractModel.wagePaymentSchedule"
                      :items="wagePaymentScheduleTypeOptions"
                      item-text="text"
                      item-value="value"
                      label="When you are paid"
                      class="text-body-1"
                      outlined
                      dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="contractModel.wagePaymentSchedule !== 'adhoc'">
                <v-col md="6" class="py-1">
                  <v-select
                      label="Payment Date"
                      v-model="contractModel.wagePaymentDate"
                      :items="wagePaymentDateOptions"
                      item-text="text"
                      item-value="value"
                      class="text-body-1"
                      outlined
                      dense
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row v-if="contractModel.wagePeriod === 'per_day' && contractModel.wageTracking === 'automatic'">
                <v-col md="6" class="py-1">
                  <v-select
                      v-model="workScheduleSelectedItems"
                      :items="weekDayScheduleTypeOptions"
                      label="Work days"
                      class="text-body-1"
                      outlined
                      multiple
                      dense
                      single-line
                      return-object
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { gql } from 'apollo-boost'
import { Contract } from '@/models/Contract'

export default {
  name: "ViewContractForm",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    contractId: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
    createdById: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      isLoading: false,
      edit: false,
      contractModel: new Contract(),
      finalSubTitle: this.subtitle,
      workScheduleSelectedItems: [],
    }
  },
  created () {
    const self = this
    if (this.userId){
      this.getContractByUserId(this.userId).then((contract) => {
        const finalContractObj = this.$_.defaults(contract, this.contractModel)
        delete finalContractObj.__ob__
        delete finalContractObj.__typename
        this.contractModel = finalContractObj
        this.updateWorkScheduleSelectedItems(this.contractModel.workSchedule)
        this.edit = true
      }).catch((error) => {
        this.edit = false
        if (this.subtitle === "") {
          this.finalSubTitle = "A contract for this Employee does not exist. Please complete the details below to create a contract."
        }
      })
    }
  },
  mounted () {
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    contractTemplateJobResponsibilities: {
      query: gql`
        query GetContractTemplateJobResponsibilities {
          contractTemplateJobResponsibilities {
            records
          }
        }
      `,
      fetchPolicy: "network-only",
      result(response, key) {
        return response.data.contractTemplateJobResponsibilities.records
      }
    },
    wageTrackingType: {
      query: gql`
        query {
          __type(name: "WageTrackingType") {
            wageTrackingType: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    wagePaymentScheduleType: {
      query: gql`
        query {
          __type(name: "WagePaymentScheduleType") {
            wagePaymentScheduleType: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    wagePeriodType: {
      query: gql`
        query {
          __type(name: "WagePeriodType") {
            wagePeriodType: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    weekDayTypes: {
      query: gql`
        query {
          __type(name: "WeekDayType") {
            weekDayTypes: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    weekDayScheduleType: {
      query: gql`
        query {
          __type(name: "WeekDayScheduleType") {
            weekDayScheduleType: fields {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
  },
  computed: {
    contractTemplateJobResponsibilitiesOptions: function () {
      let returnObject = []
      if (this.contractTemplateJobResponsibilities) {
        if (this.$_.size(this.contractTemplateJobResponsibilities.records) > 0) {
          returnObject = this.contractTemplateJobResponsibilities.records
        }
      }
      return returnObject
    },
    wageTrackingTypeOptions: function () {
      const returnObject = []
      if (this.$_.size(this.wageTrackingType) > 0 ) {
        this.$_.forEach(this.wageTrackingType.wageTrackingType, function (value) {
          switch(value.name) {
            case 'automatic':
              returnObject.push({value: value.name, text: 'I don\'t track the employee\'s worked days.'})
              break;
            case 'employee_must_track':
              returnObject.push({value: value.name, text: 'Employee must track worked days in the app.'})
              break;
            case 'employee_must_track_with_approval':
              returnObject.push({value: value.name, text: 'Employee must track worked days & I approve them.'})
              break;
            case 'employer_must_track':
              returnObject.push({value: value.name, text: 'I will track track worked days in the app.'})
              break;
            default:
              break;
          }
        })
      }
      return returnObject
    },
    wagePaymentScheduleTypeOptions: function () {
      const returnObject = []
      if (this.$_.size(this.wagePaymentScheduleType) > 0 ) {
        this.$_.forEach(this.wagePaymentScheduleType.wagePaymentScheduleType, function (value) {
          switch(value.name) {
            case 'end_of_month':
              returnObject.push({value: value.name, text: 'Paid at the end of the month.'})
              break;
            case 'adhoc':
              returnObject.push({value: value.name, text: 'Paid on an ad-hoc basis.'})
              break;
            default:
              break;
          }
        })
      }
      return returnObject
    },
    wagePeriodTypeOptions: function () {
      const returnObject = []
      if (this.$_.size(this.wagePeriodType) > 0 ) {
        this.$_.forEach(this.wagePeriodType.wagePeriodType, function (value) {
          switch(value.name) {
            case 'per_month':
              returnObject.push({value: value.name, text: 'Paid a set amount each month.'})
              break;
            case 'per_day':
              returnObject.push({value: value.name, text: 'Paid on a per day basis.'})
              break;
            default:
              break;
          }
        })
      }
      return returnObject
    },
    wagePeriodHelper: function () {
      let returnText = ''
      if (this.contractModel.wagePeriod) {
        switch(this.contractModel.wagePeriod) {
          case 'per_month':
            returnText = ' per month'
            break;
          case 'per_day':
            returnText = ' per day'
            break;
          default:
            returnText = ''
        }
      }
      return returnText
    },
    wagePeriodSuffix: function () {
      let returnText = ''
      if (this.contractModel.wagePeriod) {
        switch(this.contractModel.wagePeriod) {
          case 'per_month':
            returnText = '/month'
            break;
          case 'per_day':
            returnText = '/day'
            break;
          default:
            returnText = ''
        }
      }
      return returnText
    },
    weekDayScheduleTypeOptions: function () {
      const returnObject = []
      if (this.$_.size(this.weekDayScheduleType) > 0 ) {
        this.$_.forEach(this.weekDayScheduleType.weekDayScheduleType, (value) => {
          returnObject.push(this.$_.capitalize(value.name))
        })
      }
      return returnObject
    },
    wagePaymentDateOptions: function () {
      const textOptions = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th', '13th', '14th', '15th', '16th', '17th', '18th', '19th', '20th', '21st', '22nd', '23rd', '24th', '25th', '26th', '27th', '28th']
      const returnObject = []
      returnObject.push({ value: 'LAST_DAY_OF_MONTH', text: 'Last day of the month' })
      this.$_.forEach(textOptions, (value, key) => {
        returnObject.push({ value: this.$_.toString(key + 1), text: value })
      })
      return returnObject
    },
  },
  methods: {
    updateWorkScheduleSelectedItems (workSchedule) {
      const contractWorkSchedule = this.$_.cloneDeep(workSchedule)
      delete contractWorkSchedule.__ob__
      delete contractWorkSchedule.__typename
      if(contractWorkSchedule){
        this.workScheduleSelectedItems = []
        this.$_.forEach(contractWorkSchedule, (value, key) => {
          if (value) {
            this.workScheduleSelectedItems.push(this.$_.capitalize(key))
          }
        })
      }
    },
    async getContractByUserId (userId) {
      return new Promise(async (resolve, reject) => {
        this.isLoading = true
        this.$apollo.query({
          query: gql`
            query GetContract($userId: ID!) {
              contractByUserId(userId: $userId) {
                id
                userId
                jobTitle
                duties
                contractCurrency
                terminationNoticePeriod
                wagePaymentSchedule
                wagePaymentDate
                wagePaymentWeekDay
                wagePeriod
                wagePeriodUnitType
                wageTracking
                wageRate
                overtimeAvailable
                overtimeRate
                publicHolidayRate
                hoursOfWorkStart
                hoursOfWorkEnd
                lunchPeriod
                createdAt
                updatedAt
                workSchedule {
                  monday
                  tuesday
                  wednesday
                  thursday
                  friday
                  saturday
                  sunday
                }
              }
            }
          `,
          fetchPolicy: "no-cache",
          variables: {
            userId: userId
          }
        }).then((data) => {
          this.isLoading = false
          if (_.has(data, 'data.contractByUserId.id')) {
            return resolve(data.data.contractByUserId)
          } else {
            return reject('No data found')
          }
        }).catch((error) => {
          console.log('Error:', error)
          this.isLoading = false
          return reject(error)
        })
      })
    },
    cancel () {
      this.$emit('contractViewFormCancel')
    },
    finished (status, message) {
      const result = {
        status: status,
        message: message
      }
      this.$emit('contractViewFormFinished', result)
    },
    returnFormattedWorkSchedule (workSchedule) {
      const cleanWorkSchedule = this.$_.cloneDeep(workSchedule)
      delete cleanWorkSchedule.__ob__
      delete cleanWorkSchedule.__typename
      const returnObject = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      }
      this.$_.forEach(workSchedule, (value, index) => {
        returnObject[this.$_.toLower(value)] = true
      })
      return returnObject
    },
  },
};
</script>

<style lang="scss" scoped></style>
