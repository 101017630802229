<template>
  <!-- <v-card> -->
  <!-- <v-card-text> -->
  <v-form
    ref="UserCreateForm"
    v-model="formValid"
    :style="{ background: '#fff', width: '100vw' }"
  >
    <base-loading :is-loading="isLoading" />
    <v-alert v-if="successMessage" type="success">
      {{ successMessage }}
    </v-alert>
    <v-alert v-if="errorMessage" type="error">
      {{ errorMessage }}
    </v-alert>

    <div v-if="formType == 'default'">
      <v-row class="mx-1">
        <base-role-picker
          :label="'Role'"
          :initial="user.role"
          :chosen-role.sync="user.role"
          outlined
        />
      </v-row>
      <v-row class="mx-1">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="user.firstName"
            color="primary"
            outlined
            required
            :rules="[(v) => !!v || 'First name is required']"
          >
            <template #label>
              <span class="red--text">First Name<strong>* </strong></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="user.lastName"
            color="primary"
            outlined
            required
            :rules="[(v) => !!v || 'Last name is required']"
          >
            <template #label>
              <span class="red--text">Last Name<strong>* </strong></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="user.email"
            color="primary"
            outlined
            required
            :rules="[
              (v) => !!v || 'Email is required',
              (v) =>
                /.+@.+\..+/.test(v) || 'Email Address format must be valid',
            ]"
          >
            <template #label>
              <span class="red--text">Email<strong>* </strong></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="user.password"
            color="primary"
            outlined
            label="Password"
            clearable
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="user.role === 'user'">
        <h3 class="ml-8">Client personal Information</h3>
      </v-row>
      <v-row class="mx-1">
        <v-col cols="12" md="12">
          <v-text-field
            v-if="user.role === 'user'"
            v-model="user.idNumber"
            color="primary"
            label="ID Number"
            outlined
            clearable
          />
        </v-col>

        <v-col cols="12" md="12">
          <v-text-field
            v-if="user.role === 'user'"
            v-model="user.passport"
            color="primary"
            label="Passport Number"
            outlined
            clearable
          />
        </v-col>
      </v-row>
      <v-row class="mx-1">
        <v-col>
          <base-title-picker
            v-if="user.role === 'user'"
            color="primary"
            :label="'Title'"
            :chosen-title.sync="user.title"
            :initial="user.title"
            v-model="user.title"
            outlined
          />
        </v-col>
        <v-col>
          <base-race-picker
            v-if="user.role === 'user'"
            :label="'Race'"
            :initial="user.race"
            :chosen-race.sync="user.race"
            outlined
          />
        </v-col>
      </v-row>
      <v-row class="mx-1">
        <base-gender-picker
          v-if="user.role === 'user'"
          :label="'Gender'"
          :initial="user.gender"
          :chosen-gender.sync="user.gender"
          outlined
        />
      </v-row>
      <v-row class="mx-1">
        <v-col cols="12" md="12">
          <v-textarea
            v-if="user.role === 'user'"
            v-model="user.fullAddress"
            color="primary"
            label="Address"
            counter
            clearable
            auto-grow
            rows="4"
            outlined
          />
        </v-col>
      </v-row>
      <v-row class="mx-1">
        <v-col cols="12" md="6">
          <v-text-field
            v-if="user.role === 'user'"
            v-model="user.telNumber"
            color="primary"
            label="Telephone Number"
            outlined
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-if="user.role === 'user'"
            v-model="user.altNumber"
            color="primary"
            label="Alternative Telephone Number"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <h3 class="ml-8" v-if="user.role === 'user'">Insurance Information</h3>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-switch
            v-if="user.role === 'user'"
            class="mb-5 ml-2"
            v-model="interestQuestion"
            label="Have you used Medsafu before?"
            outlined
          />
        </v-col>
        <v-col cols="6" v-if="!interestQuestion">
          <base-product-category-picker
            v-if="user.role === 'user'"
            :chosen-product-category.sync="user.productsInterestedIn"
            label="Which product range are you interested in?"
            multiple
            outlined
          />
        </v-col>
        <v-col cols="6" v-if="interestQuestion">
          <base-product-picker
            v-if="user.role === 'user'"
            :chosen-product.sync="user.existingProducts"
            label="Which products do you currently own?"
            multiple
            outlined
          />
        </v-col>
      </v-row>
      <v-row> </v-row>
      <v-row class="mx-1">
        <v-col cols="12" md="12" v-if="user.role === 'user'">
          <user-edit-picker
            :label="'Assigned Consultant'"
            :initial="user.consultant"
            :chosen-user.sync="user.consultant"
            :role="'consultant'"
            outlined
          />
        </v-col>
      </v-row>
      <v-row>
        <h3 class="ml-8" v-if="user.role === 'user'">Lead Information</h3>
      </v-row>
      <v-row>
        <v-col cols="12" md="12" v-if="user.role === 'user'">
          <base-referral-picker
            :label="'Where the lead came from'"
            :initial="user.referral"
            :chosen-referral.sync="user.referral"
            outlined
          />
        </v-col>
      </v-row>
    </div>
    <!-- class="New-employee-create" -->
    <div v-else-if="formType == 'employeeCreate'">
      <v-stepper v-model="step" vertical>
        <v-stepper-step :complete="step > 1" step="1">
          Employee personal details
          <small class="mt-4">So the employee can be contacted</small>
        </v-stepper-step>

        <v-stepper-content step="1">
          <!-- <v-card color="#fff" class="mb-12" height="auto"> -->
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="user.firstName"
                label="First Name"
                required
                :rules="[(v) => !!v || 'First name is required']"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.lastName"
                label="Last Name"
                required
                :rules="[(v) => !!v || 'Last name is required']"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.email"
                label="Email"
                type="email"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.telNumber"
                label="Mobile"
                required
                outlined
              />
            </v-col>

            <v-col cols="12">
              <base-identification-type-picker
                :initial="'South African ID'"
                label="Identification Type"
                outlined
                :chosen-identification-types.sync="idType"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-if="idType == 'South African ID'"
                v-model="user.idNumber"
                label="Id Number"
                clearable
                outlined
              />

              <v-text-field
                v-else
                v-model="user.passport"
                label="Passport Number"
                clearable
                outlined
              />
            </v-col>
          </v-row>

          <!-- </v-card> -->
          <v-btn color="primary" @click="step = 2"> Continue </v-btn>
          <!-- <v-btn text> Cancel </v-btn> -->
        </v-stepper-content>
        <!-- :complete="step > 2" -->
        <v-stepper-step step="2">
          Employee bank details
          <small class="mt-4">So the employee can paid</small>
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="user.accountHolder"
                label="Account Holder Name"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <base-account-type-picker
                :chosen-account-type.sync="user.accountType"
                :initial="user.accountType"
                label="Account Type"
                required
                outlined
                hint="Capitec,Tyme and Discovery bank are Savings"
                :rules="[(v) => !!v || 'Account type is required']"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.accountNumber"
                label="Account Number"
                required
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <base-bank-picker
                :chosen-bank.sync="user.bankName"
                :initial="user.bankName"
                label="Bank"
                outlined
              />
            </v-col>
            <v-col cols="12">
              <base-bank-branch-picker
                :chosen-bank-branch.sync="user.branchCode"
                :initial="user.branchCode"
                label="Branch Code"
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <base-payment-identification-type-picker
                :initial="'Employer full control'"
                label="Payment Setup"
                outlined
                v-model="user.paymentSetupType"
              />
            </v-col>
          </v-row>
          <v-row class="ma-2">
            <v-btn
              color="primary"
              small
              @click="saveUserDetails()"
              class="mr-2"
            >
              Continue
            </v-btn>
            <v-btn color="secondary" small @click="step = 1">
              Previous step</v-btn
            >
          </v-row>
        </v-stepper-content>

        <v-stepper-step :complete="step > 3" step="3">
          Employee Payments
        </v-stepper-step>

        <v-stepper-content step="3">
          <v-row v-if="paymentSetupType == 'Employer full control'">
            <calender :userIdPassed="userId" />
          </v-row>
          <v-row v-else
            ><p class="mt-8">
              Payment will be goverened by employee checking in
            </p></v-row
          >
          <v-row>
            <!-- <v-btn :disabled="!formValid" color="primary" class="mx-2" @click="[save(), refreshPage()]">
              Save
            </v-btn> -->
          </v-row>
        </v-stepper-content>
      </v-stepper>
    </div>
  </v-form>
  <!-- </v-card-text> -->
  <!-- </v-card> -->
</template>

<script>
import { gql } from "apollo-boost";
import UserEditPicker from "./UserEditPicker.vue";
import Calender from "../../views/Employer/Calender.vue";

// import moment from 'moment'
export default {
  props: {
    employerID: {
      type: String,
      default: "",
    },

    close: {
      type: Boolean,
      default: false,
    },
    createdBy: {
      type: String,
      default: "",
    },
    formType: {
      type: String,
      default: "default",
    },
    role: {
      type: String,
      default: "employee",
    },
  },

  name: "UserCreate",
  documents: [],

  components: {
    UserEditPicker,
    Calender,
  },
  data: () => ({
    isLoading: false,
    formValid: true,
    interestQuestion: false,
    step: 1,
    userId: "",
    idType: "South African ID",
    paymentSetupType: "Employer full control",
    user: {
      password: "Test1234!!",
      firstName: "",
      lastName: "",
      telNumber: "",
      email: "",
      passport: "",
      idNumber: "",
      role: "employee",
      createdBy: "",
      accountType: null,
      accountNumber: "",
      fullAddress: "",
      accountHolder: "",
      paymentSetupType: "",
      bankName: null,
      branchCode: null,
      employers: [],
      employeeID: "",
    },
    successMessage: "",
    errorMessage: "",
  }),

  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      await this.saveUser()
        .then(() => {
          // this.$swal("Success!", "User was created", "success");
          // window.location.href = `/admin/users`;
        })
        .catch((e) => {
          console.log(e);
          /*this.$swal(
            "Error!",
            `Something went wrong when creating the User, check data and try again later!`,
            "error"
          );*/
        });
      this.isLoading = false;
    },
    async refreshClientData() {
      this.$apollo.queries.users.refetch();
    },
    async refreshPage() {
      this.$router.go(this.$route.currentRoute);
    },
    async saveUserDetails() {
      const self = this;
      const user = this.user;
      const empty = `cannot be empty`;
      this.user.role = "employee";
      (this.user.createdBy = this.createdBy),
        (this.user.employers = [this.employerID]);

      var ex =
        /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/;
      if (this.step == 1) {
        // if (this.idType == "South African ID") {
        //   user.passport = "";
        // }
        // if (this.idType == "Foreign passport") {
        //   user.idNumber = "";
        // }
        self.errorMessage = "";
        if (this.convertName(user.firstName) == "") {
          this.$swal({
            title: "Error!",
            text: "The employee's first name is empty",
            type: "error",
            showConfirmButton: false,
            showCancelButton: false,
            icon: "error",
            timer: 1500,
          }).then(
            function () {},
            // handling the promise rejection
            function (dismiss) {
              if (dismiss === "timer") {
                //console.log('I was closed by the timer')
              }
            }
          );
        } else if (this.convertName(user.lastName) == "") {
          this.$swal({
            title: "Error!",
            text: "The employee's last name is empty",
            type: "error",
            showConfirmButton: false,
            showCancelButton: false,
            icon: "error",
            timer: 1500,
          }).then(
            function () {},
            // handling the promise rejection
            function (dismiss) {
              if (dismiss === "timer") {
                //console.log('I was closed by the timer')
              }
            }
          );
        } else if (this.convertName(user.email) == "") {
          this.$swal({
            title: "Error!",
            text: "The employee's email is empty, this is needed to send them their contract and payslip",
            type: "error",
            showConfirmButton: false,
            showCancelButton: false,
            icon: "error",
            timer: 1500,
          }).then(
            function () {},
            // handling the promise rejection
            function (dismiss) {
              if (dismiss === "timer") {
                //console.log('I was closed by the timer')
              }
            }
          );
        } else if (this.convertName(user.telNumber) == "") {
          this.$swal({
            title: "Error!",
            text: "The employee's cellphone is empty, this is needed for security and communications.",
            type: "error",
            showConfirmButton: false,
            showCancelButton: false,
            icon: "error",
            timer: 1500,
          }).then(
            function () {},
            // handling the promise rejection
            function (dismiss) {
              if (dismiss === "timer") {
                //console.log('I was closed by the timer')
              }
            }
          );
        } else if (
          this.idType == "South African ID" &&
          this.convertName(user.idNumber) == ""
        ) {
          this.$swal({
            title: "Error!",
            text: "The employee's ID Number is empty, this is needed to make payment and verify the employee",
            type: "error",
            showConfirmButton: false,
            showCancelButton: false,
            icon: "error",
            timer: 1500,
          }).then(
            function () {},
            // handling the promise rejection
            function (dismiss) {
              if (dismiss === "timer") {
                //console.log('I was closed by the timer')
              }
            }
          );
        } else if (
          this.idType == "South African ID" &&
          this.convertName(user.idNumber) != "" &&
          !ex.test(user.idNumber)
        ) {
          this.$swal({
            title: "Error!",
            text: "The employee's ID Number is invalid please check before clicking continue",
            type: "error",
            showConfirmButton: false,
            showCancelButton: false,
            icon: "error",
            timer: 1500,
          }).then(
            function () {},
            // handling the promise rejection
            function (dismiss) {
              if (dismiss === "timer") {
                //console.log('I was closed by the timer')
              }
            }
          );
        } else if (
          this.idType != "South African ID" &&
          this.convertName(user.passport) == ""
        ) {
          this.$swal({
            title: "Error!",
            text: "The employee's Passport number is empty",
            type: "error",
            showConfirmButton: false,
            showCancelButton: false,
            icon: "error",
            timer: 1500,
          }).then(
            function () {},
            // handling the promise rejection
            function (dismiss) {
              if (dismiss === "timer") {
                //console.log('I was closed by the timer')
              }
            }
          );
        } else {
          if (self.errorMessage == "") {
            self.step = 2;
          }
        }
      } else if (this.step == 2) {
        await this.saveUser();
      }
    },
    convertName(name) {
      if (name != undefined) {
        name = name.trim().toLowerCase();
        if (name != "") {
          return (name = name.charAt(0).toUpperCase() + name.slice(1));
        } else {
          return (name = "");
        }
      } else {
        return (name = "");
      }
    },
    async saveUser() {
      const self = this;
      self.successMessage = "";
      self.errorMessage = "";
      try {
        const createdUser = await this.$apollo.mutate({
          mutation: gql`
            mutation createUser($user: UserCreateInput!) {
              createUser(user: $user) {
                id
              }
            }
          `,
          variables: {
            user: {
              firstName: self.user.firstName,
              lastName: self.user.lastName,
              email: self.user.email,
              password: "Test1234!!",
              passport: self.user.passport,
              fullAddress: self.user.fullAddress,
              idNumber: self.user.idNumber,
              telNumber: self.user.telNumber,
              role: "employee",
              accountType: self.user.accountType,
              accountNumber: self.user.accountNumber,
              accountHolder: self.user.accountHolder,
              bankName: self.user.bankName,
              branchCode: self.user.branchCode,
              employers: self.user.employers,
              paymentSetupType: self.user.paymentSetupType,
            },
          },
          watchLoading(isLoading, countModifier) {
            self.isLoading = isLoading;
          },
        });

        if (!createdUser) {
          return "Something went wrong when creating the user!";
        }
        this.$swal({
          title: "Success!",
          text: "The employee was created, you will proceed to configuring how they get paid",
          type: "success",
          showConfirmButton: true,
          showCancelButton: false,
          icon: "success",
          timer: 1500,
        }).then(
          function (dismiss) {
            if (dismiss === "timer") {
              //console.log('I was closed by the timer')
            }
          },
          function () {
            this.step = 3;
          }
        );

        console.log(
          "createdUser?.data?.createUser?.id",
          createdUser?.data?.createUser?.id
        );
        if (createdUser && createdUser.data.createUser) {
          self.userId = createdUser?.data?.createUser?.id;
        }
        // self.successMessage = `User Created Successfully`;
        this.$swal({
          title: "Success!",
          text: "The employee's payment's were configured for the duration of their contract",
          type: "success",
          showConfirmButton: false,
          showCancelButton: false,
          icon: "success",
          timer: 1500,
        }).then(
          function () {},
          // handling the promise rejection
          function (dismiss) {
            if (dismiss === "timer") {
              //console.log('I was closed by the timer')
              refreshClientData();
            }
          }
        );
        self.$eventHub.$emit("user-updated");
        this.step = 3;
      } catch (error) {
        this.$swal({
          title: "Error!",
          text: "Something went wrong when creating the User, check data and try again later!",
          type: "error",
        }).then(function () {
          //window.location.href = `/admin/users`;
        });
        self.errorMessage = `User Not Created / ${error.message}`;
      }
    },
  },
};
</script>

<style lang="scss"></style>
