<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px">
      <template v-slot:activator="{ on, attrs }">
        <v-spacer></v-spacer>
        <div class="mx-auto text-right">
          <v-btn
            class="mx-auto"
            color="primary"
            large
            rounded
            v-bind="attrs"
            @click="showNewUserFicaProductDialog"
            ><h1>Add Product to Client</h1></v-btn
          >
        </div>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Product to Client</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <base-product-picker
                  :chosen-product.sync="userFicaProductModel.product"
                  :initial="userFicaProductModel.product"
                  label="Product"
                  required
                  outlined
                  :rules="[(v) => !!v || 'Product is required']"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn color="secondary" @click="createUserFicaProduct">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="docDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Upload Documents</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-container
                  v-cloak
                  class="fileDropper"
                  @drop.prevent="addDocument"
                  @dragover.prevent
                >
                  <v-row>
                    <v-col>
                      <h2 class="text--disabled">
                        DRAG FILES HERE
                      </h2>
                    </v-col>
                  </v-row>
                </v-container>
              </v-col>
              <v-col v-if="newDocuments && newDocuments.length > 0">
                <h2>Documents Staged For Upload</h2>
                <v-list>
                  <v-list-item
                    v-for="(file, index) in newDocuments"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        >{{ file.name }} ({{
                          file.size | kb
                        }}
                        kb)</v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-avatar>
                      <v-icon color="red" @click="removeDocument(file)">
                        mdi-delete
                      </v-icon>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="prepareAndSaveDocuments">
            Save
          </v-btn>
          <v-btn color="secondary" @click="docDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="existingDocumentsDialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Existing Documents</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-if="
                  userFicaProductModel.documents &&
                    userFicaProductModel.documents.length > 0
                "
              >
                <v-list>
                  <v-list-item
                    v-for="(document, index) in userFicaProductModel.documents"
                    :key="index"
                  >
                    <v-list-item-content>
                      <v-list-item-title
                        ><a :href="document.documentLink">{{
                          document.name
                        }}</a></v-list-item-title
                      >
                    </v-list-item-content>
                    <v-list-item-avatar>
                      <v-btn
                        fab
                        large
                        dark
                        class="mr-2"
                        @click="deleteDocument(document._id)"
                        color="error"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-list-item-avatar>
                  </v-list-item>
                </v-list>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" @click="existingDocumentsDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-card class="mt-3">
      <v-data-table
        :headers="headers"
        :items="userFicaProducts.records"
        :loading="isLoading"
        :search="search"
        transition="scale-transition"
      >
        <template v-slot:top> </template>
        <template v-slot:item.documents="{ item }">
          <v-btn
            color="'#0a2752'"
            @click="showEditUserFicaProductDialog(item)"
            dark
            large
            class="ma-2 white--text"
          >
            Upload documents
            <v-icon right>mdi-upload</v-icon>
          </v-btn>
          <v-btn
            color="warning"
            large
            @click="showExistingDocumentsDialog(item)"
          >
            <v-icon left>mdi-file</v-icon>
            View the ({{ item.documents.length }}) documents
          </v-btn>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn color="error" fab large @click="deleteItem(item)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <h3 color="primary">{{ item.createdAt | getFormattedDate }}</h3>
        </template>
        <template v-slot:item.updatedAt="{ item }">
          <h3 color="primary">{{ item.updatedAt | getFormattedDate }}</h3>
        </template>
        <template v-slot:item.membershipNumber="{ item }">
          <h3 color="primary">
            {{ item.membershipNumber ? item.membershipNumber : "" }}
          </h3>
        </template>
        <!-- <template v-slot:item.management="{ item }" class="text-center">
          <base-user-products-info-modal
            :user="userId"
            :userFicaProduct="userFicaProductModel"
          />
        </template> -->
      </v-data-table>
      <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
        {{ snackText }}

        <template v-slot:action="{ attrs }">
          <v-btn v-bind="attrs" text @click="snack = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-card>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import { Product, UserFicaProduct } from "@/models/UserFicaProduct";
// import { mapState } from 'vuex'
export default {
  name: "FicaProducts",
  props: {
    userId: {
      type: String,
      default: function() {
        return "";
      },
    },
    buttonText: {
      type: String,
      default: function() {
        return "";
      },
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Product",
          align: "start",
          sortable: false,
          value: "ficaProductName",
        },
        {
          text: "Documents",
          align: "start",
          sortable: false,
          value: "documents",
        },
        // { text: "View Data", value: "management" },
        { text: "Created Date", value: "createdAt" },
        { text: "Updated Date", value: "updatedAt" },
        { text: "", align: "right", sortable: false, value: "actions" },
      ],
      userFicaProductModel: new UserFicaProduct(),
      dialog: false,
      docDialog: false,
      existingDocumentsDialog: false,
      isEdit: false,
      search: "",
      userFicaProducts: [],
      newDocuments: [],
      totalRecords: 0,
      isLoading: false,
      snack: false,
      snackColor: "",
      snackText: "",
    };
  },
  computed: {
    uploadDisabled() {
      return this.newDocuments.length === 0;
    },
  },
  created() {
    this.userFicaProductModel.user = this.userId;
  },
  watch: {
    userId(newValue, oldValue) {
      console.log(newValue);
      this.userFicaProductModel.user = this.userId;
    },
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,

    userFicaProducts: {
      query: gql`
        query UserFicaProducts($limit: Int!, $skip: Int!, $query: JSON!) {
          userFicaProducts(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              user
              ficaProduct
              ficaProductName
              documents
              createdAt
              updatedAt
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        const limit = 9999;
        const skip = 0;
        return {
          limit: limit,
          skip: skip,
          query: { user: this.userId },
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
    },
  },
  methods: {
    showSnack(snackColor, snackText) {
      this.snack = true;
      this.snackColor = snackColor;
      this.snackText = snackText;
    },
    async createUserFicaProduct() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createUserFicaProduct(
              $userFicaProduct: UserFicaProductCreateInput!
            ) {
              createUserFicaProduct(userFicaProduct: $userFicaProduct) {
                id
              }
            }
          `,
          variables: {
            userFicaProduct: {
              user: self.userFicaProductModel.user,
              ficaProduct: self.userFicaProductModel.ficaProduct,
              createdBy: self.me.id,
            },
          },
        });

        if (!response) {
          throw "Something went wrong when creating the Fica Product!";
        }
        if (response.errors && response.errors.length > 0) {
          throw response.errors[0];
        }
        if (response && response.data.createUserFicaProduct) {
          self.createUserFicaProduct.id =
            response?.data?.createUserFicaProduct?.id;
          this.$swal(
            "Success!",
            "User Fica Product was successfully created",
            "success"
          );
          this.$router.go(this.$route.currentRoute);
        }
      } catch (e) {
        this.$swal(
          "Error!",
          `Something went wrong when adding the Fica Product to the User, check data and try again later!`,
          "error"
        );
        console.log(e.message);
      }
    },
    async prepareAndSaveDocuments() {
      const self = this;
      console.log(
        "Preparing new documents for: ",
        this.userFicaProductModel.id
      );
      self.isLoading = true;
      if (this.newDocuments) {
        await Promise.all(
          this.newDocuments.map(async (document) => {
            console.log(document);
            const documentAsDataURL = await self.getFileAsDataURL(document);
            console.log(document.name, documentAsDataURL);
            await self.createDocument(document.name, documentAsDataURL);
          })
        );
        self.isLoading = false;
        this.$swal("Success!", "Documents Uploaded", "success");
        this.$router.go(this.$route.currentRoute);
      } else {
        self.isLoading = false;
        console.log("No documents need to be uploaded.");
      }
    },
    async createDocument(documentName, documentAsDataURL) {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createDocument($document: DocumentCreateInput!) {
              createDocument(document: $document) {
                id
              }
            }
          `,
          variables: {
            document: {
              name: documentName,
              documentLink: documentAsDataURL,
              user: self.userFicaProductModel.user,
              userFicaProduct: self.userFicaProductModel.id,
              createdBy: self.me.id,
            },
          },
        });
        if (!response) {
          this.showSnack("error", "Document not Created");
          throw "Something went wrong when creating the document!";
        }
        if (response.errors && response.errors.length > 0) {
          this.showSnack("error", "Document not Created");
          throw response.errors[0];
        }
        if (response && response.data.createDocument) {
          console.log(`Uploaded ${documentName} Successfully`);
        }
      } catch (e) {
        throw e.message;
      }
    },
    deleteDocument(id) {
      console.log("Deleting Document ", id);
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#b3a369",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation($id: ID!) {
                  deleteDocument(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: id,
              },
            });

            if (response) {
              this.$swal(
                "Success!",
                "Document was successfully deleted",
                "success"
              );
              this.$router.go(this.$route.currentRoute);
            }
          } catch (e) {
            console.log(`Deletion Failed ${JSON.stringify(e)}`);
          }
        }
      });
    },
    deleteItem(item) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#b3a369",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          try {
            const response = await this.$apollo.mutate({
              mutation: gql`
                mutation($id: ID!) {
                  deleteUserFicaProduct(id: $id) {
                    id
                    deleted
                  }
                }
              `,
              variables: {
                id: item.id,
              },
            });

            if (response) {
              this.$swal(
                "Success!",
                "User Product was successfully deleted",
                "success"
              );
              this.$router.go(this.$route.currentRoute);
            }
          } catch (e) {
            console.log(`Deletion Failed ${JSON.stringify(e)}`);
          }
        }
      });
    },
    async showNewUserFicaProductDialog() {
      this.isEdit = false;
      this.newDocuments = [];
      this.userFicaProductModel = new UserFicaProduct();
      this.userFicaProductModel.user = this.userId;
      this.dialog = true;
    },
    async showEditUserFicaProductDialog(item) {
      this.isEdit = true;
      this.newDocuments = [];
      this.userFicaProductModel = new UserFicaProduct();
      this.userFicaProductModel.id = item.id;
      this.userFicaProductModel.user = this.userId;
      this.userFicaProductModel.ficaProduct = item.ficaProduct;
      this.userFicaProductModel.documents = item.documents;
      this.docDialog = true;
    },
    async showExistingDocumentsDialog(item) {
      this.isEdit = true;
      this.newDocuments = [];
      this.userFicaProductModel = new UserFicaProduct();
      this.userFicaProductModel.id = item.id;
      this.userFicaProductModel.user = this.userId;
      this.userFicaProductModel.ficaProduct = item.ficaProduct;
      this.userFicaProductModel.documents = item.documents;
      this.existingDocumentsDialog = true;
    },
    addDocument(e) {
      console.log("Adding file");
      const droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        this.newDocuments.push(f);
      });
    },
    removeDocument(file) {
      this.newDocuments = this.newDocuments.filter((f) => {
        return f != file;
      });
    },
    async getFileAsDataURL(document) {
      const self = this;
      return new Promise((resolve, reject) => {
        console.log(document);
        if (typeof document === "string") {
          console.log("Picture was a String");
          resolve(document);
        }

        if (typeof document === "object") {
          console.log(document.name);
          const reader = new FileReader();
          reader.onload = async (event) => {
            const data = reader.result;
            resolve(data);
          };
          reader.onerror = (e) => {
            console.error(e);
            reject(e);
          };
          reader.readAsDataURL(document);
        }
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.fileDropper
  height:150px
  text-align: center
  border: 0.5px solid !important
  border-color: rgba(153, 202, 255, 0.82)!important
  background-color: #ebf2ff!important
</style>
