<template>
    <v-card class="my-0">
      <v-card-text>
        <v-form ref="PaymentCreateForm" v-model="formValid">
          <base-loading :is-loading="isLoading" />
          <v-alert v-if="successMessage" type="success">
            {{ successMessage }}
          </v-alert>
          <v-alert v-if="errorMessage" type="error">
            {{ errorMessage }}
          </v-alert>
  
          <v-row>
            <h3 class="ml-8">Medsafu portal Information</h3>
          </v-row>
          <v-row class="mx-1">
            <base-role-picker
              :label="'Role'"
              :initial="user.role"
              :chosen-role.sync="user.role"
              outlined
            />
          </v-row>
          <v-row class="mx-1">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.firstName"
                color="primary"
                outlined
                required
                :rules="[(v) => !!v || 'First name is required']"
              >
                <template #label>
                  <span class="red--text">First Name<strong>* </strong></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.lastName"
                color="primary"
                outlined
                required
                :rules="[(v) => !!v || 'Last name is required']"
              >
                <template #label>
                  <span class="red--text">Last Name<strong>* </strong></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.email"
                color="primary"
                outlined
                required
                :rules="[
                  (v) => !!v || 'Email is required',
                  (v) =>
                    /.+@.+\..+/.test(v) || 'Email Address format must be valid',
                ]"
              >
                <template #label>
                  <span class="red--text">Email<strong>* </strong></span>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="user.password"
                color="primary"
                outlined
                label="Password"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="user.role === 'user' || user.role === 'lead'">
            <h3 class="ml-8">Client personal Information</h3>
          </v-row>
          <v-row class="mx-1">
            <v-col cols="12" md="12">
              <v-text-field
                v-if="user.role === 'user' || user.role === 'lead'"
                v-model="user.idNumber"
                color="primary"
                label="ID Number"
                outlined
                clearable
              />
            </v-col>
  
            <v-col cols="12" md="12">
              <v-text-field
                v-if="user.role === 'user' || user.role === 'lead'"
                v-model="user.passport"
                color="primary"
                label="Passport Number"
                outlined
                clearable
              />
            </v-col>
          </v-row>
          <v-row class="mx-1">
            <v-col>
              <base-title-picker
                v-if="user.role === 'user' || user.role === 'lead'"
                color="primary"
                :label="'Title'"
                :chosen-title.sync="user.title"
                :initial="user.title"
                v-model="user.title"
                outlined
              />
            </v-col>
            <v-col>
              <base-race-picker
                v-if="user.role === 'user' || user.role === 'lead'"
                :label="'Race'"
                :initial="user.race"
                :chosen-race.sync="user.race"
                outlined
              />
            </v-col>
          </v-row>
          <v-row class="mx-1">
            <base-gender-picker
              v-if="user.role === 'user' || user.role === 'lead'"
              :label="'Gender'"
              :initial="user.gender"
              :chosen-gender.sync="user.gender"
              outlined
            />
          </v-row>
          <v-row class="mx-1">
            <v-col cols="12" md="12">
              <v-textarea
                v-if="user.role === 'user' || user.role === 'lead'"
                v-model="user.fullAddress"
                color="primary"
                label="Address"
                counter
                clearable
                auto-grow
                rows="4"
                outlined
              />
            </v-col>
          </v-row>
          <v-row class="mx-1">
            <v-col cols="12" md="6">
              <v-text-field
                v-if="user.role === 'user' || user.role === 'lead'"
                v-model="user.telNumber"
                color="primary"
                label="Telephone Number"
                outlined
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="payment.amount"
                color="primary"
                label="Amount"
                outlined
              />
            </v-col>
          </v-row>
          </v-row>
            <v-col
              cols="12"
              md="12"
            >
              <user-edit-picker
                :label="'Employee'"
                :initial="payment.employee"
                :chosen-user.sync="payment.employee"
                :role="'user'"
                outlined
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="!formValid"
          color="primary"
          class="mx-2"
          @click="[save()]"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </template>
  
  <script>
  import { gql } from "apollo-boost";
  import UserEditPicker from "./UserEditPicker.vue";
  // import moment from 'moment'
  export default {
    name: "PaymentCreate",
    documents: [],
    components: {
      UserEditPicker,
    },
    data: () => ({
      isLoading: false,
      formValid: true,
      interestQuestion: false,
      payment: {
        employee: "",
        paidfor: "",
        paidby: "",
        status: "",
        amount: 0,
      },
      successMessage: "",
      errorMessage: "",
    }),
    computed: {},
    async mounted() {},
    methods: {
      async save() {
        this.isLoading = true;
        const self = this;
        await this.savePayment()
          .then(() => {
            // this.$swal("Success!", "Payment was created", "success");
            // window.location.href = `/admin/payments`;
          })
          .catch((e) => {
            console.log(e);
            /*this.$swal(
              "Error!",
              `Something went wrong when creating the Payment, check data and try again later!`,
              "error"
            );*/
          });
        this.isLoading = false;
      },
      async refreshClientData() {
        this.$apollo.queries.payments.refetch();
      },
      async refreshPage() {
        this.$router.go(this.$route.currentRoute);
      },
      async savePayment() {
        const self = this;
        self.successMessage = "";
        self.errorMessage = "";
        if (self.$refs.PaymentCreateForm.validate()) {
          try {
            const {
                employee,
                paidfor,
                paidby,
                status,
                amount,
            } = self.payment;
            const createdPayment = await this.$apollo.mutate({
              mutation: gql`
                mutation ($payment: PaymentCreateInput!) {
                  createPayment(payment: $payment) {
                    id
                  }
                }
              `,
              variables: {
                payment: {
                    employee,
                    paidfor,
                    paidby,
                    status,
                    amount,
                },
              },
              watchLoading(isLoading, countModifier) {
                self.isLoading = isLoading;
              },
            });
            console.log(createdPayment);
            if (!createdPayment) {
              return "Something went wrong when creating the payment!";
            }
            this.$swal({
              title: "Success!",
              text: "Payment was created",
              type: "success",
            }).then(function () {
              window.location.href = `/admin/payments`;
            });
            self.successMessage = `Payment Created Successfully`;
            self.$eventHub.$emit("payment-updated");
          } catch (error) {
            this.$swal({
              title: "Error!",
              text: "Something went wrong when creating the Payment, check data and try again later!",
              type: "error",
            }).then(function () {
              window.location.href = `/admin/payments`;
            });
            self.errorMessage = `Payment Not Created / ${error.message}`;
          }
        } else {
          self.errorMessage = `Payment Not Created/ All required fields must be completed.`;
        }
      },
    },
  };
  </script>
  
  <style lang="sass"></style>
  