<template>
  <v-form
    ref="UserSalaryForm"
    v-model="formValid"
    :style="{ background: '#fff', width: '100vw' }"
  >
    <base-loading :is-loading="isLoading" />
    <base-dialog-notification ref="userCreateConfirm" />
    <base-snackbar-notification ref="userCreateSnackbar" />
    <div>
      <v-stepper v-model="step" vertical>
        <v-stepper-step :complete="step > 1" step="1">
          <span class="text-h4">Employee salary details</span>
        </v-stepper-step>

        <v-stepper-content step="1">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="user.firstName"
                :error-messages="formErrorMessages.firstname"
                label="First Name"
                required
                :rules="[(v) => !!v || 'First name is required']"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.lastName"
                :error-messages="formErrorMessages.lastname"
                label="Last Name"
                required
                :rules="[(v) => !!v || 'Last name is required']"
                outlined
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                  v-model="user.email"
                  label="Email"
                  outlined
                  :error-messages="formErrorMessages.email"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="user.telNumber"
                :error-messages="formErrorMessages.mobile"
                label="Mobile"
                required
                outlined
              />
            </v-col>

            <v-col cols="12">
              <base-identification-type-picker
                :initial="'South African ID'"
                label="Identification Type"
                outlined
                :chosen-identification-types.sync="idType"
              />
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-if="idType == 'South African ID'"
                :error-messages="formErrorMessages.idNumber"
                v-model="user.idNumber"
                label="ID Number"
                clearable
                outlined
              />

              <v-text-field
                v-else
                v-model="user.passport"
                label="Passport Number"
                clearable
                outlined
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="user.jobTitle"
                :error-messages="formErrorMessages.jobTitle"
                label="Job Title"
                clearable
                outlined
              />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <base-job-description-picker
                :initial="user.jobResponsibilities"
                label="Job Duties"
                outlined
                :chosen-job-description.sync="user.jobResponsibilities"
              />
            </v-col>
          </v-row>
          <!-- </v-card> -->
          <div class="d-flex justify-end mb-2">
            <v-btn class="" color="secondary" @click="saveUserDetails()"> Continue </v-btn>
          </div>
          <!-- <v-btn text> Cancel </v-btn> -->
        </v-stepper-content>
        <!-- :complete="step > 2" -->
        <v-stepper-step :complete="step > 2" step="2">
          <span class="text-h4">Employee bank details</span>
        </v-stepper-step>

        <v-stepper-content step="2">
          <v-row>
            <v-col cols="12">
              <base-payment-selection-picker
                label="How is the salary calculated?"
                outlined
                :chosen-payment-setup-type.sync="user.paymentSetupType"
                :initial="user.paymentSetupType"
                :error-messages="formErrorMessages.paymentSetupType"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="user.accountHolder"
                label="Account Holder Name"
                outlined
                :error-messages="formErrorMessages.accountHolder"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model.trim="user.accountNumber"
                type="tel"
                label="Account Number"
                required
                outlined
                :error-messages="formErrorMessages.accountNumber"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <base-bank-picker
                :chosen-bank.sync="user.bankName"
                :initial="user.bankName"
                label="Bank"
                outlined
                :error-messages="formErrorMessages.bankName"
              />
            </v-col>
            <v-col cols="12">
              <base-bank-branch-picker
                :chosen-bank-branch.sync="user.branchCode"
                :initial="user.branchCode"
                label="Branch Code"
                outlined
                :error-messages="formErrorMessages.branchCode"
              />
            </v-col>
            <v-col cols="12">
              <base-account-type-picker
                  :chosen-account-type.sync="user.accountType"
                  :initial="user.accountType"
                  label="Account Type"
                  required
                  outlined
                  hint="Capitec, Tyme and Discovery bank are Savings"
                  :rules="[(v) => !!v || 'Account type is required']"
                  :error-messages="formErrorMessages.accountType"
              />
            </v-col>
          </v-row>
          <v-row class="ma-2 d-flex justify-end">
            <v-btn color="primary" class="mr-2" @click="step = 1">
              Previous step</v-btn
            >
            <v-btn
              color="secondary"
              @click="saveUserDetails()"
            >
              Save
            </v-btn>

          </v-row>
        </v-stepper-content>
      </v-stepper>
    </div>
  </v-form>
</template>

<script>
import { gql } from "apollo-boost";

export default {
  props: {
    employerID: {
      type: String,
      default: "",
    },
    close: {
      type: Boolean,
      default: false,
    },
    createdBy: {
      type: String,
      default: "",
    },
    workAddress: {
      type: String,
      default: "",
    },
    formType: {
      type: String,
      default: "default",
    },
    role: {
      type: String,
      default: "employee",
    },
  },

  name: "UserSalary",
  documents: [],
  components: {

  },
  data: () => ({
    isLoading: false,
    formValid: true,
    step: 1,
    userId: "",
    user: {
    },
    formErrorMessages: {
    }
  }),
  computed: {
  },
  async mounted() {
  },
  methods: {
    async save() {
      this.isLoading = true;
    },
    async refreshClientData() {
      this.$apollo.queries.users.refetch();
    },
    async refreshPage() {
      this.$router.go(this.$route.currentRoute);
    },
    async saveUserDetails() {
    },
    async saveUserBankingDetails() {
    },
    convertName(name) {
      if (name != undefined) {
        name = name.trim().toLowerCase();
        if (name != "") {
          return (name = name.charAt(0).toUpperCase() + name.slice(1));
        } else {
          return (name = "");
        }
      } else {
        return (name = "");
      }
    },
    async saveUser() {
      try {
        const createdUser = await this.$apollo.mutate({
          mutation: gql`
            mutation createUser($user: UserCreateInput!) {
              createUser(user: $user) {
                id
              }
            }
          `,
          variables: {
            user: {
              firstName: self.user.firstName,
              lastName: self.user.lastName,
              email: self.user.email,
              password: "Test1234!!",
              passport: self.user.passport,
              telNumber: self.user.telNumber,
              role: "employee",
              accountType: self.user.accountType,
              accountNumber: self.user.accountNumber,
              accountHolder: self.user.accountHolder,
              bankName: self.user.bankName,
              branchCode: self.user.branchCode,
              employers: self.user.employers,
              paymentSetupType: self.user.paymentSetupType,
              workAddress: self.user.workAddress,
              jobTitle: self.user.jobTitle,
            },
          },
          watchLoading(isLoading, countModifier) {
            this.isLoading = isLoading;
          },
        });
        this.$emit('closeCreateSalaryDialog', {success: 'Employee saved.'})
        console.log("createdUser?.data?.createUser?.id", createdUser?.data?.createUser?.id);
        if (createdUser && createdUser.data.createUser) {
          this.userId = createdUser?.data?.createUser?.id;
        }
        this.$eventHub.$emit("user-updated");
      } catch (error) {
        this.$emit('closeCreateSalaryDialog', {error: 'Sorry, there was a problem creating the Employee. Please try again or contact support.'})
      }
    },
  },
};
</script>

<style lang="scss"></style>
