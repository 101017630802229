<template>
  <div style="height: auto" class="new-ser-picker">
    <v-text-field
      v-model="message"
      @keyup.enter="onEnter()"
      @input="typing = true"
      placeholder="Search Users"
      append-icon="mdi-magnify"
      clearable
      :loading="isLoading"
      :disabled="isLoading"
    />

    <base-loading :is-loading="isLoading" />

    <div
      class="typing"
      v-text="typing ? 'is typing...' : 'Hits ' + counter"
    ></div>
    <v-list dense v-if="newItems.length > 0 && typing == false">
      <v-list-item-group>
        <v-list-item
          v-for="(item, i) in newItems"
          :key="i"
          @click="chosenUser = item.id"
        >
          {{ item.firstName }} {{ item.lastName }} {{ item.email }}
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { gql } from "apollo-boost";
import _ from "lodash";

export default {
  name: "UserPicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "";
      },
    },
    role: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  data: () => ({
    chosenUser: null,
    totalRecords: 0,
    isLoading: false,
    returnedUsers: [],
    formattedUsers: [],

    message: "",
    typing: false,
    tempValue: "",
    newItems: [],
    counter: 0,
    limiter: 1,
  }),
  apollo: {
    users: {
      query: gql`
        query Users($limit: Int, $query: JSON!) {
          users(limit: $limit, query: $query) {
            records {
              id
              firstName
              lastName
              email
              idNumber
            }
          }
        }
      `,
      variables() {
        if (this.tempValue.length > 0) {
          this.limiter = 9999;
          var searchQuery = {
            $or: [
              { firstName: { $regex: this.tempValue, $options: "i" } },
              { lastName: { $regex: this.tempValue, $options: "i" } },
              { email: { $regex: this.tempValue, $options: "i" } },
            ],
          };
        } else {
          var searchQuery = {};
          this.limiter = 1;
        }
        return {
          limit: this.limiter,
          query: searchQuery,
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        // this.returnedUsers = data.users.records;
        // this.formattedUsers = _.map(this.returnedUsers, (user) => {
        //   return {
        //     text: `${user.firstName} ${user.lastName} ${user.email} ${user.idNumber}`,
        //     value: user.id,
        //   };
        // });

        this.counter = 0;
        if (this.tempValue?.length > 0) {
          //  this.$apollo.queries.users.refetch();
          this.newItems = data.users.records;
          this.counter = this.newItems.length;
        } else {
          this.newItems = [];
        }
      },
    },
  },
  methods: {
    onEnter() {
      this.tempValue = this.message;
      this.typing = false;
    },
  },

  watch: {
    tempValue() {
      if (this.tempValue?.length == 0 || this.tempValue === null) {
        this.newItems = [];
      }
    },
    message: _.debounce(function () {
      this.tempValue = this.message;
      this.typing = false;
      if (this.tempValue?.length == 0 || this.tempValue === null) {
        this.newItems = [];
      }
    }, 3000),
    /*initial(newValue, oldValue) {
      this.chosenUser = this.initial;
    },*/
    chosenUser(newValue) {
      this.$router.push("/admin/user/" + newValue);
    },
  },
  async mounted() {},
};
</script>

<style lang="scss">
.new-ser-picker {
  padding: 12px 0px;
  margin-top: 0 !important;
  position: relative;
  overflow: visible;
}

.new-ser-picker .typing {
  font-size: 12px;
  font-weight: bold;
}

.new-ser-picker .v-text-field__details {
  display: none;
}

.new-ser-picker .v-input {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 1px;
}

.new-ser-picker .v-list {
  max-height: 300px;
  overflow: auto;
  background-color: #eeeeee;
  z-index: 100;
  border-radius: 0px 0px 3px 3px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
}

.new-ser-picker .v-list .v-list-item--link:hover {
  color: black;
  background-color: #39fe88;
}
</style>
