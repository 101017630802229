import { loadView } from "./functions";

export const FrontendChildren = {
  children: [
    //TODO Remove this as it is for demo
    {
      path: "/",
      name: "Home",
      component: loadView("Frontend/Home"),
    },
    {
      path: "/register",
      name: "Register",
      component: loadView("Frontend/Register"),
    },
    {
      path: "/register-confirm",
      name: "Confirm Email",
      component: loadView("Frontend/RegisterConfirm"),
    },
    {
      path: "/password-reset/:id",
      name: "Password Reset",
      component: loadView("Frontend/PasswordReset"),
    },
  ],
};
