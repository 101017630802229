export class ShoppingList {
  constructor() {
    this.id = "";
    this.description = "";
    this.done = null;
    this.assignedTo = "";
    this.assignedToName = "";
    this.comment = null;
    this.createdBy = "";
    this.deleted = null;
    this.deletedAt = null;
    this.updatedBy = "";
    this.createdByName = "";
    this.updatedByName = "";
    this.createdAt = null;
    this.updatedAt = null;
  }
}
