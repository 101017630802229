<template>
  <v-autocomplete
    v-model="chosenTitle"
    v-bind="$attrs"
    :items="formattedTitles"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "TitlePicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "";
      },
    },
  },
  data: () => ({
    chosenTitle: "",
    isLoading: false,
    formattedTitles: [
      { text: "MR", value: "MR" },
      { text: "MRS", value: "MRS" },
      { text: "MISS", value: "MISS" },
      { text: "DR", value: "DR" },
      { text: "Other", value: "Other" }
    ],
  }),
  watch: {
    initial(newValue, oldValue) {
      this.chosenTitle = this.initial;
    },
    chosenTitle(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-title",
          this.chosenTitle ? this.chosenTitle : ""
        );
      }
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="sass"></style>
