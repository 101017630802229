<template>
  <v-autocomplete
    v-model="chosenAccountType"
    v-bind="$attrs"
    :items="formattedAccountTypes"
    append-icon="mdi-menu-down"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
export default {
  name: "AccountTypePicker",
  props: {
    initial: {
      type: String,
      default: function () {
        return "Current";
      },
    },
  },
  data: () => ({
    chosenAccountType: "Current",
    isLoading: false,
    formattedAccountTypes: [
      { text: "Current", value: "current" },
      { text: "Checking", value: "checking" },
      { text: "Savings", value: "savings" },
      { text: "Transmission", value: "transmission" }
    ],
  }),
  watch: {
    initial(newValue, oldValue) {
      this.chosenAccountType = this.initial
    },
    chosenAccountType(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-account-type",
          this.chosenAccountType ? this.chosenAccountType : "current"
        );
      }
    },
  },
  mounted() {
    this.chosenAccountType = this.initial
  },
  methods: {},
};
</script>

<style lang="sass"></style>
