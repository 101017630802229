<template>
  <v-container fluid :style="{ background: '#fff', width: '100vw' }" class="px-0">
    <base-loader :isLoading="isLoading"></base-loader>
    <base-dialog-notification ref="contractFormConfirm" />
    <base-snackbar-notification ref="contractFormSnackbar" />
    <v-row>
      <v-col class="px-0">
        <v-card flat>
          <v-card-title class="text-h4 grey--text" v-if="title">
            {{ title }}
          </v-card-title>
          <v-card-subtitle class="text-h4 blue-grey--text" v-if="finalSubTitle">
            {{ finalSubTitle }}
          </v-card-subtitle>
          <v-card-text >
            <v-form ref="contractForm" @submit.prevent="save()" >
              <v-row>
                <v-col md="6" class="pb-0">
                  <p class="text-h4 black--text">What is the Job Title or Role?</p>
                  <v-text-field
                      label="Job title"
                      v-model="contractModel.jobTitle"
                      outlined
                      class="text-body-1"
                      dense
                      single-line
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="contractTemplateJobResponsibilitiesOptions">
                <v-col md="6" class="py-1">
                  <p class="text-h4 black--text">What job responsibilities will this employee have?</p>
                  <v-select
                      v-model="contractModel.duties"
                      :items="contractTemplateJobResponsibilitiesOptions"
                      item-text="text"
                      item-value="value"
                      label="Job responsibilities"
                      outlined
                      multiple
                      class="text-body-1"
                      dense
                      single-line
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" class="py-1">
                  <p class="text-h4 black--text">How do you pay the employee for the work they have completed?</p>
                  <v-select
                      v-model="contractModel.wagePeriod"
                      :items="wagePeriodTypeOptions"
                      item-text="text"
                      item-value="value"
                      label="Monthly or daily wage?"
                      outlined
                      class="text-body-1"
                      dense
                      single-line
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6" class="py-1">
                  <p class="text-h4 black--text">How much to you pay the employee{{ wagePeriodHelper }}?</p>
                  <v-text-field
                      v-model.number="contractModel.wageRate"
                      outlined
                      prefix="R"
                      :suffix="wagePeriodSuffix"
                      class="text-body-1"
                      dense
                      single-line
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="contractModel.wagePeriod === 'per_day'">
                <v-col md="6" class="py-1">
                  <p class="text-h4 black--text">How often do you pay your employee?</p>
                  <v-select
                      v-model="contractModel.wagePaymentSchedule"
                      :items="wagePaymentScheduleTypeOptions"
                      item-text="text"
                      item-value="value"
                      label="Payment regularity"
                      class="text-body-1"
                      outlined
                      dense
                      single-line
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="contractModel.wagePaymentSchedule !== 'adhoc'">
                <v-col md="6" class="py-1">
                  <p class="text-h4 black--text">Which day of the month do you make payment?</p>
                  <v-select
                      label="Payment Date"
                      v-model="contractModel.wagePaymentDate"
                      :items="wagePaymentDateOptions"
                      item-text="text"
                      item-value="value"
                      class="text-body-1"
                      outlined
                      dense
                      single-line
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="6" class="py-1">
                  <p class="text-h4 black--text">How do you want to track the days that the employee has worked?</p>
                  <v-select
                      v-model="contractModel.wageTracking"
                      :items="wageTrackingTypeOptions"
                      item-text="text"
                      item-value="value"
                      label="Work tracking"
                      class="text-body-1"
                      outlined
                      dense
                      single-line
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="contractModel.wagePeriod === 'per_day' && contractModel.wageTracking === 'automatic'">
                <v-col md="6" class="py-1">
                  <p class="text-h4 black--text">Which days of the week does the employee work?</p>
                  <v-select
                      v-model="workScheduleSelectedItems"
                      :items="weekDayScheduleTypeOptions"
                      label="Work days"
                      class="text-body-1"
                      outlined
                      multiple
                      dense
                      single-line
                      return-object
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="showActions">
                <v-col md="6">
                  <v-btn elevation="0" type="submit" color="primary" class="" @click="save">
                    Save
                  </v-btn>
                  <v-btn elevation="0" outlined color="warning" class="ml-4" @click="cancel">
                    Cancel
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { gql } from 'apollo-boost'
import { Contract } from '@/models/Contract'

export default {
  name: "ContractForm",
  props: {
    title: {
      type: String,
      default: "",
    },
    subtitle: {
      type: String,
      default: "",
    },
    showActions: {
      type: Boolean,
      default: true,
    },
    contractId: {
      type: String,
      default: "",
    },
    userId: {
      type: String,
      default: "",
    },
    createdById: {
      type: String,
      default: "",
    }
  },
  data() {
    return {
      isLoading: false,
      edit: false,
      contractModel: new Contract(),
      finalSubTitle: this.subtitle,
      workScheduleSelectedItems: [],
    }
  },
  created () {
    const self = this
    if (this.userId){
      this.getContractByUserId(this.userId).then((contract) => {
        const finalContractObj = this.$_.defaults(contract, this.contractModel)
        delete finalContractObj.__ob__
        delete finalContractObj.__typename
        this.contractModel = finalContractObj
        this.updateWorkScheduleSelectedItems(this.contractModel.workSchedule)
        this.edit = true
      }).catch((error) => {
        this.edit = false
        if (this.subtitle === "") {
          this.finalSubTitle = "A contract for this Employee does not exist. Please complete the details below to create a contract."
        }
      })
    }
  },
  mounted () {
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    contractTemplateJobResponsibilities: {
      query: gql`
        query GetContractTemplateJobResponsibilities {
          contractTemplateJobResponsibilities {
            records
          }
        }
      `,
      fetchPolicy: "network-only",
      result(response, key) {
        return response.data.contractTemplateJobResponsibilities.records
      }
    },
    wageTrackingType: {
      query: gql`
        query {
          __type(name: "WageTrackingType") {
            wageTrackingType: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    wagePaymentScheduleType: {
      query: gql`
        query {
          __type(name: "WagePaymentScheduleType") {
            wagePaymentScheduleType: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    wagePeriodType: {
      query: gql`
        query {
          __type(name: "WagePeriodType") {
            wagePeriodType: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    weekDayTypes: {
      query: gql`
        query {
          __type(name: "WeekDayType") {
            weekDayTypes: enumValues {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
    weekDayScheduleType: {
      query: gql`
        query {
          __type(name: "WeekDayScheduleType") {
            weekDayScheduleType: fields {
              name
            }
          }
        }
      `,
      update: (data) => data.__type,
    },
  },
  computed: {
    contractTemplateJobResponsibilitiesOptions: function () {
      let returnObject = []
      if (this.contractTemplateJobResponsibilities) {
        if (this.$_.size(this.contractTemplateJobResponsibilities.records) > 0) {
          returnObject = this.contractTemplateJobResponsibilities.records
        }
      }
      return returnObject
    },
    wageTrackingTypeOptions: function () {
      const returnObject = []
      if (this.$_.size(this.wageTrackingType) > 0 ) {
        this.$_.forEach(this.wageTrackingType.wageTrackingType, function (value) {
          switch(value.name) {
            case 'automatic':
              returnObject.push({value: value.name, text: 'I don\'t track the employee\'s worked days.'})
              break;
            case 'employee_must_track':
              returnObject.push({value: value.name, text: 'Employee must track worked days in the app.'})
              break;
            case 'employee_must_track_with_approval':
              returnObject.push({value: value.name, text: 'Employee must track worked days & I approve them.'})
              break;
            case 'employer_must_track':
              returnObject.push({value: value.name, text: 'I will track track worked days in the app.'})
              break;
            default:
              break;
          }
        })
      }
      return returnObject
    },
    wagePaymentScheduleTypeOptions: function () {
      const returnObject = []
      if (this.$_.size(this.wagePaymentScheduleType) > 0 ) {
        this.$_.forEach(this.wagePaymentScheduleType.wagePaymentScheduleType, function (value) {
          switch(value.name) {
            case 'end_of_month':
              returnObject.push({value: value.name, text: 'I pay at the end of the month.'})
              break;
            case 'adhoc':
              returnObject.push({value: value.name, text: 'I pay on an ad-hoc basis.'})
              break;
            default:
              break;
          }
        })
      }
      return returnObject
    },
    wagePeriodTypeOptions: function () {
      const returnObject = []
      if (this.$_.size(this.wagePeriodType) > 0 ) {
        this.$_.forEach(this.wagePeriodType.wagePeriodType, function (value) {
          switch(value.name) {
            case 'per_month':
              returnObject.push({value: value.name, text: 'I pay a set amount each month.'})
              break;
            case 'per_day':
              returnObject.push({value: value.name, text: 'I pay on a per day basis.'})
              break;
            default:
              break;
          }
        })
      }
      return returnObject
    },
    wagePeriodHelper: function () {
      let returnText = ''
      if (this.contractModel.wagePeriod) {
        switch(this.contractModel.wagePeriod) {
          case 'per_month':
            returnText = ' per month'
            break;
          case 'per_day':
            returnText = ' per day'
            break;
          default:
            returnText = ''
        }
      }
      return returnText
    },
    wagePeriodSuffix: function () {
      let returnText = ''
      if (this.contractModel.wagePeriod) {
        switch(this.contractModel.wagePeriod) {
          case 'per_month':
            returnText = '/month'
            break;
          case 'per_day':
            returnText = '/day'
            break;
          default:
            returnText = ''
        }
      }
      return returnText
    },
    weekDayScheduleTypeOptions: function () {
      const returnObject = []
      if (this.$_.size(this.weekDayScheduleType) > 0 ) {
        this.$_.forEach(this.weekDayScheduleType.weekDayScheduleType, (value) => {
          returnObject.push(this.$_.capitalize(value.name))
        })
      }
      return returnObject
    },
    wagePaymentDateOptions: function () {
      const textOptions = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th', '13th', '14th', '15th', '16th', '17th', '18th', '19th', '20th', '21st', '22nd', '23rd', '24th', '25th', '26th', '27th', '28th']
      const returnObject = []
      returnObject.push({ value: 'LAST_DAY_OF_MONTH', text: 'Last day of the month' })
      this.$_.forEach(textOptions, (value, key) => {
        returnObject.push({ value: this.$_.toString(key + 1), text: value })
      })
      return returnObject
    },
  },
  methods: {
    updateWorkScheduleSelectedItems (workSchedule) {
      const contractWorkSchedule = this.$_.cloneDeep(workSchedule)
      delete contractWorkSchedule.__ob__
      delete contractWorkSchedule.__typename
      if(contractWorkSchedule){
        this.workScheduleSelectedItems = []
        this.$_.forEach(contractWorkSchedule, (value, key) => {
          if (value) {
            this.workScheduleSelectedItems.push(this.$_.capitalize(key))
          }
        })
      }
    },
    async getContractByUserId (userId) {
      return new Promise(async (resolve, reject) => {
        this.isLoading = true
        this.$apollo.query({
          query: gql`
            query GetContract($userId: ID!) {
              contractByUserId(userId: $userId) {
                id
                userId
                jobTitle
                duties
                contractCurrency
                terminationNoticePeriod
                wagePaymentSchedule
                wagePaymentDate
                wagePaymentWeekDay
                wagePeriod
                wagePeriodUnitType
                wageTracking
                wageRate
                overtimeAvailable
                overtimeRate
                publicHolidayRate
                hoursOfWorkStart
                hoursOfWorkEnd
                lunchPeriod
                createdAt
                updatedAt
                workSchedule {
                  monday
                  tuesday
                  wednesday
                  thursday
                  friday
                  saturday
                  sunday
                }
              }
            }
          `,
          fetchPolicy: "no-cache",
          variables: {
            userId: userId
          }
        }).then((data) => {
          this.isLoading = false
          if (_.has(data, 'data.contractByUserId.id')) {
            return resolve(data.data.contractByUserId)
          } else {
            return reject('No data found')
          }
        }).catch((error) => {
          console.log('Error:', error)
          this.isLoading = false
          return reject(error)
        })
      })
    },
    async save() {
      return new Promise(async (resolve, reject) => {
        //this.edit = this.$_.has(this.contractModel, 'id');
        this.edit = (!(this.contractModel.id === null || this.contractModel.id === undefined))
        if(this.edit){
          await this.update().then(async (data) => {
            console.log('Contract UpDated:', data)
            await this.generateContractPdfDocument(this.userId).then((data) => {
              this.finished('success', 'Contract Updated Successfully')
              return resolve('Contract Updated Successfully')
            }).catch((error) => {
              console.log('Error:', error)
              this.finished('error', error)
              return reject(error)
            })
          }).catch((error) => {
            console.log('Error:', error)
            this.finished('error', error)
            return reject(error)
          })
        } else {
          await this.create(this.userId).then(async (data) => {
            console.log('Contract Created:', data)
            this.contractModel.id = data.id
            await this.generateContractPdfDocument(this.userId).then((data) => {
              this.finished('success', 'Contract Created Successfully')
              return resolve('Contract Created Successfully')
            }).catch((error) => {
              console.log('Error:', error)
              this.finished('error', error)
              return reject(error)
            })
          }).catch((error) => {
            console.log('Error:', error)
            this.finished('error', error)
            return reject(error)
          })
        }
      })
    },
    async update() {
      return new Promise(async (resolve, reject) => {
        this.isLoading = true;
        this.contractModel.workSchedule = this.returnFormattedWorkSchedule(this.workScheduleSelectedItems)
        this.contractModel.updatedAt = new Date().getTime()
        this.$apollo.mutate({
          mutation: gql`
              mutation UpdateContract($contract: ContractUpdateInput!) {
                updateContract(contract: $contract) {
                  id
                  updated
                }
              }
            `,
          fetchPolicy: "no-cache",
          variables: {
            contract: this.contractModel
          }
        }).then((data) => {
          console.log('Finished Contract Update Mutation')
          this.isLoading = false
          return resolve(true)
        }).catch((error) => {
          this.isLoading = false
          console.log('Contract Mutation Error:', error)
          this.$refs.contractFormConfirm.open("Error", "There was a problem while saving the Contract. Please try again or contact support.", "error")
          return reject(error)
        })
      })
    },
    async create(userId) {
      return new Promise(async (resolve, reject) => {
        this.isLoading = true;
        delete(this.contractModel.id)
        this.contractModel.userId = userId
        this.contractModel.workSchedule = this.returnFormattedWorkSchedule(this.workScheduleSelectedItems)
        this.$apollo.mutate({
          mutation: gql`
            mutation CreateContract($contract: ContractCreateInput!) {
              createContract(contract: $contract) {
                id
                userId
              }
            }
          `,
          fetchPolicy: "no-cache",
          variables: {
            contract: this.contractModel
          }
        }).then((data) => {
          console.log('Finished Contract Mutation No Error')
          this.isLoading = false
          return resolve(data.data.createContract)
        }).catch((error) => {
          this.isLoading = false
          console.log('Contract Mutation Error:', error)
          this.$refs.contractFormConfirm.open("Error", "There was a problem while saving the Contract. Please try again or contact support.", "error")
          return reject(error)
        })
      })
    },
    cancel () {
      this.$emit('contractFormCancel')
    },
    finished (status, message) {
      const result = {
        status: status,
        message: message
      }
      this.$emit('contractFormFinished', result)
    },
    returnFormattedWorkSchedule (workSchedule) {
      const cleanWorkSchedule = this.$_.cloneDeep(workSchedule)
      delete cleanWorkSchedule.__ob__
      delete cleanWorkSchedule.__typename
      const returnObject = {
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false
      }
      this.$_.forEach(workSchedule, (value, index) => {
        returnObject[this.$_.toLower(value)] = true
      })
      return returnObject
    },
    generateContractPdfDocument(userId) {
      return new Promise(async (resolve, reject) => {
        this.$apollo.mutate({
          mutation: gql`
                mutation createContractPdfDocument($id: ID!) {
                  createContractPdfDocument(id: $id)
                }
              `,
          fetchPolicy: "no-cache",
          variables: {
            id: userId
          },
        }).then((result) => {
          return resolve(true)
        }).catch((error) => {
          return reject("Error while generating your contract. Please try again or contact support.")
        })
      })
    }
  },
};
</script>

<style lang="scss" scoped></style>
