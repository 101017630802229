<template>
  <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
    <v-layout align-start pr-4>
      <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
      <span class="text-h5">{{ snackbar.text }}</span>
    </v-layout>
    <v-row v-if="snackbar.showClose">
      <div class="spacer"></div>
    <v-btn  text class="my-2" @click="snackbar.visible = false">
      Close
    </v-btn>
    </v-row>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackbarNotification",
  data: () => ({
    snackbar: {
      color: null,
      icon: null,
      mode: null,
      position: "top",
      text: null,
      timeout: 3000,
      title: null,
      visible: false
    },
    timeout: 3000,
    showClose: true
  }),
  methods: {
    /**
     * Open a snackbar notification
     * @param {string} message
     * @param {string} type - Can be error, info, success, warning
     * @param {int} timeout - Timeout in ms (defaults to 3000ms)
     * @param {boolean} showClose - Show close button (defaults to true)
     */
    open(message, type, timeout, showClose) {
      const title = "Not used."
      if (!type) return;
      if (timeout) this.timeout = timeout;
      // check if showClose is not assigned while being boolean safe
      console.log(showClose)
      if (showClose !== undefined) this.showClose = showClose;
      switch (type) {
        case "error":
          this.snackbar = {
            color: "error",
            icon: "mdi-alert-circle",
            mode: "multi-line",
            position: "top",
            timeout: this.timeout,
            title: title,
            text: message,
            showClose: this.showClose,
            visible: true
          };
          break;
        case "info":
          this.snackbar = {
            color: "info",
            icon: "mdi-information",
            mode: "multi-line",
            position: "top",
            timeout: this.timeout,
            title: title,
            text: message,
            showClose: this.showClose,
            visible: true
          };
          break;
        case "success":
          this.snackbar = {
            color: "success",
            icon: "mdi-check-circle",
            mode: "multi-line",
            position: "top",
            timeout: this.timeout,
            title: title,
            text: message,
            showClose: this.showClose,
            visible: true
          };
          break;
        case "warning":
          this.snackbar = {
            color: "warning",
            icon: "mdi-alert",
            mode: "multi-line",
            position: "top",
            timeout: this.timeout,
            title: title,
            text: message,
            showClose: this.showClose,
            visible: true
          };
          break;
      }
    }
  },
};
</script>

<style lang="sass"></style>
