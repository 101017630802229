<template>
  <section>
    <v-card class="my-0">
      <v-card-text>
        <v-form ref="UserLeaseAgreementForm" v-model="formValid">
          <base-loading :is-loading="isLoading" />
          <v-alert
            v-if="successMessage"
            type="success"
            icon="mdi-firework"
            dense
            prominent
            dismissable
          >
            <h1>{{ successMessage }}</h1>
          </v-alert>
          <v-alert v-if="errorMessage" type="error">
            {{ errorMessage }}
          </v-alert>
          <v-row class="mx-1">
            <v-col cols="12" md="6">
              <base-user-picker
                :chosen-user.sync="chosenUserId"
                :initial="id"
                label="User"
                outlined
              />
              <v-alert
                v-if="user.validLease"
                type="success"
                prominent
                dismissable
              >
                <h1>
                  The Lease Agreement has already been completed for this user
                </h1>
              </v-alert>
              <v-alert v-else type="error">
                <h1>This user has not yet completed the Lease Agreement</h1>
              </v-alert>
            </v-col>
            <v-col cols="12" md="6">
              <v-img
                :src="'https://volcafespecialty.com/wp-content/uploads/2017/09/PDF-Placeholder.png'"
                :contain="true"
                max-width="60"
                max-height="80"
              />
              <div
                v-html="
                  user.leaseAgreement
                    ? $options.filters.getQuestionsBeforeYouSignAgreementLink(
                        user.leaseAgreement
                      )
                    : 'None'
                "
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="!formValid || user.leaseAgreement"
          color="primary"
          class="mx-2"
          @click="signLeaseAgreement()"
        >
          Send contract
        </v-btn>
      </v-card-actions>
    </v-card>
  </section>
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "LeaseAgreement",
  props: {
    id: {
      type: String,
      default: function () {
        return null;
      },
    },
  },
  data: () => ({
    isLoading: false,
    formValid: true,
    user: "",
    chosenUserId: null,
    success: false,
    successMessage: "",
    errorMessage: "",
  }),
  apollo: {
    // Query with parameters
    user: {
      query: gql`
        query User($id: ID!) {
          user(id: $id) {
            id
            email
            validLease
            leaseAgreement
            leaseAgreementSignature
          }
        }
      `,
      // Reactive parameters
      variables() {
        // Use vue reactive properties here
        return {
          id: this.chosenUserId,
        };
      },
      skip() {
        if (this.chosenUserId) {
          return false;
        }
        return true;
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data, loading, networkStatus }) {
        console.log("We got some result!", data);
        if (data && data.user) {
        }
      },
    },
  },
  computed: {},
  async mounted() {},
  methods: {
    async signLeaseAgreement() {
      const self = this;
      self.isLoading = true;
      self.successMessage = "";
      self.errorMessage = "";
      if (self.$refs.UserLeaseAgreementForm.validate()) {
        try {
          const getQuestionsBeforeYouSignAgreementLink =
            await this.$apollo.mutate({
              mutation: gql`
                mutation ($email: String!) {
                  getQuestionsBeforeYouSignAgreementLink(email: $email)
                }
              `,
              variables: {
                email: this.user.email,
              },
            });
          if (getQuestionsBeforeYouSignAgreementLink) {
            self.successMessage = `Lease Agreement Link sent to ${this.user.email}`;
          }
          self.isLoading = false;
        } catch (error) {
          if (error.response) {
            self.errorMessage = `${error.response.data}`;
          } else {
            self.errorMessage = `Questions before you sign agreement link not sent: ${error.message}`;
          }
          self.isLoading = false;
        }
      } else {
        self.errorMessage =
          "Questions before you sign agreement link not sent: All required fields must be completed.";
        self.isLoading = false;
      }
    },
  },
};
</script>

<style lang="sass"></style>
