export class Payment {
    constructor() {
        this.id = "";
        this.employer = "";
        this.employee = "";
        this.status = "";
        this.processBy = null;
        this.period = "";
        this.amount = 0;
        this.rate = 0;
        this.duration = 0;
    }
  }