<template>
  <v-autocomplete
    v-model="chosenReferral"
    v-bind="$attrs"
    :items="formattedReferrals"
    append-icon="mdi-magnify"
    :loading="isLoading"
    :disabled="isLoading"
  />
</template>

<script>
import { gql } from "apollo-boost";
export default {
  name: "ReferralPicker",
  props: {
    initial: {
      type: String,
      default: function() {
        return "";
      },
    },
  },
  data: () => ({
    chosenReferral: "",
    isLoading: false,
    formattedReferrals: [],
  }),
  apollo: {
    referrals: {
      query: gql`
        query Referrals($limit: Int!, $skip: Int!, $query: JSON!) {
          referrals(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
            }
            count
          }
        }
      `,
      variables() {
        return {
          limit: 9999,
          skip: 0,
          query: {},
        };
      },
      watchLoading(isLoading, countModifier) {
        this.isLoading = isLoading;
      },
      result({ data }, key) {
        this.returnedReferrals = data.referrals.records;
        this.formattedReferrals = _.map(this.returnedReferrals, (referral) => {
          return { text: `${referral.name}`, value: referral.name };
        });
      },
    },
  },
  watch: {
    chosenReferral(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit(
          "update:chosen-referral",
          this.chosenReferral ? this.chosenReferral : ""
        );
      }
    },
  },
  async mounted() {
    this.initial
      ? (this.chosenReferral = this.initial)
      : (this.chosenReferral = "");
  },
  methods: {},
};
</script>

<style lang="sass"></style>
